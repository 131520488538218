export default Object.freeze([
  [`Maladie`, `Incubation`, `Transmission`, `Effet`],
  [`Guilangue`, `6 heures`, `Inhalation de spores`, `Langue gonflée, Impossible de parler`],
  [`La vitreuse`, `12 heures`, `Contact (eau sale, poussières)`, `La cornée devient laiteuse, Cécité`],
  [`Grippe`, `1-3 jours`, `Inhalation, contact`, `Problèmes respiratoires, Maux de tête, Fatigue`],
  [`Indigestion`, `3-6 heures`, `Ingestion`, `Vomissements, Diarrhée, Déshydratation`],
  [`Lycanthropie`, `28 jours`, `Morsure ou griffure lors de la pleine lune`, `Transformation en loup-garou à la pleine lune`],
  [`Magieuse`, `2 jours`, `Inhalation`, `Incapable de sentir la magie`],
  [`Pâteuse`, `1 jour`, `Alcool`, `Migraine, Fatigue`],
  [`Petit mal`, `1 jour`, `Inhalation, contact`, `Maux de tête, Fatigue`],
]);
