const knowledge = [
  {
    description: `Le personnage sait compter au delà de 10 et faire des calculs simples`,
    effect: [],
    name: `Calculer`,
    related: CHARACTER.SKILLS.MASTERY.INTELLECT,
  },
  {
    description: `Le personnage sait pratiquer des chirurgies ou des amputations`,
    effect: [],
    name: `Opérer`,
    related: CHARACTER.SKILLS.KNOWLEDGE.BIOLOGY,
  },
  {
    description: `Le personnage sait comment les gens du passé se comportaient selon les situations`,
    effect: [],
    name: `Connaissance de l'étiquette ancienne`,
    related: CHARACTER.SKILLS.KNOWLEDGE.CULTURE,
  },
  {
    description: `Le personnage connaître la faune commune`,
    effect: [],
    name: `Connaissance de la faune`,
    related: CHARACTER.SKILLS.KNOWLEDGE.CULTURE,
  },
  {
    description: `Le personnage connaître la flore commune`,
    effect: [],
    name: `Connaissance de la flore`,
    related: CHARACTER.SKILLS.KNOWLEDGE.CULTURE,
  },
  {
    description: `Le personnage connaît les rudiments de la cuisine`,
    effect: [],
    name: `Cuisiner`,
    related: CHARACTER.SKILLS.KNOWLEDGE.SURVIVAL,
  },
  {
    description: `Le personnage sait comment fondre et modeler les métaux`,
    effect: [],
    name: `Fondre des métaux`,
    related: CHARACTER.SKILLS.KNOWLEDGE.ENGINEERING,
  },
  {
    description: `Le personnage sait effectuer une filature`,
    effect: [],
    name: `Filature`,
    related: CHARACTER.SKILLS.KNOWLEDGE.SURVIVAL,
  },
  {
    description: `Le personnage connaît les principes de base de l'informatique et sait coder ou pirater un logiciel`,
    effect: [],
    name: `Informatique`,
    related: CHARACTER.SKILLS.KNOWLEDGE.ENGINEERING,
  },
  {
    description: `Le personnage sait lire la langue commune`,
    effect: [],
    name: `Lire`,
    related: CHARACTER.SKILLS.KNOWLEDGE.CULTURE,
    requirements: [],
  },
  {
    description: `Le personnage sait lire la langue du passé`,
    effect: [],
    name: `Lire l'ancien`,
    related: CHARACTER.SKILLS.KNOWLEDGE.CULTURE,
  },
  {
    description: `Le personnage sait nager`,
    effect: [],
    name: `Nager`,
    related: CHARACTER.SKILLS.MASTERY.STRENGTH,
  },
  {
    description: `Le personnage sait comment porter et utiliser une armure assistée`,
    effect: [],
    name: `Porter des armures assistés`,
    related: CHARACTER.SKILLS.KNOWLEDGE.CULTURE,
  },
  {
    description: `Le personnage connaît les principes de base de la physique et sait comment réparer les armes et fabriquer des mécanismes`,
    effect: [],
    name: `Mécanique`,
    related: CHARACTER.SKILLS.KNOWLEDGE.ENGINEERING,
  },
  {
    description: `Le personnage connaît les principes de base de la robotique et sait comment fabriquer un robot équilibré et efficace`,
    effect: [],
    name: `Robotique`,
    related: CHARACTER.SKILLS.KNOWLEDGE.ENGINEERING,
  },
  {
    description: `Le personnage sait reconnaître et suivre les traces d'êtres humains ou de bêtes`,
    effect: [],
    name: `Suivre les traces`,
    related: CHARACTER.SKILLS.KNOWLEDGE.SURVIVAL,
  },
];

export default knowledge;

// Escalader, Pirater
// Chasser – Capturer des animaux pour se nourrir.
// Pêcher – Attraper du poisson avec des outils rudimentaires.
// Cultiver – Faire pousser des plantes comestibles.
// Tisser – Fabriquer des tissus à partir de fibres naturelles.
// Coudre – Réparer ou créer des vêtements.
// Allumer – Faire du feu avec des moyens rudimentaires.
// Forger – Travailler le métal pour créer des outils.
// Bâtir – Construire des structures simples.
// Tailler – Façonner la pierre ou le bois pour construire.
// Tracer – Dessiner des schémas ou des cartes lisibles.
// Soigner – Appliquer des soins de base sur une blessure.
// Accoucher – Aider à une naissance sans assistance médicale.
// Orienter – Trouver son chemin sans carte moderne.
// Tanner – Transformer une peau animale en cuir.
// Piéger – Poser des pièges pour capturer du gibier.
// Broyer – Réduire des matériaux en poudre ou petits morceaux.
// Réciter – Mémoriser et transmettre oralement des connaissances.
// Désarmer – Retirer une arme à un adversaire.
// Fondre – Chauffer un matériau pour le remodeler.
// Naviguer – Se déplacer sur l’eau sans instruments modernes.
// Filer – Transformer des fibres en fil à tisser.
// Lier – Assembler des matériaux pour solidifier une structure.
// Presser – Extraire des liquides (huile, jus, teintures...).
// Distiller – Séparer des substances par évaporation et condensation.


// Métier	Type	Débouchées
// Cuisinier	Artisanat
// Electronicien	Artisanat
// Forgeron	Artisanat
// Informaticien	Artisanat
// Jardinier	Artisanat	Scéniste, Paysagiste
// Mécanicien	Artisanat
// Ouvrier manoeuvre	Artisanat
// Roboticien	Artisanat
// Tailleur	Artisanat
// Tanneur	Artisanat
// Apprenti magicien	Aventure	Mage, Sorcier
// Explorateur	Aventure
// Récupérateur rural	Aventure
// Récupérateur urbain	Aventure
// Chauffeur	Aventure	Pilote, Pilote de course
// Mousse	Aventure	Moussaillon, Capitaine
// Pirate	Aventure	Corsair, Capitaine
// Colporteur de nouvelles	Aventure
// Ragotard	Aventure
// Crieur public	Aventure
// Héraut	Aventure
// Garde du corps	Combat
// Gardien de la paix	Combat
// Gladiateur	Combat
// Mercenaire	Combat
// Paladin	Combat
// Shérif	Combat
// Soldat	Combat
// Tueur à gages	Combat
// Tireur d'élite	Combat
// Biologiste	Science
// Cartographe	Science
// Climatologue	Science
// Chimiste	Science
// Géographe	Science
// Géologue	Science
// Historien	Science
// Médecin	Science
// Météorologue	Science
// Vétérinaire	Science
// Gérant	Social	Intendant
// Maire	Social	Dictateur
// Marchand nomade	Social
// Marchand sédentaire	Social
// Prêcheur	Social
// Voleur	Social	Contrebandier, Trouble-fête, Brigand


// Métier	Type
// Médecin généraliste	Artisanat et science
// Cartographe	Artisanat et science
// Cuisinier	Artisanat et science
// Fermier	Artisanat et science
// Forgeron	Artisanat et science
// Historien	Artisanat et science
// Fleuriste	Artisanat et science
// Informaticien	Artisanat et science
// Jardinier	Artisanat et science
// Ouvrier manoeuvre	Artisanat et science
// Mécanicien	Artisanat et science
// Tailleur	Artisanat et science
// Tanneur	Artisanat et science

// Apprenti magicien	Aventure
// Explorateur	Aventure
// Récupérateur rural	Aventure
// Récupérateur urbain	Aventure
// Chauffeur	Aventure
// Mousse	Aventure
// Pirate	Aventure
// Colporteur de nouvelles	Aventure
// Récolteur	Aventure
// Chasseur de gibier	Aventure

// Garde du corps	Combat
// Gardien de la paix	Combat
// Gladiateur	Combat
// Mercenaire	Combat
// Paladin	Combat
// Shérif	Combat
// Soldat	Combat
// Tueur à gages	Combat
// Tireur d'élite	Combat

// Gérant	Social
// Maire	Social
// Marchand nomade	Social
// Marchand sédentaire	Social
// Prêcheur	Social
// Voleur	Social
