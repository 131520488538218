import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useShallow } from 'zustand/react/shallow';

import spells from 'data/spells';
import { DataGridDisplay } from 'components/afterworld/data/GridDisplay';
import { PageHeading } from 'components/elements';
import { Spell } from 'components/afterworld/data/Spell';
import { Spells } from 'data/metadata';
import { useStore } from 'store';

const filterAlternative = (schools, alternativeMethod) => {
  switch (alternativeMethod) {
    case 'mono': return schools.length === 1;
    case 'hybrid': return schools.length > 1;
  }
};

const costFilterMapping = {
  EMPTY: [],
  LOW: [0, 1],
  MEDIUM: [2],
  HIGH: [3],
}

export function DataSpellPage() {
  const { alternative, cost, school, type, search, setFilter } = useStore(useShallow((state) => ({
    alternative: state.filter_spell_alternative,
    cost: state.filter_spell_cost,
    school: state.filter_spell_school,
    search: state.filter_spell_search,
    setFilter: state.setFilter,
    type: state.filter_spell_type,
  })));

  const costFilter = cost ? costFilterMapping[cost] : [];

  const filteredData = spells.filter((datum) => (
    (!costFilter.length || costFilter.includes(datum.cost))
    && (!school || datum.schools.filter((s) => s.name === school).length)
    && (!alternative || filterAlternative(datum.schools, alternative))
    && (!type || datum.types.filter((t) => t.includes(type)).length)
    && (!search || datum.name.toLowerCase().includes(search))
  ));

  return (
    <>
      <PageHeading>
        Sorts
      </PageHeading>

      <Box sx={{ display: 'grid', gridTemplateColumns: { lg: '50% 50%', md: '100%' }, gap: 1, mb: 1 }}>
        <FormControl fullWidth>
          <InputLabel id="afterworld-spells__list-filter-school__label">
            École
          </InputLabel>

          <Select
            label="École"
            labelId="afterworld-spells__list-filter-school__label"
            onChange={(e) => { setFilter('spell', 'school', e.target.value); }}
            size="small"
            value={school}
          >
            <MenuItem value=""><em>Aucun filtre</em></MenuItem>
            {Object.entries(Spells.SCHOOL).map(([schoolKey, schoolName]) => (
              <MenuItem key={schoolKey} value={schoolName}>{schoolName}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel id="afterworld-spells__list-filter-alternative__label">
            Alternatives
          </InputLabel>

          <Select
            label="Alternative"
            labelId="afterworld-spells__list-filter-alternative__label"
            onChange={(e) => { setFilter('spell', 'alternative', e.target.value); }}
            size="small"
            value={alternative}
          >
            <MenuItem value=""><em>Aucun filtre</em></MenuItem>
            <MenuItem value="mono">Sorts Mono-école</MenuItem>
            <MenuItem value="hybrid">Sorts hybrides</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel id="afterworld-spells__list-filter-cost__label">
            Coût
          </InputLabel>

          <Select
            label="Coût"
            labelId="afterworld-spells__list-filter-cost__label"
            onChange={(e) => { setFilter('spell', 'cost', e.target.value); }}
            size="small"
            value={cost}
          >
            <MenuItem value={`EMPTY`}><em>Aucun filtre</em></MenuItem>
            <MenuItem value={`LOW`}>≤ 1</MenuItem>
            <MenuItem value={`MEDIUM`}>2</MenuItem>
            <MenuItem value={`HIGH`}>≥ 3</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel id="afterworld-spells__list-filter-type__label">
            Type
          </InputLabel>

          <Select
            label="Type"
            labelId="afterworld-spells__list-filter-type__label"
            onChange={(e) => { setFilter('spell', 'type', e.target.value); }}
            size="small"
            value={type}
          >
            <MenuItem value=""><em>Aucun filtre</em></MenuItem>
            {Object.entries(Spells.Types).map(([typeKey, typeName]) => (
              <MenuItem key={typeKey} value={typeName}>{typeName}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>


      <FormControl sx={{ mb: 1, width: '100%' }}>
        <TextField
          id="afterworld-spells__list-search"
          label="Rechercher le sort"
          onChange={(e) => { setFilter('spell', 'search', e.target.value.toLowerCase()); }}
          size="small"
          value={search}
          variant="outlined"
        />
      </FormControl>

      <DataGridDisplay
        data={filteredData}
        density="comfortable"
        mobileComponent={Spell}
      />
    </>
  );
}
