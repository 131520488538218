export default (set) => ({
  filter_armor_search: '',
  filter_armor_type: '',
  filter_bestiary_search: '',
  filter_bestiary_type: '',
  filter_gift_level: '',
  filter_gift_search: '',
  filter_item_search: '',
  filter_item_type: '',
  filter_knowledge_related: '',
  filter_knowledge_search: '',
  filter_spell_alternative: '',
  filter_spell_cost: 'EMPTY',
  filter_spell_school: '',
  filter_spell_search: '',
  filter_spell_type: '',
  filter_weapon_search: '',
  filter_weapon_type: '',
  setFilter: (page, filter, value) => set((state) => {
    const key = `filter_${page}_${filter}`;
    if (state[key] !== value) {
      return { [key]: value };
    }
    return state;
  }),
});
