
import Skills from 'components/afterworld/Skills';
import sleepData from 'data/rules/sleep';
import { PageHeading } from 'components/elements';
import { Quote, Subtitle, Table, Text, Title } from 'components/semantic';

export function ManualStatisticsPage() {
  return (
    <>
      <PageHeading>
        Statistiques
      </PageHeading>

      <Title>Personnage</Title>

      <Subtitle>Descriptions</Subtitle>

      <Text>
        {`Les descriptions permettent d'avoir d'autres détails pour rendre le personnage unique, détails qui pourront faciliter l'immersion voir parfois être critiques selon certaines situations.`}
      </Text>

      <Text>
        {`On y retrouve de manière non-exhaustive le sexe du personnage, son âge, sa taille et son poids, la couleur de ses yeux, la taille et la couleur de ses cheveux, sa couleur de peau, sa pilosité, son activité dans le monde ...`}
      </Text>

      <Subtitle>Folies</Subtitle>

      <Text>
        {`Les folies représentent à quel point le personnage est dénaturé par le monde hostile qui l'entoure.`}
      </Text>

      <Text>
        {`Il est possible de choisir de commencer un personnage avec une folie, choisie ou tirée au hasard.`}
      </Text>

      <Quote>
        {`Il faut consulter la page des folies pour en avoir la liste`}
      </Quote>

      <Subtitle>Main d&apos;usage</Subtitle>

      <Text>
        {`Un personnage peut être : droitier, gaucher ou ambimane. L'ambidextrie s'acquiert normalement plus tard et l'être dès le début de la partie dépend du MJ.`}
      </Text>

      <Quote>
        {`Utiliser la main inhabituelle réduit les chances de réussite de 25 %
        L'utilisation simultanée des deux mains provoque un malus supplémentaire de 25 % par main`}
      </Quote>

      <Subtitle>Objectifs</Subtitle>

      <Text>
        {`Les objectives du personnage sont capitaux afin de cerner sa personnalité et de le jouer correctement.`}
      </Text>

      <Text>
        {`L'objectif court terme représente son aspirateur au jour le jour, tandis que l'objectif long terme est son aspiration profonde.`}
      </Text>

      <Subtitle>Radiations</Subtitle>

      <Text>
        {`Le taux de radiations varie généralement de 0 à 1000 et identifie à quel point le personnage est affecté par les radiations.`}
      </Text>

      <Text>
        {`Ce score est aussi proportionnel aux chances d'en décéder : plus il sera élevé et moins le personnage aura de chances d'en survivre.`}
      </Text>

      <Subtitle>Santé</Subtitle>

      <Text>
        {`Un personnage dispose d'un score de base de 100 points de vie sur chaque membre de son corps. Ce score n'augmente pas avec les niveaux, seuls certains cas de figure très précis permettent de le changer.`}
      </Text>

      <Text>
        {`Pour comprendre l'impact des blessures sur la santé, il faut se référer à la page des dégâts dans la section du combat.`}
      </Text>

      <Subtitle>Sommeil</Subtitle>

      <Text>
        {`Selon son type de sommeil, un personnage aura plus ou moins besoin de temps de repos pour avoir une nuit correcte, mais sera également plus enclin à être réveillé selon le bruit environnant.`}
      </Text>

      <Table data={sleepData}></Table>

      <Subtitle>Vitesse</Subtitle>

      <Text>
        {`La vitesse de déplacement d'un personnage est de 1 m/s en discrétion, de 3 m/s en vitesse normale et de 8 m/s en course.`}
      </Text>

      <Text>
        {`Il peut courir autant de secondes que son score en Endurance afin d'être essoufflé.`}
      </Text>

      <Title>Points</Title>

      <Subtitle>Points de chance</Subtitle>

      <Text>
        {`Les points de chance sont là pour permettre de pallier au côté aléatoire des dés lors des situations critiques. Lorsque d'un test sur une compétence, il est possible d'utiliser un point de chance après le test. Faire ainsi double les chances de réussir l'action en cours.`}
      </Text>

      <Quote>
        {`Par exemple pour un score de Détection de 27, le dé montre un score de 43, ce qui est insuffisant. Utiliser un point de chance augmente les chances de réussite à 54, ce qui transforme l'action en réussite`}
      </Quote>

      <Text>
        {`Un personnage commence avec 5 points de chance.`}
      </Text>

      <Subtitle>Points divin</Subtitle>

      <Text>
        {`Il s'agit d'une particularité de la race des Adrulens: ils sont davantage proches de leurs dieux avec qui ils communiquent.`}
      </Text>

      <Text>
        {`Leur ferveur est récompensée par des points divins, qui peuvent donc se récupérer avec le temps, par la prière ou les actions qui encensent les bienfaits prônés par leur divinité.`}
      </Text>

      <Text>
        {`Les autres races peuvent également avoir une telle ferveur que les dieux s'en soucient au point que le personnage acquiert des points divins, cela étant à la discrétion du MJ.`}
      </Text>

      <Text>
        {`L'utilisation des points divins est similaire aux points de Chance : ils sont à utiliser juste après un test de compétence, cependant l'effet diffère. Ils permettent de relancer le dé et de choisir quel score garder parmi les résultats.`}
      </Text>

      <Subtitle>Psychose</Subtitle>

      <Text>
        {`Les points de psychose s'accumulent lorsqu'un personnage frôle la mort ou tombe inconscient faute de points de Mental.`}
      </Text>

      <Text>
        {`D'autres situations rocambolesques ou des monstres effrayants peuvent également contribuer à la psychose d'un personnage.`}
      </Text>

      <Text>
        Dès le gain du 5ème point de psychose, il faut effectuer un test de <Skills.Defenses.Will />, et ce à chaque nouveau point gagné. Ce test a un malus de 5 % par point de psychose.
      </Text>

      <Text>
        {`Si ce test échoue, le personnage gagne une folie tirée au hasard et le nombre de points de psychose est ensuite réduit de moitié.`}
      </Text>

      <Subtitle>Fatigue</Subtitle>

      <Text>
        {`Le score de fatigue augmente selon certaines actions du personnage: agir davantage en combat, faire des efforts épuisants, ...`}
      </Text>

      <Text>
        Si le score de fatigue atteint le score en Endurance, le personnage est fatigué. Il ne peut plus effectuer qu'une seule action rapide par tour de combat et chaque tentative d'augmenter davantage son score de fatigue va nécessiter un test réussi de <Skills.Defenses.Will />.
      </Text>

      <Text>
        {`La fatigue se récupère en quelques minutes au repos. Il est aussi possible de la récupérer en combat en passant son tour.`}
      </Text>
    </>
  );
}
