import { Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import { ButtonMenu } from 'components/ButtonMenu';

export function UniverseMenu() {
  return (
    <>
      <Button
        LinkComponent={Link}
        endIcon={<FontAwesomeIcon icon={['fa', 'warning']} />}
        to="/universe"
      >
        Attention
      </Button>

      <ButtonMenu
        button={{ icon: 'cloud-sun', text: `Quotidien` }}
        menuItems={[
          { icon: 'people-group', text: `Les gens`, url: '/universe/daily/people' },
          { icon: 'screwdriver-wrench', text: `Métiers`, url: '/universe/daily/jobs' },
          { icon: 'hand-holding-dollar', text: `Prix`, url: '/universe/daily/prices' },
        ]}
      />

      <ButtonMenu
        button={{ icon: 'book', text: `Savoirs` }}
        menuItems={[
          { icon: 'robot', text: `Races`, url: '/universe/knowledge/races' },
          { icon: 'yin-yang', text: `Religions`, url: '/universe/knowledge/religions' },
          { icon: 'wand-sparkles', text: `Magie`, url: '/universe/knowledge/magic' },
        ]}
      />

      {/* <ButtonMenu
        button={{ icon: 'book-bookmark', text: `Histoire` }}
        menuItems={[
          { icon: 'bomb', text: `L'effondrement`, url: '/universe/history/collapse' },
          { icon: 'biohazard', text: `Conséquences`, url: '/universe/history/aftermath' },
          { icon: 'seedling', text: `Renouveau`, url: '/universe/history/renewal' },
        ]}
      /> */}

      <ButtonMenu
        button={{ icon: 'book-atlas', text: `Lieux` }}
        menuItems={[
          { icon: 'earth-africa', text: `Afrique`, url: '/universe/location/africa' },
          { icon: 'earth-americas', text: `Amérique`, url: '/universe/location/americas' },
          { icon: 'earth-asia', text: `Asie`, url: '/universe/location/asia' },
          { icon: 'earth-europe', text: `Europe`, url: '/universe/location/europe' },
          { icon: 'earth-oceania', text: `Océanie`, url: '/universe/location/oceania' },
          { icon: 'globe', text: `Les plans`, url: '/universe/location/plans' },
        ]}
      />
    </>
  );
}
