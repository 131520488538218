import { Spells } from 'data/metadata';

const data = Object.freeze([
  {
    additionalEffects: [
      `Effet : éclaire et réduit l'obscurité`,
      `Effet : résistance au froid +3°`,
      `Surcharge : résistance au froid +6° à la place`,
    ],
    cost: 1,
    description: `Invoque une petite flamme stable, résistante au vent et à l'eau, qui ne brûle pas mais réchauffe et éclaire.`,
    name: `Étincelle de vie`,
    requirements: [],
    schools: [
      { name: Spells.SCHOOL.FORGE },
      { name: Spells.SCHOOL.DAWN },
    ],
    types: [
      Spells.Types.SUMMON,
    ],
  },
  {
    additionalEffects: [
      `Effet : permet de purifier l'eau de bactéries (mais pas des radiations)`,
    ],
    area: `4 m²`,
    cost: 2,
    description: `Une onde de chaleur stérilise une zone, détruisant germes et parasites.`,
    name: `Brasier purificateur`,
    requirements: [
      `proximité`,
      `relique (source de feu, briquet, allumettes, ...)`,
    ],
    schools: [
      { name: Spells.SCHOOL.FORGE },
      { name: Spells.SCHOOL.DAWN },
    ],
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    additionalEffects: [
      `Surcharge : inflige 40 + 7x1d8 dégâts à la place`,
    ],
    area: `4 m²`,
    cost: 3,
    damage: `25 + 4x1d8`,
    description: `Une colonne de feu sacré s'abat sur la zone, brûlant les ennemis.`,
    name: `Jugement du Soleil`,
    requirements: [
      `proximité`,
      `relique (source de feu, briquet, allumettes, ...)`,
    ],
    schools: [
      { name: Spells.SCHOOL.FORGE },
      { name: Spells.SCHOOL.DAWN },
    ],
    types: [
      Spells.Types.CAST,
    ],
  },
  {
    additionalEffects: [
      `Effet : fait perdre 1 point de Fatigue aux personnes dans la zone`,
      `Surcharge : fait également perdre 1 point de Mental`,
    ],
    cost: 1,
    description: `Une fine poussière noire affecte les individus dans la zone.`,
    name: `Cendres maudites`,
    requirements: [
      `composant (poudre d'os)`
    ],
    schools: [
      { name: Spells.SCHOOL.FORGE },
      { name: Spells.SCHOOL.DUSK },
    ],
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    additionalEffects: [
      `Effet : applique Brûlure Ⅱ pour 3 tours`,
      `Effet : chaque dégâts infligés par les brûlures de ce sort font perdre 1 point de Mental à la cible`,
      `Effet : chaque dégâts infligés par les brûlures de ce sort font gagner 1 point de Fatigue à la cible`,
      `Surcharge : augmente l'effet de Brûlure à Ⅲ`,
    ],
    area: `4 m²`,
    cost: 2,
    description: `Une empreinte brûlante apparaît sur la cible qui calcine la chair et fatigue la personne.`,
    name: `Marque de la mort`,
    requirements: [
      `contact`
    ],
    schools: [
      { name: Spells.SCHOOL.FORGE },
      { name: Spells.SCHOOL.DUSK },
    ],
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    additionalEffects: [
      `Effet : inflige 40 + 4x1d8 dégâts`,
      `Surcharge : augmente les dégâts à 60 + 7x1d8`,
    ],
    area: `4 m²`,
    cost: 3,
    description: `Le sol s'ouvre brièvement, libérant des flammes noires qui consument toute matière organique sans affecter le métal ou la pierre.`,
    name: `Faille infernale`,
    requirements: [
      `relique (source de feu, briquet, allumettes, ...)`,
      `composant (morceau de chair)`,
    ],
    schools: [
      { name: Spells.SCHOOL.FORGE },
      { name: Spells.SCHOOL.DUSK },
    ],
    types: [
      Spells.Types.CAST,
    ],
  },
  {
    additionalEffects: [
      `Effet : Acuité -20% et incapable de respirer pour 1 tour`,
    ],
    cost: 1,
    description: `Une bourrasque dispersant des particules chaudes et irritantes affecte la cible.`,
    name: `Bourrasque de poussière`,
    requirements: [
      `composant (sphère venteuse)`,
      `composant (poussière)`,
    ],
    schools: [
      { name: Spells.SCHOOL.FORGE },
      { name: Spells.SCHOOL.TEMPEST },
    ],
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    additionalEffects: [
      `Effet : inflige Brûlure Ⅲ pour 3 tours`,
      `Surcharge : double la hauteur et la puissance de la tornade`,
      `Surcharge : augmente l'intensité de la Brûlure à Ⅳ`,
    ],
    cost: 2,
    description: `Crée une petite tornade avec des flammes virevoltantes à l'intérieur, qui a une chance d'emporter les personnes et de les brûler.`,
    high: `8 mètres`,
    name: `Tornade de feu`,
    requirements: [
      `proximité`,
      `relique (source de feu, briquet, allumettes, ...)`,
      `composant (sphère venteuse)`,
    ],
    schools: [
      { name: Spells.SCHOOL.FORGE },
      { name: Spells.SCHOOL.TEMPEST },
    ],
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    additionalEffects: [
      `Effet : désactive tout ce qui est électrique et dégâts x5 sur les cyborgs`,
      `Effet : 60% de chances d'infliger Brûlure Ⅲ pour 6 tours`,
      `Surcharge : 90% de chances d'infliger Brûlure Ⅳ pour 6 tours à la place`,
    ],
    area: `10 m²`,
    cost: 3,
    damage: `50 + 8x1d12`,
    description: `Une pluie de météores s'abat sur la zone et provoque des perturbations magnétiques.`,
    name: `Météores magnétiques`,
    requirements: [
      `proximité`,
      `relique (source de feu, briquet, allumettes, ...)`,
      `composant (morceau de roche)`,
      `composant (sphère orageuse)`,
    ],
    schools: [
      { name: Spells.SCHOOL.FORGE },
      { name: Spells.SCHOOL.TEMPEST },
    ],
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    additionalEffects: [
      `Effet : soigne 15 dégâts sur un membre du corps`,
      `Effet : soigne 15 dégâts supplémentaires s'il s'agissait de dégâts de Brûlure ou de Poison`,
      `Surcharge : double l'effet de soins`,
    ],
    cost: 1,
    description: `Invoque un cataplasme à base d'argile et de plantes, prêt à l'emploi. L'effet ne persiste que tant que l'invocation est présente et disparaît après.`,
    name: `Cataplasme`,
    requirements: [],
    schools: [
      { name: Spells.SCHOOL.FORGE },
      { name: Spells.SCHOOL.AWAKENING },
    ],
    types: [
      Spells.Types.SUMMON,
    ],
  },
  {
    additionalEffects: [
      `Effet : déplacement ÷ 2 dans la zone`,
      `Effet : inflige Brûlure Ⅱ pour 3 tours aux personnes sans chaussures`,
      `Surcharge : inflige Brûlure Ⅲ pour 3 tours aux personnes dans la zone à la place`,
    ],
    area: `4 m²`,
    cost: 2,
    description: `Le sol dans la zone devient chaud et instable ce qui favorise l'enracinement des plantes.`,
    name: `Terre fumante`,
    requirements: [],
    schools: [
      { name: Spells.SCHOOL.FORGE },
      { name: Spells.SCHOOL.AWAKENING },
    ],
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    additionalEffects: [
      `Effet : solidifie l'eau dans le membre du corps touché ce qui le transforme en glaçon qui peut se briser aux moindres dégâts`,
      `Surcharge : sublime l'eau dans le membre du corps touché, ne laissant que les os et la peau séchée`,
    ],
    cost: 3,
    description: `Invoque magiquement un projectile, mélange de givre et de braise ardente, qui est projeté vers une personne.`,
    name: `Pique de givre-feu`,
    requirements: [
      `relique (un peu d'eau solide)`,
      `relique (source de feu, briquet, allumettes, ...)`,
    ],
    schools: [
      { name: Spells.SCHOOL.FORGE },
      { name: Spells.SCHOOL.AWAKENING },
    ],
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [
      `Effet : effectue une moyenne des blessures totales de la personne qui sont réparties sur l'ensemble de son corps`,
      `Surcharge : effectue la moyenne sur deux personnes ensemble`,
    ],
    cost: 1,
    description: `Effectue une répartition de l'énergie vitale de la personne sur tout son corps.`,
    name: `Équilibre Vie et Mort`,
    requirements: [
      `contact`,
      `composant (morceau de chair)`,
    ],
    schools: [
      { name: Spells.SCHOOL.DAWN },
      { name: Spells.SCHOOL.DUSK },
    ],
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [
      `Effet : sur un test de Sang froid réussi, la cible est juste aveuglée, autrement elle est paralysée`,
      `Effet : sur un échec de test de Volonté, la cible gagne un point de Psychose et perd un point de Mental`,
      `Surcharge : la cible gagne 2 points de Psychose et perd 2 points de Mental`,
    ],
    cost: 2,
    description: `Alterne à vive allure entre des images de torture et le noir complet dans les yeux de la cible.`,
    name: `Diaporama effréné`,
    requirements: [],
    schools: [
      { name: Spells.SCHOOL.DAWN },
      { name: Spells.SCHOOL.DUSK },
    ],
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    additionalEffects: [
      `Effet : gangrène les tissus`,
      `Surcharge : augmente les dégâts à 30 + 5x1d8 à la place et augmente l'effet de gangrène`,
    ],
    area: `10 m²`,
    cost: 3,
    damage: `20 + 3x1d8 (brûlures)`,
    description: `Invoque une source de lumière nécrotique qui provoque des démangeaisons voir de la nécrose aux personnes proches.`,
    name: `Sombre soleil`,
    requirements: [
      `composant (morceau de chair)`,
    ],
    schools: [
      { name: Spells.SCHOOL.DAWN },
      { name: Spells.SCHOOL.DUSK },
    ],
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    additionalEffects: [
      `Effet : soigne aux personnes dans la zone un montant équivalent à leur score en Vigueur`,
      `Effet : ces personnes gagnent 2 points de Fatigue`,
      `Surcharge : double les soins et les points de Fatigue gagnés`,
    ],
    area: `4 m²`,
    cost: 1,
    description: `Transporte dans l'air des phéromones qui activent les capacités de régénération des personnes dans la zone.`,
    name: `Souffle de vie`,
    requirements: [
      `composant (sphère venteuse)`,
    ],
    schools: [
      { name: Spells.SCHOOL.DAWN },
      { name: Spells.SCHOOL.TEMPEST },
    ],
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [
      `Effet : aveugle pendant 6 tours`,
      `Surcharge : aveugle pendant 12 tours`,
    ],
    cost: 2,
    description: `Un éclair déchire le ciel et s'abat entre le lanceur et la cible, dont la lumière aveuglante affecte les personnes qui l'ont en visuel.`,
    name: `Éclair aveuglant`,
    requirements: [
      `composant (sphère orageuse)`,
    ],
    schools: [
      { name: Spells.SCHOOL.DAWN },
      { name: Spells.SCHOOL.TEMPEST },
    ],
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [
      `Effet : les dégâts subit par l'une des personnes sont infligés à l'autre également`,
      `Surcharge : les dégâts sont réduit de moitié pour les deux personnes`,
    ],
    cost: 3,
    description: `Crée un lien énergétique entre deux personnes.`,
    name: `Lien énergétique`,
    requirements: [
      `composant (sphère orageuse)`
    ],
    schools: [
      { name: Spells.SCHOOL.DAWN },
      { name: Spells.SCHOOL.TEMPEST },
    ],
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    additionalEffects: [
      `Effet : Armure +6 sur le bras porteur`,
      `Effet : toute personne qui regarde le bouclier est aveuglée pendant 3 tours`,
    ],
    cost: 1,
    description: `Invoque un bouclier de givre de résistance moyenne mais qui reflète et renvoie excessivement la lumière.`,
    name: `Invocation - Bouclier Givre & Lumière`,
    requirements: [
      `composant (de l'eau solide)`
    ],
    schools: [
      { name: Spells.SCHOOL.DAWN },
      { name: Spells.SCHOOL.AWAKENING },
    ],
    types: [Spells.Types.SUMMON],
  },
  {
    additionalEffects: [
      `Effet : les personnes récupèrent 5 points de vie par tour de baignade`,
      `Surcharge : les personnes à l'exception du lanceur récupèrent 1 points de Mental par tour de baignade`,
    ],
    area: `10 m³`,
    cost: 2,
    description: `Augmente les facultés régénératives d'une étendue d'eau, permettant aux personnes de s'y baigner pour récupérer plus rapidement.`,
    name: `Thermes régénérant`,
    requirements: [],
    schools: [
      { name: Spells.SCHOOL.DAWN },
      { name: Spells.SCHOOL.AWAKENING },
    ],
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    additionalEffects: [
      `Effet : en combat, capable de dépenser 1 point de Fatigue supplémentaire pour gagner une action rapide de plus`,
      `Effet : compétences de Maîtrise et de Défenses +15%`,
      `Surcharge : augmente le bonus à 40%`,
    ],
    cost: 3,
    description: `Accroît les facultés physiques d'une personne, lui permettant de se dépasser.`,
    name: `Apothéose de l'évolution`,
    requirements: [
      `contact`,
    ],
    schools: [
      { name: Spells.SCHOOL.DAWN },
      { name: Spells.SCHOOL.AWAKENING },
    ],
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [
      `Effet : les personnes dans la zone perdent 1 point de Mental à moins de réussir un test de Volonté`,
      `Surcharge : les personnes dans la zone gagnent 1 point de Psychose en plus de perdre un point de Mental`,
    ],
    cost: 1,
    description: `Provoque un bruit strident, semblable à des cris d'agonie, ce qui affecte les personnes proches.`,
    name: `Sifflet de la mort`,
    requirements: [
      `composant (sphère venteuse)`
    ],
    schools: [
      { name: Spells.SCHOOL.DUSK },
      { name: Spells.SCHOOL.TEMPEST },
    ],
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [
      `Effet : paralyse la cible pendant 1 tour`,
      `Surcharge : inflige 45 + 7x1d8 dégâts à la place`,
    ],
    cost: 2,
    damage: `30 + 4x1d8`,
    description: `Envoie une décharge électrique sur la cible sans que celle-ci n'émette la moindre lumière, ce qui la rend indétectable.`,
    name: `Éclair ténébreux`,
    requirements: [
      `composant (sphère orageuse)`,
      `luminosité ambiante`,
    ],
    schools: [
      { name: Spells.SCHOOL.DUSK },
      { name: Spells.SCHOOL.TEMPEST },
    ],
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [
      `Effet : transmets la Grippe et la Magieuse`,
      `Surcharge : transmets la Variole`,
    ],
    area: `4 m²`,
    cost: 3,
    description: `Envoie une panoplie de bactéries dans l'air autour de la cible.`,
    name: `Vent de peste`,
    requirements: [
      `composant (poudre d'os)`,
      `composant (sphère venteuse)`,
    ],
    schools: [
      { name: Spells.SCHOOL.DUSK },
      { name: Spells.SCHOOL.TEMPEST },
    ],
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [],
    area: `4 m²`,
    cost: 1,
    description: `Fait pourrir toute vie présente dans la terre environnante ce qui la rend stérile.`,
    name: `Sols infertiles`,
    requirements: [
      `contact`,
      `composant (poussière)`,
      `composant (poudre d'os)`,
    ],
    schools: [
      { name: Spells.SCHOOL.DUSK },
      { name: Spells.SCHOOL.AWAKENING },
    ],
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    additionalEffects: [
      `Effet : immobilise et désarme les personne dans la zone`,
      `Surcharge : renverse au sol et noie les personnes dans la zone`,
    ],
    area: `10 m²`,
    cost: 2,
    description: `Invoque un nuage de brume noire, presque liquide, aux pieds des personnes dans la zone, d'où émergent des ombres qui les saisissent.`,
    name: `Lac des ténèbres`,
    requirements: [
      `composant (de l'eau liquide)`,
      `luminosité ambiante`,
    ],
    schools: [
      { name: Spells.SCHOOL.DUSK },
      { name: Spells.SCHOOL.AWAKENING },
    ],
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [
      `Effet : transmets le Petit Mal`,
      `Surcharge : inflige 45 + 7x1d4 dégâts à la place`,
      `Surcharge : transmets la Putride à la place`,
    ],
    cost: 3,
    damage: `30 + 5x1d4`,
    description: `Crée un solide pieu, mélange de givre et de sang coagulé, et le projette sur une cible.`,
    name: `Pieu de sang-givre`,
    requirements: [
      `composant (de l'eau solide)`,
      `composant (fiole de sang)`,
    ],
    schools: [
      { name: Spells.SCHOOL.DUSK },
      { name: Spells.SCHOOL.AWAKENING },
    ],
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [
      `Effet : la cible peut ignorer jusqu'à 2 points de Fatigue`,
      `Surcharge : donne une chance de faire repartir un cœur arrêté depuis moins de 3 minutes`,
    ],
    cost: 1,
    description: `Provoque une légère décharge électrique sur la cible, de sorte à la réveiller ou à la stimuler.`,
    name: `Décharge tonique`,
    requirements: [],
    schools: [
      { name: Spells.SCHOOL.TEMPEST },
      { name: Spells.SCHOOL.AWAKENING },
    ],
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    additionalEffects: [
      `Effet : les personnes affectées doivent faire l'action voulue par le lanceur, cet effet peut être contré avec un test de Volonté`,
    ],
    area: `4 m²`,
    cost: 2,
    description: `Transporte des phéromones dans l'air et affecte des personnes dans la zone.`,
    name: `Message de phéromones`,
    requirements: [
      `composant (sphère venteuse)`
    ],
    schools: [
      { name: Spells.SCHOOL.TEMPEST },
      { name: Spells.SCHOOL.AWAKENING },
    ],
    types: [
      Spells.Types.CAST,
      Spells.Types.CAST_CHANNEL,
    ],
  },
  {
    additionalEffects: [
      `Surcharge : inflige 60 + 10x1d8 dégâts à la place`,
    ],
    cost: 3,
    damage: `40 + 10x1d4`,
    description: `Projette des éclaboussures chargées électriquement sur une personne.`,
    name: `Éclaboussures électrifiées`,
    requirements: [
      `composant (sphère orageuse)`,
      `composant (de l'eau liquide)`,
    ],
    schools: [
      { name: Spells.SCHOOL.TEMPEST },
      { name: Spells.SCHOOL.AWAKENING },
    ],
    types: [Spells.Types.CAST],
  },
]);

export default data;
