import PropTypes from 'prop-types';

import transposeArray from 'lib/transposeArray';

import './Table.scss';

export function Table({
  data,
  direction = 'row',
  headers = [],
  responsive = false,
  sizes = [],
  style = 'classic',
}) {
  const totalSize = sizes.reduce((prev, curr) => (prev + curr), 0);

  const formattedData = direction === 'column'
    ? transposeArray(data)
    : data;

  const getSize = (index) => (Math.floor(sizes[index] / totalSize * 100) + '%');

  return (
    <div className={`afterworld-table style-${style}${responsive ? ' responsive' : ''}`}>
      <table className={`direction-${direction}`}>
        {!!headers.length && (
          <thead>
            <tr>
              {headers.map((heading, index) => (
                !!sizes.length && sizes[index]
                  ? (<th key={heading} width={getSize(index)}>{heading}</th>)
                  : (<th key={heading}>{heading}</th>)
              ))}
            </tr>
          </thead>
        )
        }
        <tbody>
          {formattedData.map((column, columnIndex) => (
            <tr key={columnIndex} className="box-column">
              {column.map((cell, cellIndex) => (
                !!sizes.length && sizes[cellIndex]
                  ? (<td key={cellIndex} className="box-cell" width={getSize(cellIndex)}>
                    {cell}
                  </td>)
                  : (<td key={cellIndex} className="box-cell">
                    {cell}
                  </td>)
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.node)).isRequired,
  direction: PropTypes.oneOf(['column', 'row']),
  headers: PropTypes.arrayOf(PropTypes.node),
  responsive: PropTypes.bool,
  sizes: PropTypes.arrayOf(PropTypes.number),
  style: PropTypes.oneOf(['classic', 'inward']),
};
