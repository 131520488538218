import { Element, Type, Subtype } from 'data/metadata';

const data = [
  {
    ammo: `Mini-Nuke`,
    bpa: `1`,
    damage: `250 + 1d100`,
    effects: `Zone d'impact applique Radiations Ⅲ / tour`,
    load: `1`,
    name: `Atomi-boom`,
    range: `Moyenne`,
    value: 520,
    weight: 25,
    zone: `5`,
  },
  {
    ammo: `Calibre .308`,
    bpa: `15`,
    damage: `65 + 6x1d10 + DR`,
    effects: `-40% chances de toucher sans trépied`,
    load: `90`,
    name: `Fusil Bren`,
    range: `Moyenne`,
    value: 280,
    weight: 15,
  },
  {
    ammo: `Carburant`,
    bpa: `15`,
    effects: `Applique Brûlure Ⅳ pour 3 tours, cumulable`,
    load: `90`,
    name: `Lance-flammes`,
    range: `Moyenne`,
    value: 270,
    weight: 25,
    zone: `2`,
  },
  {
    ammo: `Grenades Fa6`,
    bpa: `1`,
    damage: `50 + 3x1d20 + DR`,
    effects: `Possible de mettre n'importe quelle grenade`,
    load: `3`,
    name: `Lance-grenades`,
    range: `Moyenne`,
    value: 190,
    weight: 10,
    zone: `2`,
  },
  {
    ammo: `Missile`,
    bpa: `1`,
    damage: `100 + 4x1d20 + DR`,
    load: `1`,
    name: `Lance-missiles`,
    range: `Moyenne`,
    value: 360,
    weight: 20,
    zone: `2`,
  },
  {
    ammo: `Pack de CE`,
    bpa: `30`,
    damage: `58 + 8x1d8 + DR`,
    load: `240`,
    name: `Laser Gatling`,
    range: `Courte`,
    value: 430,
    weight: 40,
  },
  {
    ammo: `Calibre .308`,
    bpa: `5`,
    damage: `46 + 6x1d8 + DR`,
    load: `20`,
    name: `Lewis MK 2`,
    range: `Longue`,
    value: 150,
    weight: 12,
  },
  {
    ammo: `Calibre .44`,
    bpa: `15`,
    damage: `40 + 15x1d6 + DR`,
    effects: `Arme artisanale, chances de s'enrayer accrues`,
    load: `120`,
    name: `Le désosseur`,
    range: `Courte`,
    value: 215,
    weight: 15,
  },
  {
    ammo: `Balles 5,56mm`,
    bpa: `20`,
    damage: `55 + 5x1d10 + DR`,
    effects: `-20% chances de toucher sans trépied`,
    load: `80`,
    name: `M249 Saw`,
    range: `Moyenne`,
    value: 210,
    weight: 25,
  },
  {
    ammo: `Balles 5,56mm`,
    bpa: `30`,
    damage: `55 + 5x1d8 + DR`,
    load: `240`,
    name: `Minigun CZ-53`,
    range: `Courte`,
    value: 240,
    weight: 25,
  },
  {
    ammo: `Missile`,
    bpa: `1`,
    damage: `60 + 8x1d20 + DR`,
    effects: `Délai entre le tir et l'impact de 1 tour / portée`,
    load: `1`,
    name: `Mortier`,
    range: `Longue`,
    value: 395,
    weight: 25,
    zone: `3`,
  },
  {
    ammo: `Balles 5,56mm`,
    bpa: `20`,
    damage: `50 + 5x1d10 + DR`,
    effects: `-40% chances de toucher sans trépied`,
    load: `480`,
    name: `Vindicator`,
    range: `Moyenne`,
    value: 190,
    weight: 30,
  },
];

export default data.map((i) => ({
  ...i,
  element: Element.ITEM,
  subtype: Subtype.Weapon.HEAVY,
  type: Type.Item.WEAPON,
}));
