import { Monsters } from 'data/metadata';

const data = [
  {
    attacks: [Monsters.Skills.Poison('Rêvoilé', '2 minutes')],
    death: [`Nuage de poison : libère un nuage de poison qui a 30% de chances d'appliquer Poison "Rêvoilé" pour 5 minutes`],
    health: 15,
    hit: [80, 60, 0],
    name: `Bulbododo`,
  },
  {
    attacks: [
      `Applique Radiations I`,
      Monsters.Skills.Poison('Essouffleur', '2 minutes'),
    ],
    damage: [`8 + 1d8 + DR`, ``, ``],
    health: 25,
    hit: [70, 0, 0],
    name: `Spéléla`,
    speed: 1,
  },
  {
    abilities: [`Spores : libère des spores aux alentours qui appliquent Poison "Assoiffeur" pour 12 heures`],
    attacks: [Monsters.Skills.Poison('Essouffleur', '2 minutes')],
    damage: [`6 + 2x1d6 + DR`, ``, ``],
    health: 40,
    hit: [65, 45, 0],
    name: `Amyrexia`,
    passives: [Monsters.Skills.RégénérationRadioactive],
  },
  {
    abilities: [`Nuage de poison : libère un vaste nuage qui a 70% de chances d'appliquer Poison "Étouffeur" pour 1 minute`],
    armor: 1,
    attacks: [Monsters.Skills.Poison('Rêvoilé', '5 minutes')],
    death: [`Spores empoisonnées : des spores transparentes appliquent Poison "Spectrol" pendant 1 heure`],
    health: 60,
    hit: [75, 0, 0],
    name: `Mycognon`,
    speed: 2,
  },
  {
    abilities: [`"Nuage de poison": libère un vaste nuage de poison qui applique Poison "Assoiffeur" pour 12 heures`],
    name: `Chaxye`,
    health: 35,
  },
  {
    health: 10,
    name: `Mut-lotus`,
    passives: [
      `"Nuage de poison" : à la mort, libère un nuage de poison applique Poison "Rêvoilé" pour 15 minutes`,
      Monsters.Skills.AuraRadioactive('Ⅳ'),
    ],
  },
  {
    abilities: [`"Explosion de fumée": dégage une épaisse fumée verdâtre opaque`],
    armor: 4,
    attacks: [`Applique Saignements Ⅳ permanent`],
    damage: [`26 + 8x1d4 + 3xDR`, ``, ``],
    health: 115,
    hit: [110, 0, 0],
    initiative: 5,
    name: `Arracheur de coeurs`,
    skills: [
      { skill: `Agilité (en forêt)`, value: `120 %` },
    ],
    speed: 2,
  },
  {
    attacks: [
      `(PC) applique Saignements Ⅲ pour 12 tours`,
      Monsters.Skills.Poison('Tue-sorcier', '1 minute', `(PM) 90`),
      `(PM) 40 % : rend fou pendant 12 heures`
    ],
    damage: [`12 + 2x1d6 + DR`, ``, ``],
    health: 35,
    hit: [90, 40, 0],
    name: `Syla`,
  },
  {
    armor: 2,
    attacks: [Monsters.Skills.Poison('Neucrosite', '1 minute')],
    damage: [`14 + 2x1d6 + DR`, ``, ``],
    health: 75,
    hit: [90, 30, 0],
    name: `Fertiliseur`,
    passives: [`Venin addictif : les personnes touchées par le poison deviennent addict`],
    speed: 1,
  },
  {
    abilities: [
      Monsters.Skills.Tremblement,
      `Spores toxiques : libère un nuage qui applique Poison "Rêvoilé" et "Assoiffeur" aux personnes proches pendant 12 heures`
    ],
    actions: `+1`,
    armor: 6,
    damage: [`26 + 3x1d10 + DR`, ``, ``],
    health: 240,
    hit: [90, 70, 0],
    name: `Arbre béhémoth`,
    passives: [Monsters.Skills.Piétinement],
    show: [`Effraie les personnes proches`],
    speed: 4,
  },
  {
    abilities: [
      `Nuage de poison : libère un vaste nuage qui applique Poison "Étouffeur" pendant 5 minutes`,
      `Groupe : se trouve généralement avec un groupe de 4 à 9 Mycognons [3 + 1d6]`,
      Monsters.Skills.Chef('Mycognons', 6),
    ],
    attacks: [Monsters.Skills.Poison('Rêvoilé', '15 minutes')],
    damage: [`8 + 2x1d6 + DR`, ``, ``],
    death: [`Spores empoisonnées : des spores transparentes sont libérées et appliquent Poison "Spectrol" pendant 3 heures`],
    health: 20,
    hit: [80, 0, 0],
    initiative: 4,
    name: `Clampignon`,
    speed: 2,
  },
  {
    abilities: [
      `Jet de poison : applique Poison "Tue-sorcier" à la cible pour 2 minutes`,
      `Barrage d'épines : inflige 40 + 5x1d6 aux personnes proches et leur applique Poison "Tue-sorcier" pour 12 tours, rechargement de 10 tours`
    ],
    attacks: [Monsters.Skills.Poison('Neucrosite', '1 minute')],
    actions: `+1`,
    armor: 12,
    damage: [`65 + 10x1d8 + 4xDR`, ``, ``],
    health: 890,
    hit: [90, 50, 0],
    initiative: 7,
    name: `Neolethid`,
  },
  {
    abilities: [`Étreinte douloureuse : tente d'attraper une cible et de la serrer, ce qui lui inflige 50 + 8x1d6 blessures`],
    actions: `+1`,
    armor: 8,
    attacks: [Monsters.Skills.Poison('Rêvoilé', '1 minute')],
    damage: [`26 + 4x1d6 + 2xDR`, ``, ``],
    health: 450,
    hit: [95, 70, 0],
    name: `Cactopus`,
  },
];

export default data.map((i) => ({
  ...i,
  types: [Monsters.Type.PLANT],
}));
