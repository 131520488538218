import { Monsters, Spells } from 'data/metadata';

const data = [
  {
    armor: 1,
    attacks: [`Applique Radiations Ⅲ`],
    damage: [`10 + 2x1d6 + DR`, ``, ``],
    death: [`Explosion de radiations : applique Radiations Ⅳ aux personnes proches`],
    health: 80,
    hit: [70, 65, 50],
    initiative: 2,
    name: `Centaure`,
    speed: 1,
  },
  {
    attacks: [
      `50 % : transmets la Putride`,
      `25 % : transmets la Peste bubonique`,
    ],
    damage: [`6 + 1d6 + DR`, ``, ``],
    health: 60,
    hit: [75, 0, 0],
    initiative: 2,
    name: `Naufragé putride`,
    speed: 2,
  },
  {
    armor: 1,
    attacks: [`Applique Saignements Ⅱ pour 6 tours`],
    damage: [`8 + 3x1d4 + 2xDR`, ``, ``],
    health: 0,
    hit: [75, 0, 0],
    initiative: 5,
    name: `Mangeur de chair`,
    speed: 2,
  },
  {
    abilities: [`Contrôle mental : se place sur la tête d'une cible et envahit son cerveau afin de diriger ses actions`],
    attacks: [`Applique Radiations Ⅳ`],
    health: 35,
    hit: [85, 0, 0],
    initiative: 6,
    name: `Parasite`,
    speed: 4,
  },
  {
    armor: 6,
    attacks: [`Applique Radiations Ⅱ`],
    damage: [`15 + 2x1d6 + DR`, ``, ``],
    health: 120,
    hit: [80, 0, 0],
    name: `Fangetue`,
    passives: [Monsters.Skills.Faiblesse("Tête", 0)],
    speed: 2,
  },
  {
    abilities: [`Étranglement : maintient la cible et tente de l'étouffer. Si elle ne parvient pas à se libérer avant de perdre son souffle, elle tombe inconsciente`],
    armor: 2,
    damage: [`25 + 3x1d6 + DR`, ``, ``],
    health: 220,
    hit: [90, 0, 0],
    name: `Visionnaire`,
    passives: [
      `Champ de vision large : impossible d'être pris par surprise`,
      `Vision lointaine : capable de voir extrêmement loin et peut maintenir un pistage à la vue sur des kilomètres`,
    ],
    speed: 2,
  },
  {
    armor: 1,
    attacks: [
      `Applique Radiations Ⅳ`,
      `80% : transmets le Petit Mal`,
      `70% : applique Poison "Étouffeur" pendant 6 tours`,
      `50% : transmets la Putride`,
      `25% : transmets la Peste bubonique`,
    ],
    damage: [`8 + 2x1d6 + DR`, ``, ``],
    health: 65,
    hit: [85, 0, 0],
    initiative: 4,
    name: `Porteur de peste`,
    speed: 3,
  },
  {
    armor: 3,
    abilities: [`Crachat toxique : crache un bézoard purulent vers une cible avec 25% de chances de l'atteindre, transmets un cocktail de 3 maladies`],
    attacks: [
      `Applique Saignements Ⅲ pour 12 tours`,
      `Applique Poison "Essouffleur" permanent`,
    ],
    damage: [`35 + 4x1d8 + DR`, ``, ``],
    health: 450,
    hit: [80, 0, 0],
    initiative: 4,
    name: `Abysse putride`,
    speed: 5,
  },
  {
    armor: 4,
    attacks: [`Applique Saignement Ⅱ pour 3 tours`],
    damage: [`24 + 4x1d6 + 2xDR`, `14 + 4x1d4 + DR`, ``],
    health: 160,
    hit: [85, 75, 0],
    initiative: 5,
    magic: [
      { school: Spells.SCHOOL.NATURE_WATER, level: `80 %` },
      { school: Spells.SCHOOL.FORGE, level: `60 %` },
      { school: Spells.SCHOOL.DUSK, level: `40 %` },
    ],
    mana: 20,
    name: `Goule des bois`,
    speed: 3,
  },
  {
    abilities: [Monsters.Skills.HurlementStrident],
    armor: 5,
    attacks: [`Applique Radiations Ⅱ`],
    damage: [`22 + 3x1d8 + DR`, ``, ``],
    health: 180,
    hit: [90, 0, 0],
    initiative: 3,
    name: `Mutant sylvestre`,
    speed: 3,
  },
  {
    armor: 6,
    attacks: [`Applique Saignements Ⅱ pour 6 tours`],
    damage: [`28 + 4x1d6 + DR`, ``, ``],
    health: 140,
    hit: [90, 0, 0],
    initiative: 4,
    magic: [{ school: Spells.SCHOOL.NATURE_WATER, level: `90 %` }],
    mana: 15,
    name: `Goule du désert`,
    speed: 3,
  },
  {
    armor: 5,
    attacks: [`(PC) 80 % : renverse la cible au sol`],
    health: 130,
    hit: [85, 70, 25],
    initiative: 4,
    items: [
      `Arme lourde simple ou exotique`,
      `Armure de cuir partielle`
    ],
    name: `Thog sauvage`,
    speed: 4,
    skills: [
      { skill: `Intimidation`, value: `65 %` }
    ],
  },
  {
    armor: 3,
    attacks: [
      `Applique Radiations Ⅲ`,
      `(PM) 35 % : paralyse pendant 2 tours`
    ],
    damage: [`42 + 5x1d6 + DR`, ``, ``],
    health: 360,
    hit: [85, 65, 0],
    initiative: 3,
    name: `Roi Fangetue`,
    passives: [
      `Groupe : se trouve généralement avec un groupe de 2 à 5 Fangetue [1 + 1d4]`,
      Monsters.Skills.Chef('Fangetue', 2),
    ],
    speed: 2,
  },
  {
    abilities: [`Explosion de radiations : applique Radiations V aux personnes proches`],
    armor: 1,
    attacks: [`Applique Radiations Ⅳ`],
    damage: [`8 + 2x1d6 + DR`, ``, ``],
    health: 40,
    hit: [95, 0, 0],
    initiative: 5,
    name: `Irradié`,
    passives: [`Zone radioactive : les alentours appliquent Radiations Ⅲ à la fin de chaque tour même après le décès`],
    speed: 3,
  },
  {
    armor: 4,
    attacks: [`Renverse la cible au sol`],
    damage: [`25 + 4x1d10 + 2xDR`, ``, ``],
    health: 170,
    hit: [65, 95, 0],
    initiative: 4,
    name: `Gomutant`,
    speed: 3,
  },
  {
    abilities: [`Feindre la mort : redevient conscient au bout de 2 tours après chaque décès non-létal`],
    armor: 1,
    damage: [`16 + 2x1d6 + DR`, ``, ``],
    health: 110,
    hit: [85, 0, 0],
    initiative: 4,
    name: `Mutant XE-12`,
    speed: 4,
  },
  {
    abilities: [
      `Couvée : frappe une cible d'estoc et pond une larve dans son corps si l'attaque touche`,
      `Explosion de larves : provoque l'explosion d'une ou plusieurs larves proches, ce qui inflige 30 + 3x1d10 blessures et applique Saignements d'un rang de 1 + 1d4`
    ],
    attacks: [
      `Touche toutes les cibles sur 180° dans la direction attaquée (frappe de taille)`,
      `80 % : renverse au sol`
    ],
    damage: [`12 + 2x1d6 + DR`, ``, ``],
    health: 60,
    hit: [75, 0, 0],
    name: `Couveuse`,
    passives: [`Récupération radioactive : soigne une blessure à la fin de chaque tour`],
    speed: 2,
  },
  {
    attacks: [
      `Rend fou pendant 1 heure`,
      `Gagne un point de folie`
    ],
    health: 90,
    initiative: 3,
    name: `Mange-esprit`,
    passives: [Monsters.Skills.Group(`4 à 9 [3 + 1d6]`)],
    speed: 3,
  },
  {
    armor: 2,
    attacks: [
      `80% : applique Saignements Ⅱ pour 6 tours`,
      `60% : rend fou pendant 120 tours (10 minutes)`,
      `40% : gagne un point de folie`,
      `20% : transmets la Putride`,
    ],
    damage: [`18 + 3x1d4 + DR`, ``, ``],
    health: 110,
    hit: [90, 0, 0],
    initiative: 4,
    name: `Horreur des profondeurs fanatique`,
    speed: 2,
    show: [
      `Gagne un point de folie`,
      `60 % : rend fou pendant 120 tours (10 minutes)`,
    ],
  },
  {
    armor: 3,
    attacks: [`Applique Radiations V`],
    damage: [`12 + 2x1d8 + DR`, ``, ``],
    death: [`L'aura radioactive continue à faire effet`],
    health: 90,
    hit: [90, 0, 0],
    initiative: 5,
    name: `Avatar de la radioactivité`,
    passives: [
      Monsters.Skills.RégénérationRadioactive,
      Monsters.Skills.AuraRadioactive('V'),
    ],
    show: [`40% : effraie les personnes proches`],
    speed: 3,
  },
  {
    abilities: [`Vol : vole dans le ciel en haute altitude, difficile à détecter`],
    death: [`Pluie radioactive : applique Radiations V à la fin de chaque tour pendant 3 tours`],
    health: 85,
    name: `Flotteur irradié`,
    passives: [Monsters.Skills.AuraRadioactive('Ⅲ')],
    speed: 4,
  },
  {
    armor: 4,
    attacks: [
      `Applique Poison "Étouffeur" pour 30 tours`,
      `Les attaques PM ignorent l'armure`,
    ],
    damage: [`20 + 5x1d6 + DR`, ``, ``],
    health: 210,
    hit: [95, 85, 0],
    initiative: 6,
    name: `Reine mut-fourmi`,
    passives: [`Gestation : peu après la mort ou lors de blessures infligées dans le ventre, libère 1d4 monstres « Mut-fourmi géante », ne fonctionne qu'une seule fois`],
    speed: 3,
  },
  {
    armor: 6,
    attacks: [
      `Réduit l'Initiative de la cible de 1 pendant 6 tours`,
      `Applique Radiations V et laisse une flaque au sol qui applique Radiations Ⅲ par tour pendant 12 tours`
    ],
    damage: [`32 + 4x1d6 + DR`, ``, ``],
    death: [`Provoque une explosion de gelée qui applique Radiations V aux individus dans un rayon de 3 mètres`],
    health: 290,
    hit: [110, 90, 75],
    initiative: 4,
    name: `Roi des centaures`,
    passives: [
      `Groupe: se trouve généralement avec un groupe de 4 à 9 Centaures [3 + 1d6]`,
      Monsters.Skills.Chef('Centaures', 6),
    ],
    speed: 2,
  },
  {
    armor: 3,
    attacks: [`Applique Radiations Ⅲ`],
    damage: [`18 + 6x1d6 + 3xDR`, ``, ``],
    health: 80,
    hit: [110, 0, 0],
    initiative: 8,
    name: `Goule cellulaire`,
    passives: [
      `Récupération radioactive: soigne une blessure à la fin de chaque tour`,
      `Gestation: peu après la mort ou lors de blessures infligées dans le ventre, libère 1d4 monstres « Parasite », ne fonctionne qu'une seule fois`
    ],
    speed: 4,
  },
  {
    abilities: [`Odeur putride : les personnes proches tombent inconscientes si elles échouent un test de Volonté`],
    attacks: [`Applique Saignements Ⅳ permanent`],
    damage: [`36 + 3x1d8 + DR`, ``, ``],
    health: 370,
    hit: [115, 0, 0],
    initiative: 4,
    name: `Mange-mort`,
    passives: [Monsters.Skills.Résistance("Dos", 5)],
    speed: 5,
  },
  {
    attacks: [`Applique Saignements V permanent`],
    damage: [`42 + 10x1d4 + 3xDR`, ``, ``],
    health: 160,
    hit: [110, 0, 0],
    initiative: 8,
    name: `Déchiqueteur`,
    show: [`Gagne un point de folie`],
    speed: 3,
  },
  {
    abilities: [Monsters.Skills.Tremblement],
    armor: 12,
    damage: [`80 + 5x1d10 + 5xDR`, ``, ``],
    health: 650,
    hit: [95, 40, 0],
    items: [
      `Grosse arme quelconque de fortune`,
      `Grosse armure de fortune partielle ou complète`
    ],
    name: `Béhémoth Thog sauvage`,
    show: [`Effraie les individus proches`],
    speed: 5,
  },
  {
    abilities: [
      `« Brise navire » : brise un navire en deux`,
      `« Déluge » : provoque une vague gigantesque qui renverse les navires proches`,
      `« Balayage » : une attaque dont les dégâts sont réduits de moitié mais qui touche toutes les personnes dans la zone d'effet et les renverse par dessus bord`,
      `« Chancre du noyau » : une odeur nauséabonde paralyse les personnes dans un large rayon pendant 3 tours et transmets la Variole`,
    ],
    actions: `+1`,
    armor: 8,
    damage: [`125 + 10x1d10 + 5xDR`, ``, ``],
    death: [
      `« Prolifération » : 5 à 10 petits béhémoths s'échappent du cadavre telles des araignées et s'enfuient vers les profondeurs`,
    ],
    health: 1600,
    hit: [70, 40, 0],
    initiative: 5,
    name: `Béhémoth des mers`,
    show: [
      `Effraie les individus proches`,
      `65% de chances de renverser les navires proches`,
    ],
    speed: 20,
  },
  {
    abilities: [
      `« Consomme » : absorbe et consomme n'importe quoi, un arbre, un bâtiment, un navire, ... ce qui augmente le niveau d'absorption de 1 et fait grossir l'avaleur de mondes`,
      `« Relâchement » : des spores étranges émanent du mutant et applique le poison Rêvoilé aux personnes aux alentours`,
      `« Souffle ardent » : applique Brûlure V pendant 2 minutes aux personnes touchées`,
    ],
    armor: 8,
    damage: [`20 + 1d20`, ``, ``],
    health: 500,
    hit: [80, 0, 0],
    initiative: 4,
    name: `Avaleur de mondes`,
    passives: [
      `« Absorption » : les points de vie et les dégâts sont multipliés par le niveau d'absorption - afin de déterminer celui-ci lors de la rencontre initiale, il faut tirer 1d100 - si le niveau d'absorption de l'avaleur de mondes atteint 1000, il atteint plus ou moins la taille d'une planète`,
      `« Rayonnement » : les personnes proches subissent Radiations V à la fin de chaque tour`,
      `« Sensibilité magique » : les dégâts subit des sorts sont doublés`,
      `« Tremblement » : à partir d'un niveau d'absorption de 20, chaque déplacement provoque des tremblements qui renversent les personnes proches au sol`,
    ],
    speed: 10,
  },
];

export default data.map((i) => ({
  ...i,
  types: [Monsters.Type.MUTANT],
}));
