import PropTypes from 'prop-types';

import toCamelCase from 'lib/toCamelCase';
import { Label } from 'components/elements';

export function Skill({ category, type }) {
  return (
    <Label color="lightgray">
      {CHARACTER.SKILLS[category][type]}
    </Label>
  );
}

Skill.propTypes = {
  category: PropTypes.oneOf(Object.keys(CHARACTER.SKILLS)),
  type: PropTypes.oneOf(Object.keys(CHARACTER.SKILLS).reduce((prev, category) => ([...prev, ...Object.keys(CHARACTER.SKILLS[category])]), [])),
};

const components = {};

for (const category in CHARACTER.SKILLS) {
  for (const type in CHARACTER.SKILLS[category]) {
    const prettyCategory = toCamelCase(category);
    const prettyType = toCamelCase(type);
    if (!components[prettyCategory]) {
      components[prettyCategory] = {};
    }
    components[prettyCategory][prettyType] = () => (<Skill category={category} type={type} />);
  }
}

export default components;
