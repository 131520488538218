import Skills from 'components/afterworld/Skills';
import { PageHeading } from 'components/elements';
import { Table, Text, Title, Quote } from 'components/semantic';

export function ManualGlossaryPage() {
  return (
    <>
      <PageHeading>
        Glossaire
      </PageHeading>

      <Title>Action et test</Title>

      <Text>
        {`Lorsqu'un personnage effectue une action, un test de compétence est demandé. Ce test est réussi si le score obtenu sur le dé est inférieur ou égal au score de la compétence. Ce test nécessite de lancer 1d100.`}
      </Text>

      <Text>
        Par exemple, si un personnage veut effectuer une action de crochetage avec une compétence en <Skills.Mastery.Agility /> à 45, il doit lancer 1d100. Si le score obtenu est inférieur ou égal à 45, l'action est réussie.
      </Text>

      <Text>
        {`Cela fonctionne de la même manière pour la magie.`}
      </Text>

      <Title>Degré de réussite</Title>

      <Text>
        {`Lors d'un test, le degré de réussite s'obtient en comparant le score du dé et le score de la compétence. La différence des dizaines correspond au degré de réussite.`}
      </Text>

      <Text>
        Par exemple, lors d'un tir avec un Fusil Sniper avec une compétence en <Skills.Weapons.Light /> à 85, le score obtenu sur le dé est 32. Le degré de réussite est de 5 : 8 - 3.
      </Text>

      <Quote>
        {`Le degré de réussite s'abrège avec DR`}
      </Quote>

      <Title>Vocabulaire</Title>

      <Table data={[
        ['MJ ou GM', 'Maître du Jeu ou Game Master'],
      ]} />
    </>
  );
}
