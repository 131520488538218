const aptitudes = [
  {
    effect: [`${CHARACTER.SKILLS.WEAPONS.HEAVY} +5`],
    name: `Amateur de gros calibre`,
  },
  {
    effect: [`${CHARACTER.SKILLS.MASTERY.ACUITY} +5`],
    name: `Attentif`,
  },
  {
    effect: [`${CHARACTER.SKILLS.WEAPONS.MELEE} +5`],
    name: `Bagarreur`,
  },
  {
    effect: [`${CHARACTER.SKILLS.MASTERY.INTELLECT} +5`],
    name: `Brillant`,
  },
  {
    effect: [`${CHARACTER.SKILLS.SOCIAL.TALKING} +5`],
    name: `Charismatique`,
  },
  {
    effect: [`${CHARACTER.SKILLS.MASTERY.STRENGTH} +5`],
    name: `Costaud`,
  },
  {
    effect: [`${CHARACTER.SKILLS.KNOWLEDGE.CULTURE} +5`],
    name: `Cultivé`,
  },
  {
    effect: [`${CHARACTER.SKILLS.SOCIAL.EMPATHY} +5`],
    name: `Empathique`,
  },
  {
    effect: [`Endurance +1`],
    name: `Endurant`,
  },
  {
    effect: [`${CHARACTER.SKILLS.WEAPONS.LIGHT} +5`],
    name: `Fine gâchette`,
  },
  {
    effect: [`${CHARACTER.SKILLS.DEFENSES.COLD_BLOOD} +5`],
    name: `Impassible`,
  },
  {
    effect: [`${CHARACTER.SKILLS.KNOWLEDGE.ENGINEERING} +5`],
    name: `Ingénieux`,
  },
  {
    effect: [`${CHARACTER.SKILLS.SOCIAL.INTIMIDATION} +5`],
    name: `Intimidant`,
  },
  {
    effect: [`${CHARACTER.SKILLS.WEAPONS.THROWING} +5`],
    name: `Jongleur`,
  },
  {
    effect: [`Magie de l'école "${SPELLS.SCHOOL.FORGE}" +5`],
    name: `Magicien ardent`,
  },
  {
    effect: [`Magie de l'école "${SPELLS.SCHOOL.DAWN}" +5`],
    name: `Magicien lumineux`,
  },
  {
    effect: [`Magie de l'école "${SPELLS.SCHOOL.AWAKENING}" +5`],
    name: `Magicien naturel`,
  },
  {
    effect: [`Magie de l'école "${SPELLS.SCHOOL.DUSK}" +5`],
    name: `Magicien sombre`,
  },
  {
    effect: [`Magie de l'école "${SPELLS.SCHOOL.TEMPEST}" +5`],
    name: `Magicien tempétueux`,
  },
  {
    effect: [`${CHARACTER.SKILLS.KNOWLEDGE.SURVIVAL} +5`],
    name: `Méthodique`,
  },
  {
    effect: [`${CHARACTER.SKILLS.KNOWLEDGE.BIOLOGY} +5`],
    name: `Naturaliste`,
  },
  {
    effect: [`${CHARACTER.SKILLS.SOCIAL.NEGOTIATE} +5`],
    name: `Négociateur`,
  },
  {
    effect: [`Armure +1`],
    name: `Peau de pierre`,
  },
  {
    effect: [`${CHARACTER.SKILLS.DEFENSES.PSYCHOLOGY} +5`],
    name: `Psychologue`,
  },
  {
    effect: [`Charge +1`],
    name: `Reins solides`,
  },
  {
    effect: [`Mental +1`],
    name: `Résilient`,
  },
  {
    effect: [`${CHARACTER.SKILLS.DEFENSES.WILL} +5`],
    name: `Résolu`,
  },
  {
    effect: [`${CHARACTER.SKILLS.DEFENSES.VIGOR} +5`],
    name: `Robuste`,
  },
  {
    effect: [`${CHARACTER.SKILLS.MASTERY.AGILITY} +5`],
    name: `Souple`,
  },
  {
    effect: [`Initiative +1`],
    name: `Vivace`,
  },
];

export default aptitudes.map((a) => ({ ...a, level: 1, usable: 2 }));
