
import { List, Title } from 'components/semantic';
import { PageHeading } from 'components/elements';
import racesData from 'data/rules/races';

export function ManualRacePage() {
  return (
    <>
      <PageHeading>
        Race
      </PageHeading>
      <Title>Humain</Title>
      <List data={racesData.HUMAN}></List>
      <Title>Salbek</Title>
      <List data={racesData.SALBEK}></List>
      <Title>Thog</Title>
      <List data={racesData.THOG}></List>
      <Title>Cyborg</Title>
      <List data={racesData.CYBORG}></List>
      <Title>Elementaire</Title>
      <List data={racesData.ELEMENTAL}></List>
      <Title>Adrulens</Title>
      <List data={racesData.ADRULEN}></List>
    </>
  );
}
