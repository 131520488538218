import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useShallow } from 'zustand/react/shallow';

import knowledge from 'data/knowledge';
import { DataGridDisplay } from 'components/afterworld/data/GridDisplay';
import { Knowledge } from 'components/afterworld/data/Knowledge';
import { PageHeading } from 'components/elements';
import { useStore } from 'store';

const allSkills = Object.values(CHARACTER.SKILLS.ALL);

export function DataKnowledgePage() {
  const { related, search, setFilter } = useStore(useShallow((state) => ({
    related: state.filter_knowledge_related,
    search: state.filter_knowledge_search,
    setFilter: state.setFilter,
  })));

  const filteredData = knowledge
    .filter((datum) =>
      (!related || datum.related === related)
      && (!search || datum.name.toLowerCase().includes(search))
    );

  return (
    <>
      <PageHeading>
        Savoirs
      </PageHeading>

      <FormControl sx={{ mb: 1, width: '100%' }}>
        <InputLabel id="afterworld-knowledge__list-filter__label">
          Compétence en rapport
        </InputLabel>

        <Select
          labelId="afterworld-knowledge__list-filter__label"
          id="afterworld-knowledge__list-filter"
          value={related}
          label="Niveau"
          onChange={(e) => { setFilter('knowledge', 'related', e.target.value); }}
        >
          <MenuItem value=""><em>Aucun filtre</em></MenuItem>
          {allSkills.map((filterItem) => (
            <MenuItem key={filterItem} value={filterItem}>{filterItem}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl sx={{ mb: 1, width: '100%' }}>
        <TextField
          id="afterworld-knowledge__list-search"
          label="Rechercher le savoir"
          onChange={(e) => { setFilter('knowledge', 'search', e.target.value.toLowerCase()); }}
          size="small"
          value={search}
          variant="outlined"
        />
      </FormControl>

      <DataGridDisplay
        data={filteredData}
        density="comfortable"
        mobileComponent={Knowledge}
      />
    </>
  );
}
