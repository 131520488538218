const aptitudes = [
  {
    effect: [`Nouvelle magie spécialisée`],
    name: `Apprenti sorcier`,
  },
  {
    effect: [
      `L'action de feinte devient une action gratuite (une seule action gratuite est possible par tour)`,
      `L'attaque après une feinte bénéficie désormais des mêmes bonus qu'une attaque furtive`
    ],
    name: `Bretteur hors pair`,
  },
  {
    effect: [
      `Plus de limite d'esquive par tour`,
      `Possible d'esquiver même au sol`,
    ],
    name: `Contorsionniste`,
  },
  {
    effect: [
      `Il devient toujours possible d'effectuer des actions pleines même lors d'un état de fatigue`,
      `Se reposer en combat récupère désormais 2 points de fatigue`,
    ],
    name: `Dernier souffle`,
  },
  {
    effect: [
      `Combat -10`,
      `Maîtrise +10`,
    ],
    name: `Dévotion corporelle`,
  },
  {
    effect: [
      `Combat -10`,
      `Défenses +10`,
    ],
    name: `Dévotion de protection`,
  },
  {
    effect: [
      `Compétences -5`,
      `Combat +25`,
    ],
    name: `Dévotion guerrière`,
  },
  {
    effect: [
      `Combat -10`,
      `Savoirs +10`,
    ],
    name: `Dévotion scientifique`,
  },
  {
    effect: [
      `Combat -10`,
      `Social +10`,
    ],
    name: `Dévotion sociale`,
  },
  {
    effect: [
      `Sur la compétence ${CHARACTER.SKILLS.WEAPONS.LIGHT}, les attaques d'opportunité ne nécessitent plus d'être au contact`,
      `Chances de critiques +5% lors d'une attaque d'opportunité avec une arme légère`,
    ],
    name: `Gâchette facile`,
  },
  {
    effect: [
      `Réduit le coût en Mental des sorts d'invocation de 2`,
      `Canaliser une invocation et agir avec est désormais une action rapide`,
      `Désinvoquer une invocation est une action gratuite (une seule action gratuite est possible par tour)`,
    ],
    name: `Magicien invocateur`,
  },
  {
    effect: [
      `Nouvelle compétence de spécialité`,
      `Compétences de spécialité +5`,
    ],
    name: `Maître des spécialités`,
    requirements: [`Ne pas avoir l'aptitude 'Touche à tout'`],
  },
  {
    effect: [
      `Augmente les dégâts du personnage selon le degré de Saignements le plus élevé qu'il a sur lui : 20x le degré`,
      `Dépendance aux dégâts subit`,
    ],
    name: `Masochiste`,
  },
  {
    effect: [
      `Les attaques d'opportunité sont gratuites (une seule action gratuite est possible par tour)`,
      `Les attaques d'opportunité ne nécessitent plus de test d'Agilité`,
    ],
    name: `Opportuniste`,
  },
  {
    effect: [
      `La détonation des explosifs a toujours lieu au contact de la cible`,
      `Les lancers d'explosifs ne peuvent plus être renvoyés`,
    ],
    name: `Précision d'un horloger`,
  },
  {
    effect: [
      `Augmente les dégâts du personnage selon le degré de Brûlure le plus élevé qu'il a sur lui : 20x le degré`,
      `Dépendance au feu`,
    ],
    name: `Pyromane`,
  },
  {
    effect: [
      `Lors des attaques critiques, le montant des dégâts est doublé au lieu d'y ajouter le degré de réussite naturel`,
      `Lors des attaques critiques, les ennemis tués explosent en morceaux`,
    ],
    name: `Sanguinaire`,
  },
  {
    effect: [
      `Avec la compétence ${CHARACTER.SKILLS.WEAPONS.LIGHT} et une seule fois par tour, une des actions suivantes est gratuite.`,
      `Recharger | Engainer | Dégainer | Localiser`,
    ],
    name: `Tireur d'élite`,
  },
  {
    effect: [
      `Toutes les compétences +5`,
      `Compétences de spécialité -30`,
    ],
    name: `Touche à tout`,
    requirements: [`Ne pas avoir l'aptitude 'Maître des spécialités'`],
  },
  {
    effect: [`En combat, lorsque l'action est délayée, le personnage peut effectuer une action rapide supplémentaire`],
    name: `Traînard`,
  },
]

export default aptitudes.map((a) => ({ ...a, level: 10 }));
