import actionsData from 'data/rules/actions';
import { List, Quote, Subtitle, Text, Title } from 'components/semantic';
import { PageHeading } from 'components/elements';

export function ManualRoundPage() {
  return (
    <>
      <PageHeading>
        Déroulement
      </PageHeading>

      <Title>Les tours</Title>

      <Text>
        {`Contrairement au jeu narratif et théâtral habituel, les combats se déroulent au tour par tour, et chaque tour en combat dure 5 secondes.`}
      </Text>

      <Subtitle>Planification</Subtitle>

      <Text>
        {`Au début de chaque tour commence l'étape de planification, chaque joueur doit écrire les actions de son personnage, cette information doit rester secrète et les joueurs ne peuvent pas se concerter.`}
      </Text>

      <Quote>
        {`Il est possible de mettre un chronomètre pour limiter le temps de planification des actions`}
      </Quote>

      <Text>
        {`Une fois que chaque joueur a décidé de son action, il faut passer à l'étape d'initiative.`}
      </Text>

      <Subtitle>Initiative</Subtitle>

      <Text>
        {`Afin de déterminer l'ordre de résolution des actions prévues, chaque participant au combat doit lancer 1d10 et y ajouter son score d'Initiative.`}
      </Text>

      <Text>
        {`Ensuite, en partant du score le plus élevé, les participants décrivent l'action qu'ils avaient prévu de réaliser.`}
      </Text>

      <Title>Les actions</Title>

      <Text>
        {`Il est possible de planifier tout type d'action. Ces dernières sont regroupées en trois catégories : les actions pleines, les actions rapides et les actions libres.`}
      </Text>

      <Subtitle>Action pleine</Subtitle>

      <Text>
        {`Lors d'une action pleine, il n'est pas possible de faire autre chose.`}
      </Text>

      <List data={actionsData.FULL}></List>

      <Subtitle>Action rapide</Subtitle>

      <Text>
        {`Il est possible de faire deux actions rapides plutôt qu'une action pleine.`}
      </Text>

      <List data={actionsData.FAST}></List>

      <Subtitle>Action libre</Subtitle>

      <Text>
        {`Enfin, une fois par tour, une action libre peut être réalisée qu'importe les autres actions qui ont été faites.`}
      </Text>

      <List data={actionsData.FREE}></List>

      <Title>Changer les règles</Title>

      <Text>
        {`Il existe parfois des effets qui permettent aux personnages d'agir durant l'étape de planification ou à la fin de l'initiative. Ces cas de figure restent exceptionnels.`}
      </Text>

      <Text>
        {`Toutefois, il existe une possibilité disponible à tous : la réaction. En réaction à l'action d'un autre participant au combat, un personnage peut effectuer une action rapide s'il le désire. Cela engendrera toutefois un point de fatigue.`}
      </Text>
    </>
  );
}
