import { Monsters } from 'data/metadata';

const data = [
  {
    armor: 2,
    health: 90,
    hit: [60, 35],
    initiative: 2,
    items: [
      `Arme légère ou de corps à corps simple`,
      `Armure de peau ou de cuir partielle`
    ],
    name: `Fuyard`,
    speed: 4,
    skills: [
      { skill: `Sang froid`, value: `5 %` },
    ],
  },
  {
    armor: 3,
    health: 100,
    hit: [75, 0, 0],
    initiative: 3,
    items: [
      `Arme de corps-à-corps simple`,
      `Armure de peau ou de cuir partielle`
    ],
    name: `Fermier`,
    speed: 3,
  },
  {
    armor: 3,
    health: 100,
    hit: [85, 60, 20],
    initiative: 5,
    items: [
      `Arme légère simple`,
      `Armure de cuir partielle`
    ],
    name: `Pillard isolé`,
    speed: 3,
    skills: [
      { skill: `Intimidation`, value: `80 %` }
    ],
  },
  {
    armor: 5,
    health: 100,
    hit: [90, 80, 40],
    initiative: 6,
    items: [
      `Arme légère ou lourde simple`,
      `Armure de cuir complète`
    ],
    name: `Mercenaire`,
    speed: 3,
    skills: [
      { skill: `Social`, value: `45 %` }
    ],
  },
  {
    armor: 7,
    health: 0,
    hit: [65, 95, 0],
    initiative: 5,
    items: [
      `Arme légère simple`,
      `Arme de corps-à-corps simple`,
      `Armure cuir complète`
    ],
    name: `Garde de village`,
    speed: 3,
  },
  {
    armor: 5,
    health: 110,
    hit: [90, 85, 0],
    initiative: 8,
    items: [
      `Arme de corps à corps simple ou exotique`,
      `Armure de peau ou de cuir complète`
    ],
    name: `Bienfaiteur des forêts`,
    speed: 4,
    skills: [
      { skill: `Vigueur`, value: `80 %` },
      { skill: `Agilité`, value: `90 %` },
    ],
  },
  {
    armor: 5,
    health: 110,
    hit: [90, 75, 55],
    initiative: 6,
    items: [
      `Arme de corps à corps simple ou exotique`,
      `Armure de tissu ou de cuir complète`
    ],
    name: `Cultiste`,
    speed: 3,
    skills: [
      { skill: `Intelligence`, value: `10 %` },
      { skill: `Défenses`, value: `60 %` },
    ],
  },
  {
    armor: 4,
    health: 100,
    hit: [95, 90, 35],
    initiative: 7,
    items: [
      `Arme de corps à corps simple`,
      `Arme à distance simple`,
      `Armure de cuir complète`,
      `5% de chances d'avoir un véhicule`,
    ],
    name: `Pillard des terres dévastées`,
    passives: [Monsters.Skills.Group(`3 à 6 [2 + 1d4]`)],
    speed: 3,
    skills: [
      { skill: `Social`, value: `60 %` },
      { skill: `Défenses`, value: `60 %` },
    ],
  },
  {
    armor: 6,
    health: 100,
    hit: [80, 95, 80],
    initiative: 5,
    items: [
      `Arme légère simple ou exotique`,
      `Armure assistée partielle ou complète`
    ],
    name: `Dissident`,
    speed: 3,
    skills: [
      { skill: `Social`, value: `60 %` },
      { skill: `Défenses`, value: `40 %` },
    ],
  },
  {
    armor: 5,
    health: 100,
    hit: [80, 75, 85],
    initiative: 5,
    items: [
      `Arme quelconque, simple ou exotique`,
      `Armure de tissu ou de cuir partielle ou complète`
    ],
    name: `Récupérateur`,
    speed: 3,
    skills: [
      { skill: `Savoirs`, value: `50 %` },
    ],
  },
  {
    armor: 5,
    health: 100,
    hit: [80, 70, 65],
    initiative: 7,
    items: [
      `Arme quelconque, simple ou exotique`,
      `Armure de cuir renforcé complète`
    ],
    name: `Caravanier`,
    speed: 3,
    skills: [
      { skill: `Savoirs`, value: `55 %` },
      { skill: `Social`, value: `75 %` },
      { skill: `Défenses`, value: `55 %` },
      { skill: `Maîtrise`, value: `45 %` },
    ],
  },
  {
    armor: 8,
    health: 110,
    hit: [110, 0, 0],
    initiative: 8,
    items: [
      `Arme de corps-à-corps exotique`,
      `Armure assistée complète`
    ],
    name: `Arbitre`,
    speed: 3,
    skills: [
      { skill: `Défenses`, value: `85 %` },
      { skill: `Maîtrise`, value: `60 %` },
    ],
  },
  {
    armor: 8,
    health: 100,
    hit: [80, 85, 80],
    initiative: 8,
    items: [
      `Arme légère à énergie simple ou exotique`,
      `Armure assistée complète`
    ],
    name: `Paladin`,
    speed: 3,
    skills: [
      { skill: `Savoirs`, value: `60 %` },
      { skill: `Social`, value: `70 %` },
      { skill: `Défenses`, value: `60 %` },
    ],
  },
  {
    armor: 6,
    health: 110,
    hit: [60, 110, 90],
    initiative: 10,
    items: [
      `Arme légère, simple ou exotique`,
      `Armure assistée complète`
    ],
    name: `Militaire`,
    speed: 3,
    skills: [
      { skill: `Social`, value: `40 %` },
      { skill: `Défenses`, value: `70 %` },
      { skill: `Maîtrise`, value: `60 %` },
    ],
  },
  {
    armor: 8,
    health: 110,
    hit: [110, 0, 0],
    initiative: 7,
    items: [
      `Arme de corps-à-corps exotique`,
      `Arme explosive exotique`,
      `Armure assistée exotique complète`
    ],
    name: `Sombre Garde Adrulen`,
    speed: 3,
    skills: [
      { skill: `Maîtrise`, value: `110 %` },
    ],
  },
  {
    armor: 5,
    health: 110,
    hit: [0, 100, 0],
    initiative: 12,
    items: [
      `Arme légère à énergie exotique`,
      `Arme explosive exotique`,
      `Armure assistée exotique complète`
    ],
    name: `Sombre Tireur Adrulen`,
    speed: 3,
    skills: [
      { skill: `Maîtrise`, value: `100 %` }, ,
    ],
  },
  {
    armor: 3,
    health: 90,
    hit: [100, 0, 0],
    initiative: 3,
    items: [
      `Arme de corps-à-corps exotique`,
      `Arme en tissu ou en cuir complète`,
    ],
    name: `Politicien`,
    skills: [
      { skill: `Social`, value: `140 %` },
      { skill: `Défenses`, value: `90 %` },
    ],
    speed: 3,
  },
  {
    name: `Vétéran entraîné`,
    armor: 5,
    health: 100,
    hit: [110, 110, 110],
    initiative: 6,
    items: [
      `Arme lourde exotique`,
      `Arme légère exotique`,
      `Armure assistée exotique`
    ],
    speed: 3,
    skills: [
      { skill: `Combat`, value: `110 %` },
      { skill: `Savoirs`, value: `75 %` },
      { skill: `Défenses`, value: `80 %` },
      { skill: `Maîtrise`, value: `90 %` },
    ],
  },
  {
    actions: `+2`,
    armor: 3,
    attacks: [`Applique poisons ou maladies`],
    health: 120,
    hit: [140, 130, 0],
    initiative: 12,
    items: [
      `Arme de corps-à-corps exotique x2`,
      `Arme de jet exotique`,
      `Armure en cuir exotique`
    ],
    name: `Rôdeur solitaire`,
    speed: 4,
    skills: [
      { skill: `Armes`, value: `130 %` },
      { skill: `Social`, value: `80 %` },
      { skill: `Maîtrise`, value: `110 %` },
    ],
  },
];

export default data.map((i) => ({
  ...i,
  types: [Monsters.Type.HUMAN],
}));
