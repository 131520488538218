
import { Image, Subtitle, Text, Title } from 'components/semantic';
import { PageHeading } from 'components/elements';

export function UniverseKnowledgeMagicPage() {
  return (
    <>
      <PageHeading>
        La magie
      </PageHeading>

      <Title>La magie élémentaire</Title>

      <Subtitle>L'école de l'Aube</Subtitle>

      <Text>
        {`L'Aube resplendit comme le premier rayon du soleil perçant l'obscurité, baignant le monde d'une chaleur apaisante et d'une clarté bienveillante. Ses adeptes, souvent perçus comme des gardiens et des guérisseurs, canalisent une énergie pure qui revitalise les corps fatigués et ranime les âmes égarées. Chaque incantation est une prière aux forces du renouveau, un souffle divin qui repousse la corruption et fait fleurir l'espoir. Mais derrière cette lumière éclatante se cache aussi un poids immense : celui du devoir et du sacrifice, car offrir la vie à autrui peut exiger un tribut insoupçonné.`}
      </Text>

      <Image src="/images/manual/dawn.webp" />

      <Subtitle>L'école du Crépuscule</Subtitle>

      <Text>
        {`Le Crépuscule est une caresse glaciale, un frisson qui serpente dans l'air avant de s'évanouir dans l'inconnu. Ses disciples s'aventurent là où peu osent poser le pied, conversant avec les murmures des esprits et déliant les fils fragiles de l'existence. Ils tissent des ombres mouvantes et façonnent des illusions troublantes, cachant la vérité derrière un voile en perpétuel changement. On les craint, on les fuit, car leur magie, insidieuse et capricieuse, joue avec les lois de la vie et de la mort. Mais eux seuls comprennent que l'ombre n'est pas forcément maléfique, et que parfois, embrasser la nuit est le seul moyen d'échapper aux ténèbres.`}
      </Text>

      <Image src="/images/manual/dusk.webp" />

      <Subtitle>L'école de la Tempête</Subtitle>

      <Text>
        {`La Tempête est un cri furieux déchirant les cieux, une force brute et indomptable qui danse entre frénésie et destruction. Ses maîtres sont des âmes libres, tourbillonnant au gré du vent, insaisissables comme l'éclair frappant sans prévenir. Ils jonglent avec des rafales hurlantes et des arcs électriques, défiant la pesanteur et la raison avec une aisance déconcertante. À leur passage, les éléments se déchaînent, sculptant un monde en perpétuel mouvement. Mais la Tempête n'est pas qu'un chaos aveugle : elle est aussi la promesse du changement, un souffle neuf qui balaie l'ancien pour ouvrir la voie à un avenir insoupçonné.`}
      </Text>

      <Image src="/images/manual/tempest.webp" />

      <Subtitle>L'école de l'Éveil</Subtitle>

      <Text>
        {`L'Éveil est un murmure ancien qui résonne dans le vent et coule au fil des rivières, tissant un lien invisible entre chaque être vivant. Ceux qui l'écoutent ressentent la pulsation du monde sous leurs pieds, entendent la terre respirer et comprennent les secrets murmurés par les feuillages. Ils façonnent l'eau comme une extension de leur propre chair, font éclore la vie d'un simple geste et réparent ce que le temps a brisé. Mais l'Éveil n'est pas qu'harmonie et douceur : il est aussi la force implacable de la nature en colère, l'ouragan qui déracine, l'océan qui engloutit. Respecter son équilibre, c'est comprendre que la vie et la destruction ne sont que deux facettes d'une même essence.`}
      </Text>

      <Image src="/images/manual/awakening.webp" />

      <Subtitle>L'école de la Forge</Subtitle>

      <Text>
        {`La Forge est le grondement sourd d'un volcan en éveil, la force brute qui modèle le monde à coups de marteau et d'incendies dévorants. Ses adeptes sont des bâtisseurs et des destructeurs à la fois, sculptant le métal et façonnant la pierre, embrassant le feu comme un maître exigeant mais généreux. Ils marchent sur des sentiers brûlants, leurs veines vibrant d'une énergie incandescente, et transforment tout ce qu'ils touchent en armes redoutables ou en remparts inébranlables. Pourtant, derrière leur puissance brute se cache un sens aigu du devoir, car la Forge ne se contente pas de consumer : elle purifie, elle transforme, elle élève. Ceux qui la servent ne se contentent pas d'exister – ils façonnent l'avenir, un battement de cœur de lave après l'autre.`}
      </Text>

      <Image src="/images/manual/forge.webp" />

      <Title>La magie avancée</Title>

      <Subtitle>L'école de l'Ascension</Subtitle>

      <Text>
        {`L'École de l'Ascension est un sanctuaire silencieux, où la pensée s'élève comme un souffle léger dans l'aube brumeuse. Ses adeptes, maîtres des fils invisibles qui relient les âmes, exercent un contrôle subtil sur l'esprit des autres, sculptant l'univers mental à leur image. Leurs gestes, discrets mais puissants, sont empreints de sérénité, et leurs voix, comme des murmures, provoquent des révélations intérieures. Les élèves, leurs yeux remplis de clarté, parcourent les arcanes de la télépathie, où chaque pensée devient une arme et chaque rêve, une réalité fragile.`}
      </Text>

      <Image src="/images/manual/ascend.webp" />

      <Subtitle>L'école de la Transcendance</Subtitle>

      <Text>
        {`L'École de la Transcendance s'épanouit dans une architecture changeante, sans cesse remodelée par les volontés de ceux qui y vivent. Les disciples de la Transcendance sont des voyageurs d'âmes et de corps, des êtres dont la forme se transforme au gré des dimensions. Ils scrutent les failles entre les mondes, explorant les mutations, les métamorphoses et les anomalies des réalités parallèles. Ils portent en eux l'ambiguïté des êtres transfigurés, où l'apparence n'est qu'un masque qui dissimule l'évolution infinie de leur essence.`}
      </Text>

      <Image src="/images/manual/transcendance.webp" />

      <Subtitle>L'école de l'Éclipse</Subtitle>

      <Text>
        {`L'École de l'Éclipse se trouve dans une époque figée, où chaque seconde se suspend comme un fil ténu. Ici, les maîtres du temps tissent des sortilèges d'une infinie complexité, manipulant la lumière et l'ombre du passé, du présent et de l'avenir. Les élèves, le regard lourd de sagesse et de tourments, commandent les rythmes de l'univers, déchirant parfois le voile du moment pour provoquer des distorsions qui effleurent l'éternité. Leur magie n'est jamais linéaire, et chaque action en cache une autre, chaque souffle une dimension du temps insoupçonnée.`}
      </Text>

      <Image src="/images/manual/eclipse.webp" />

      <Title>Les arts inconnus</Title>

      <Subtitle>Magie des Gravures</Subtitle>

      <Text>
        {`Dans les forges silencieuses et les ateliers d'argile, les maîtres des Gravures sculptent des symboles ancestraux avec une précision hypnotique. À l'aide de leurs outils affûtés, ils gravent des runes dans la pierre, le métal et le bois, insufflant dans chaque incise une puissance ancienne et implacable. Les artefacts qu'ils créent ne sont pas de simples objets, mais des réservoirs d'énergie, des canaux d’influences mystiques capables de modifier la réalité elle-même. Leur art est un mélange de science et de sorcellerie, où chaque rune, chaque sigil, est une porte vers un monde inconnu. Ces façonneurs d’âme et de matière sculptent non seulement le monde extérieur, mais aussi les forces invisibles qui l’animent.`}
      </Text>

      <Image src="/images/manual/carving.webp" />

      <Subtitle>Magie de l'Harmonie</Subtitle>

      <Text>
        {`Dans des salles où résonnent des échos d'anciennes mélodies, la magie de l'Harmonie se tisse au fil des notes. Les magiciens-musiciens, leurs instruments comme des extensions de leurs âmes, façonnent des sorts aussi fluides et puissants que la musique elle-même. Leur savoir se transmet dans des mouvements gracieux, où chaque vibration de corde ou souffle d’air devient un acte sacré, orchestrant l’équilibre subtil entre les mondes. Ils jouent non seulement des sons, mais des émotions, des énergies pures qui fusionnent en une symphonie de forces invisibles. Leurs créations sont comme des harmonies entrelacées, capables de guérir ou de détruire, selon l'intention qu'elles insufflent.`}
      </Text>

      <Image src="/images/manual/harmonic.webp" />

      <Subtitle>Magie de la Chorégraphie</Subtitle>

      <Text>
        {`Les magiciens de la Chorégraphie, souvent solitaires et autodidactes, déploient leur art avec l'agilité d'un danseur inspiré. À travers des pas et des gestes précis, ils orchestrent les mouvements de petits élémentaires d'Albâtre, des créatures de lumière et de sable, qui prennent vie à chaque geste. Ces éléments, fragiles et malléables, suivent les cadences imposées, tissant des sorts invisibles au rythme de la danse. Chaque mouvement, chaque pirouette, est une invitation à l’équilibre et à la transformation, comme une danse entre le monde physique et le royaume des esprits. Leur magie est une chorégraphie divine, fluide et éphémère, marquant de son empreinte la frontière entre l'art et la puissance.`}
      </Text>

      <Image src="/images/manual/choregraphic.webp" />

    </>
  );
}
