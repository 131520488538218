import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useParams } from 'react-router-dom';

import monsters from 'data/monsters';
import { Image, Title, Table } from 'components/semantic';
import { Label, PageHeading } from 'components/elements';
import { PageRootContainer } from 'layout/PageRootContainer';

const mapping = {
  hit: { format: (v) => (`${v} %`), icon: 'hand-fist', text: 'Toucher' },
  damage: { icon: 'gun', text: 'Dégâts' },
  health: { icon: 'heart', text: 'Santé' },
  armor: { icon: 'shield-halved', text: 'Armure' },
  initiative: { format: (v) => (`+${v}`), icon: 'gauge-high', text: 'Initiative' },
  actions: { icon: 'stopwatch', text: 'Actions' },
  mana: { icon: 'meteor', text: 'Magie' },
  speed: { format: (v) => (`${v} m/s`), icon: 'person-running', text: 'Vitesse' },
};

export function MonsterPage() {
  const params = useParams();
  const monsterName = atob(params['*']);
  const monster = monsters.find((datum) => datum.name === monsterName);
  const {
    abilities,
    attacks,
    death,
    items,
    magic,
    name,
    passives,
    show,
    skills,
    types,
  } = monster;

  const monsterData = Object.keys(mapping)
    .filter((stat) => !!monster[stat])
    .map((stat) => ([
      <FontAwesomeIcon key={stat} icon={['fa', mapping[stat].icon]} />,
      mapping[stat].text,
      Array.isArray(monster[stat])
        ? (monster[stat].filter(_ => _).map(mapping[stat].format ? mapping[stat].format : (v) => (v)).join(' · '))
        : (mapping[stat].format ? mapping[stat].format(monster[stat]) : monster[stat]),
    ]));

  const monsterCapabilities = [];

  if (show) {
    show.forEach((s) => {
      monsterCapabilities.push([<Label color="lightgreen" key={s}>Apparition</Label>, s]);
    });
  }

  if (death) {
    death.forEach((d) => {
      monsterCapabilities.push([<Label color="lightgreen" key={d}>Décès</Label>, d]);
    });
  }

  if (passives) {
    passives.forEach((passive) => {
      monsterCapabilities.push([<Label color="lightgreen" key={passive}>Passif</Label>, passive]);
    });
  }

  if (attacks) {
    attacks.forEach((attack) => {
      monsterCapabilities.push([<Label color="lightgreen" key={attack}>Attaque</Label>, attack]);
    });
  }

  if (abilities) {
    abilities.forEach((ability) => {
      monsterCapabilities.push([<Label color="lightgreen" key={ability}>Technique</Label>, ability]);
    });
  }

  if (skills) {
    skills.forEach(({ skill, value }) => {
      monsterCapabilities.push([<Label color="lightgreen" key={skill}>{`Compétence - ${skill}`}</Label>, value]);
    });
  }

  if (magic) {
    magic.forEach(({ school, level }) => {
      monsterCapabilities.push([<Label color="lightgreen" key={school}>{`École - ${school}`}</Label>, level]);
    });
  }

  if (items) {
    items.forEach((item) => {
      monsterCapabilities.push([<Label color="lightgreen" key={item}>Objet</Label>, item]);
    });
  }

  return (
    <PageRootContainer target="/bestiary">
      <PageHeading backButton={(<Link to="/bestiary">
        < FontAwesomeIcon icon={['fa', 'arrow-left']} />
      </Link>)
      }>
        {name}
      </PageHeading >

      <Title>{types.join(' ')}</Title>

      <Image size="large" src={`/images/bestiary/1024/${name}.webp`}></Image>

      <Title>Statistiques</Title>

      <Table data={monsterData} sizes={[1, 3, 3]}></Table>

      {!!monsterCapabilities.length && (
        <>
          <Title>Competences</Title>
          <Table data={monsterCapabilities} sizes={[1, 2]}></Table>
        </>
      )}
    </PageRootContainer >
  );
}
