import { Element, Type, Subtype } from 'data/metadata';

const data = [
  {
    name: `Armure assistée militaire`,
    armor: {
      armLeft: 60,
      armRight: 60,
      chest: 60,
      head: 60,
      legLeft: 60,
      legRight: 60,
    },
    weight: 40,
    value: 610,
    effects: `Nécessite de l'énergie (consomme 300 PE par heure)`,
  },
  {
    name: `Armure en métal`,
    armor: {
      armRight: 30,
      armLeft: 30,
      chest: 30,
    },
    weight: 25,
    value: 43,
    effects: `Rouille / Agilité -5`,
  },
  {
    name: `Armure en cuir`,
    armor: {
      armRight: 5,
      armLeft: 5,
      chest: 5,
    },
    weight: 10,
    value: 22,
    effects: `Fragile`,
  },
  {
    name: `Armure en cuir renforcé`,
    armor: {
      armRight: 8,
      armLeft: 8,
      chest: 8,
    },
    weight: 15,
    value: 35,
  },
  {
    name: `Casque en métal`,
    armor: {
      head: 30,
    },
    weight: 2,
    value: 32,
    effects: `Rouille / Acuité -1`,
  },
  {
    name: `Casque en cuir`,
    armor: {
      head: 5,
    },
    weight: 1,
    value: 7,
    effects: `Fragile`,
  },
  {
    name: `Jambières en métal`,
    armor: {
      legRight: 30,
      legLeft: 30,
    },
    weight: 6,
    value: 29,
    effects: `Rouille`,
  },
  {
    name: `Jambières en cuir renforcé`,
    armor: {
      legRight: 8,
      legLeft: 8,
    },
    weight: 4,
    value: 6,
  },
  {
    armor: {
      head: 120,
      armRight: 120,
      armLeft: 120,
      chest: 120,
      legRight: 120,
      legLeft: 120,
    },
    description: `Un bouclier énergétique qui se déclenche lors de mouvements brusques et protège des attaques
    Modèle militaire avec une consommation réduite
    Nécessite de l'énergie (consomme 20 PE par point de dégât bloqué)`,
    name: `Protection Vonner militaire`,
    value: 4950,
    weight: 1,
  },
  {
    armor: {
      head: 80,
      armRight: 80,
      armLeft: 80,
      chest: 80,
      legRight: 80,
      legLeft: 80,
    },
    description: `Un bouclier énergétique qui se déclenche lors de mouvements brusques et protège des attaques
    Il s'agit du modèle "tout public", mais on sait que seuls les plus riches pouvaient se le payer !
    Nécessite de l'énergie (consomme 40 PE par point de dégât bloqué)`,
    name: `Protection Vonner personnelle`,
    value: 2450,
    weight: 1,
  },
  {
    name: `Veste en cuir`,
    armor: {
      armRight: 5,
      armLeft: 5,
    },
    weight: 2,
    value: 16,
    effects: `Fragile`,
  },
  {
    armor: {
      legRight: 5,
      legLeft: 5,
    },
    effects: `Fragile`,
    name: `Jambières en cuir`,
    value: 9,
    weight: 2,
  },
];

export default data.map((i) => ({
  ...i,
  element: Element.ITEM,
  subtype: Subtype.Armor.PROTECTION,
  type: Type.Item.ARMOR,
}));
