import React from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import styled from "@emotion/styled";

import { DataCard } from 'components/afterworld/data/card/DataCard';
import { CardPin } from 'components/afterworld/data/card/CardPin';
import { Label } from 'components/elements';

const Effect = styled.div`
  margin: 4px 0;
  padding: 4px 0;
  &:first-of-type {
    margin-top: 0;
    padding-top: 0;
  }
  &:last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  &:not(:first-of-type) {
    border-top: dotted 1px lightgray;
  }
`;

export function Spell({
  additionalEffects = [],
  area = '',
  cost = 0,
  damage = '',
  description,
  high = '',
  name,
  range = '',
  requirements = [],
  schools,
  summon = [],
  types,
}) {
  const conditionalMappingCallback = (structure) => (structure.if ? structure.text : '');

  const typesComponents = types.map((e, index) => (
    <React.Fragment key={index}>
      {!!index && (' · ')}
      <Label color="lightgray">{e}</Label>
    </React.Fragment>)
  );

  const requirementsComponents = requirements.map((e, index) => (
    <React.Fragment key={index}>
      {!!index && (' · ')}
      <Label color="lightgray" sx={{ mb: 1 }}>{e}</Label>
    </React.Fragment>)
  );

  const body = [
    { text: (<center>{typesComponents}</center>), if: types.length },
    { text: (<>{description}</>), if: description },
    {
      text: (<>
        <center>
          <Box sx={{ fontWeight: 'bold', mb: 1 }}>Pré-requis</Box>
          {requirementsComponents}
        </center>
      </>),
      if: requirements?.length,
    },
  ].map(conditionalMappingCallback).filter((_) => _).map((e, i) => (<Effect key={i}>{e}</Effect>));

  const footer = [
    ...additionalEffects.map((e) => ({ text: e, if: additionalEffects?.length })),
    {
      text: (
        <>
          {summon?.map((e, i) => (<div key={i}>{e}</div>))}
        </>),
      if: summon?.length,
    },
    { text: `Hauteur : ${high}`, if: high },
    { text: `Zone : ${area}`, if: area },
    { text: `Portée : ${range}`, if: range },
    { text: `Dégâts : ${damage}`, if: damage },
  ].map(conditionalMappingCallback).filter((_) => _).map((e, i) => (<Effect key={i}>{e}</Effect>));

  return (
    <DataCard
      bottom={schools.map((school) => (
        <CardPin key={school.name} >
          {school.name}
        </CardPin >
      ))}
      content={body}
      description={footer.length ? footer : undefined}
      title={name}
      pinRight={{
        tooltip: 'Coût en Mental',
        value: cost,
      }}
    />
  );
}

Spell.propTypes = {
  additionalEffects: PropTypes.arrayOf(PropTypes.string),
  area: PropTypes.string,
  cost: PropTypes.number,
  damage: PropTypes.string,
  description: PropTypes.string.isRequired,
  high: PropTypes.string,
  name: PropTypes.string.isRequired,
  range: PropTypes.string,
  requirements: PropTypes.arrayOf(PropTypes.string),
  schools: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    level: PropTypes.string,
  })).isRequired,
  summon: PropTypes.arrayOf(PropTypes.string),
  types: PropTypes.arrayOf(PropTypes.string).isRequired,
};
