const knowledge = [
  {
    description: `Le personnage sait quels métaux sont idéaux pour faire des alliages`,
    effect: [],
    name: `Connaître les alliages`,
    related: CHARACTER.SKILLS.KNOWLEDGE.CULTURE,
  },
  {
    description: `Le personnage sait écrire la langue commune`,
    effect: [],
    name: `Écrire`,
    related: CHARACTER.SKILLS.MASTERY.INTELLECT,
    requirements: [`Lire le courant`],
  },
  {
    description: `Le personnage sait écrire la langue du passé`,
    effect: [],
    name: `Écrire l'ancien`,
    related: CHARACTER.SKILLS.MASTERY.INTELLECT,
    requirements: [
      `Écrire le courant`,
      `Lire l'ancien`,
    ],
  },
  {
    description: `Le personnage connaît les principes avancés de la physique et sait comment réparer des armes à énergie ou fabriquer des accélérateurs à particules`,
    effect: [],
    name: `Mécanique quantique`,
    related: CHARACTER.SKILLS.KNOWLEDGE.ENGINEERING,
    requirements: [
      `Mécanique`,
    ],
  },
];

export default knowledge;
