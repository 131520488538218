import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { DataCard } from 'components/afterworld/data/card/DataCard';
import { Image } from 'components/semantic';
import { Label } from 'components/elements';

export function Monster({
  name,
  types,
}) {
  try {
    const base64name = btoa(name);

    return (
      <Link to={`/bestiary/${base64name}`}>
        <DataCard
          bottom={types.map((type) => (<Label key={type} color="darkgreen">{type}</Label>))}
          title={name}
          description={<Image size="large" src={`/images/bestiary/128/${name}.webp`}></Image>}
        />
      </Link>
    );
  } catch (error) {
    return (
      <DataCard
        bottom={types.map((type) => (<Label key={type} color="darkgreen">{type}</Label>))}
        title={name}
        description={<p>Error: Unable to call window.btoa on monster name "{name}".</p>}
      />
    );
  }

}

Monster.propTypes = {
  name: PropTypes.string.isRequired,
  types: PropTypes.arrayOf(PropTypes.string).isRequired,
};
