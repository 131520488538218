import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

import './ArmorData.scss';

import { ArmorDataArea } from 'components/afterworld/data/ArmorDataArea';

export function ArmorData({
  armor = {},
  border = false,
}) {
  if (!Object.keys(armor).length) return null;

  const {
    armLeft,
    armRight,
    chest,
    head,
    legLeft,
    legRight,
  } = armor;

  return (
    <Box className={`armor-data-box ${border ? 'bordered' : ''}`}>
      <Box className="armor-data">
        <ArmorDataArea value={head}>Tête</ArmorDataArea>
      </Box>

      <Box className="armor-data">
        <ArmorDataArea value={armLeft}>BG</ArmorDataArea>
        <ArmorDataArea value={chest}>Torse</ArmorDataArea>
        <ArmorDataArea value={armRight}>BD</ArmorDataArea>
      </Box>

      <Box className="armor-data">
        <ArmorDataArea value={legLeft}>JG</ArmorDataArea>
        <ArmorDataArea value={legRight}>JD</ArmorDataArea>
      </Box>
    </Box>
  );
}

const ArmorDataShapeDto = {
  head: PropTypes.number,
  armRight: PropTypes.number,
  armLeft: PropTypes.number,
  chest: PropTypes.number,
  legRight: PropTypes.number,
  legLeft: PropTypes.number,
};

ArmorData.propTypes = {
  armor: PropTypes.shape(ArmorDataShapeDto),
  border: PropTypes.bool,
};

export const ArmorDataShape = ArmorDataShapeDto;
