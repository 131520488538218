import { Monsters, Spells } from 'data/metadata';

const data = [
  {
    armor: 5,
    damage: [`4 + 1d6`, ``, ``],
    health: 90,
    hit: [80, 0, 0],
    name: `Cervrobot`,
    passives: [Monsters.Skills.Faiblesse("Tête", 0)],
    speed: 2,
  },
  {
    armor: 5,
    damage: [`22 + 3x1d8 + 2xDR`, ``, ``],
    health: 0,
    hit: [85, 45, 0],
    initiative: 3,
    name: `Radiobot`,
    passives: [Monsters.Skills.Virevoltant(40)],
    speed: 1,
  },
  {
    armor: 2,
    damage: [`8 + 3x1x4 + DR`, ``, ``],
    health: 60,
    hit: [70, 0, 0],
    initiative: 5,
    name: `Spiderbot`,
    passives: [Monsters.Skills.MouvementsErratiques(50)],
    speed: 4,
  },
  {
    armor: 8,
    health: 120,
    hit: [50, 80, 60],
    initiative: 7,
    items: [
      `Arme légère à énergie simple`,
      `Armure de tissu ou de cuir complète`
    ],
    name: `Cyborg sauvage`,
    speed: 3,
    skills: [
      { skill: `Sciences`, value: `60 %` },
    ],
  },
  {
    armor: 9,
    attacks: [`30% : paralyse pour 3 tours`],
    damage: [`24 + 4x1d6 + DR`, ``, ``],
    health: 210,
    hit: [85, 55, 0],
    initiative: 4,
    name: `Protectoron`,
    speed: 2,
  },
  {
    abilities: [Monsters.Skills.Alarme],
    armor: 5,
    damage: [`25 + 4x1d6 + DR`, ``, ``],
    health: 85,
    hit: [85, 55, 0],
    name: `Sentinelle`,
    speed: 2,
  },
  {
    abilities: [Monsters.Skills.Feinte],
    armor: 12,
    damage: [`45 + 4x1d8 + 2xDR`, ``, ``],
    health: 80,
    hit: [115, 80, 35],
    initiative: 12,
    name: `Tourelle Gunny III`,
  },
  {
    abilities: [
      Monsters.Skills.Feinte,
      Monsters.Skills.Surcharge,
    ],
    armor: 6,
    attacks: [
      `Transmets une maladie`,
      `(PM) Par balayage`,
    ],
    damage: [`35 + 3x1d8 + DR`, ``, ``],
    health: 110,
    hit: [90, 55, 0],
    items: [`Arme "Minigun"`],
    name: `Hammut 400 Tueur d'armée`,
    speed: 2,
  },
  {
    abilities: [Monsters.Skills.Surcharge],
    armor: 12,
    damage: [`45 + 4x1d8 + 2xDR`, ``, ``],
    health: 160,
    hit: [90, 70, 0],
    initiative: 5,
    items: [
      `Arme "Minigun"`,
      `Arme "Lance-grenades"`,
    ],
    name: `Prototype KT-25 de guerre`,
    speed: `3 (course 12)`,
  },
  {
    abilities: [
      Monsters.Skills.Feinte,
      `Impulsion EM : désactive tout mécanisme incluant de l'électronique dans la zone touchée et inflige 10 + 1d10 aux robots`
    ],
    armor: 10,
    damage: [`40 + 5x1d8`, ``, ``],
    health: 80,
    hit: [90, 70, 0],
    initiative: 8,
    name: `Tourelle Burn 7`,
  },
  {
    abilities: [Monsters.Skills.Alarme],
    armor: 10,
    attacks: [`(PC): applique Brûlure Ⅲ pour 6 tours`],
    damage: [`40 + 3x1d8 + DR`, `30 + 3x1d6 + DR`, ``],
    death: [`Explosion : inflige 40 + 3x1d20 blessures`],
    health: 85,
    hit: [90, 75, 0],
    initiative: 4,
    name: `M. Gunny`,
    speed: 2,
  },
  {
    abilities: [
      `Cisaille : une attaque précise qui inflige moitié moins de dégâts mais ignore la moitié de l'armure`,
      Monsters.Skills.Alarme,
    ],
    armor: 12,
    attacks: [`Applique Saignements Ⅳ permanent`],
    damage: [`24 + 8x1d4 + 3xDR`, ``, ``],
    health: 65,
    hit: [110],
    initiative: 8,
    name: `M. Saw`,
    speed: 3,
  },
  {
    armor: 10,
    damage: [`selon arme`, ``, ``],
    death: [`Explosion: provoque une auto-destruction qui inflige 12 + 1d10 blessures`],
    health: 40,
    hit: [85, 55, 0],
    initiative: 2,
    items: [`Arme de corps à corps ou légère, simple ou exotique`],
    name: `Exo-squelette Y-17`,
    speed: 3,
  },
  {
    actions: `+1`,
    armor: 15,
    damage: [`selon arme`, ``, ``],
    health: 140,
    hit: [110, 90, 0],
    initiative: 18,
    items: [
      `Arme de corps-à-corps exotique`,
      `Armure en cuir renforcé complète`,
      `Armure assistée exotique`
    ],
    name: `Gamme YT-0 Assassin`,
    passives: [
      Monsters.Skills.DoubleEsquive,
      `Analyse : reconnaît le membre du groupe à attaquer en premier et quelle partie du corps viser`,
      `Trajectoires assistées : capable de prévisualiser les trajectoires des armes à distance et de les esquiver`,
    ],
    skills: [
      { skill: `Maîtrise`, value: `90 %` }
    ],
    speed: 4,
  },
  {
    actions: `+1`,
    armor: 15,
    damage: [`31 + 6x1d6 + DR`, ``, ``],
    health: 0,
    hit: [120, 0, 0],
    initiative: 12,
    name: `Gamme YT-0 Bourreau`,
    passives: [
      `Aura intimidante : les personnes proches subissent l'état "Fasciné" à chaque tour`,
      `Attaques horrifiques : les personnes qui témoignent des dégâts d'une attaque gagnent un point de psychose`,
    ],
    speed: 4,
  },
  {
    abilities: [Monsters.Skills.Charge(`2`, `4`)],
    armor: 18,
    damage: [`45 + 4x1d8 + 2xDR`, ``, ``],
    health: 210,
    hit: [120, 0, 0],
    name: `Gamme YT-0 Biggy Piggy`,
    passives: [`Attaques horrifiques : les personnes qui témoignent des dégâts d'une attaque gagnent un point de psychose`],
    show: [`60% : effraie les personnes proches`],
    speed: 3,
  },
  {
    actions: `+1`,
    armor: 14,
    damage: [`55 + 3x1d10 + DR (mains nues)`, `9 + DR + 1d6 (mitraillette)`, `45 + 10x1d4 + 4xDR (sniper)`],
    health: 340,
    hit: [80, 55, 90],
    initiative: 8,
    name: `Gamme YT-0 Assemblage T-26`,
    speed: 4,
  },
  {
    abilities: [
      `« Missiles chercheurs » : lancés depuis les bras, ces missiles suivent les cibles jusqu'à impact et infligent 60 + 4x1d20`,
      `« Arc électrique » : projeté depuis le bas du dos, électrocute les cibles touchées ce qui les étourdit 2 tours et inflige 40 + 5x1d10, les dégâts sur les robots sont quintuplés`,
      `« Rayon layer » : lance un rayon depuis les yeux qui inflige 150 dégâts`,
    ],
    actions: `+3`,
    armor: 60,
    damage: [`65 + 4x1d8 + DR`, ``, ``],
    health: 1700,
    hit: [120, 0, 0],
    initiative: 8,
    name: `Hope`,
    passives: [
      `« Variabilité » : Hope ne peut pas faire plusieurs fois la même action dans un tour`,
      `« Blindage en galaxium ensorcelé » : insensible à la magie et aux ondes EM, détruit les champs électromagnétiques traversés`,
      `« Détection du mouvement » : impossible de se déplacer sans être repéré`,
      `« Détection de chaleur » : impossible de se rendre invisible pour ne pas être détecté`,
    ],
    speed: 6,
  },
  {
    abilities: [
      `« Camouflage » : active un camouflage qui réfléchit le décor et le rend imperceptible`,
      `« Afflux d'énergie » : augmente l'initiative de 8 et les actions de 2 pendant 4 tours mais incapable d'agir pendant 2 tours ensuite`,
      `« Morsure » : une attaque qui ignore l'armure mais dont les chances de toucher sont réduites de moitié`,
      `« Balayage » : une attaque circulaire qui renverse au sol toutes les cibles touchées`,
    ],
    actions: `+2`,
    armor: 30,
    damage: [`90 + 5x1d10 + 3xDR`, ``, ``],
    health: 1400,
    hit: [140, 0, 0],
    initiative: 8,
    name: `Despair`,
    passives: [
      `« Blindage renforcé » : insensible aux ondes EM`,
      `« Mouvements contrôlés » : capable de se déplacer sans bruit`,
      Monsters.Skills.Piétinement,
    ],
    speed: 8,
  },
  {
    abilities: [
      `« Renforcement » : augmente l'armure d'une partie du corps de 50 pendant 1 tour`,
      `« Allonge » : allonge un bras ce qui permet de porter un coup d'estoc en portée moyenne dont les dégâts ignorent la moitié de l'armure`,
    ],
    actions: `+1`,
    armor: 20,
    attacks: [`Applique Saignements Ⅳ permanent`],
    damage: [`50 + 8x1d6 + 2xDR`, ``, ``],
    health: 220,
    hit: [130, 0, 0],
    initiative: 12,
    name: `Svellacyr`,
    passives: [
      `« Perception indéfectible » : capable de voir de dos, dans la nuit, de repérer les mouvements et la chaleur, entends toutes les fréquences, incapable d'être pris par surprise`,
      `« Analyse » : sait quel personne d'un groupe attaquer en priorité ou quelle partie du corps cibler sur une personne`,
      `« Trajectoires assistées » : capable de prévisualiser les trajectoires des armes à distance et de les esquiver`,
    ],
    speed: 5,
  },
  {
    abilities: [],
    actions: `+1`,
    armor: 8,
    health: 125,
    initiative: 4,
    magic: [
      { school: Spells.SCHOOL.AWAKENING, level: `120 %` },
      { school: Spells.SCHOOL.DAWN, level: `120 %` },
      { school: Spells.SCHOOL.DUSK, level: `120 %` },
      { school: Spells.SCHOOL.FORGE, level: `120 %` },
      { school: Spells.SCHOOL.TEMPEST, level: `120 %` },
    ],
    name: `Kaell Thon Rivallar`,
    passives: [
      `« Capteur de magie » : capable de détecter qui lance quel sort sur plusieurs kilomètres carrés aux alentours`,
      `« Danse rituelle » : capable de lancer des sorts sans magie ni composant ou relique`,
      `« Sonorités mystiques » : capable de lancer un sort instantanément par tour`,
      `« Runes réactives » : le moindre lancement de sort dans les environs déclenche un sort gratuit et instantané`
    ],
    speed: 3,
  },
];

export default data.map((i) => ({
  ...i,
  types: [Monsters.Type.ROBOT],
}));
