import Skills from 'components/afterworld/Skills';
import { List, Subtitle, Table, Text, Title } from 'components/semantic';
import { PageHeading } from 'components/elements';

export function ManualMeleePage() {
  return (
    <>
      <PageHeading>
        En melee
      </PageHeading>

      <Title>Attaques spéciales</Title>

      <Subtitle>En puissance</Subtitle>

      <Text>
        {`L'attaque en puissance est un coup au corps-à-corps qui consiste à mettre tout son poids et sa force au prix de sa souplesse et de son équilibre.`}
      </Text>

      <Text>
        {`Les dégâts de l'attaque seront augmentés du degré de réussite, mais il sera impossible de faire une esquive ou une parade jusqu'à la prochaine action.`}
      </Text>

      <Subtitle>En souplesse</Subtitle>

      <Text>
        {`L'attaque en souplesse est également un coup au corps-à-corps mais qui consiste cette fois à mesurer son attaque au profit de la souplesse et de l'équilibre.`}
      </Text>

      <Text>
        {`Lors de ce genre d'attaque, les dégâts sont réduit : il ne faut pas prendre en compte le degré de réussite de l'arme dans les dégâts. Faire ainsi ne représente toutefois qu'une action rapide, il devient donc possible d'effectuer une autre action rapide dans le tour, comme une parade ou une autre attaque en souplesse.`}
      </Text>

      <Subtitle>Circulaire ou tournoyante</Subtitle>

      <Text>
        {`Lorsque du combat avec une arme de mêlée qui dispose d'une allonge suffisante, il est possible d'effectuer des attaques qui touchent plusieurs personnes.`}
      </Text>

      <Text>
        {`L'attaque circulaire consiste à effectuer un balayage en avant afin de toucher le maximum de personnes dans un angle de 180°.`}
      </Text>

      <Text>
        {`L'attaque tournoyante quant-à-elle consiste à effectuer un tour complet sur soit pour toucher toutes les personnes proches en mêlée.`}
      </Text>

      <Text>
        {`Chacune de ces attaques implique des malus sur la précision, et des malus de dégâts peuvent également être appliqués au-delà de la première cible touchée si l'arme est contondante. Ce genre d'attaque n'est pas réalisable à mains nues ou avec des armes de poing.`}
      </Text>

      <Table data={[[`Circulaire`, `-15%`], [`Tournoyante`, `-30%`]]}></Table>

      <Title>Attaques situationnelles</Title>

      <Subtitle>Attaques d'opportunite</Subtitle>

      <Text>
        {`Lorsqu'une cible au contact sur qui le personnage a un visuel se déplace sans lui faire face en permanence, il est possible de faire une attaque d'opportunité. `}
        Il faut réussir un test d'<Skills.Mastery.Agility />. pour pouvoir attaquer avec une action rapide, s'il reste des actions au personnage.
        Il faut être en pleine possession de ses moyens pour effectuer ce genre d'attaque, ne pas être au sol par exemple.
      </Text>

      <Subtitle>Attaque furtive</Subtitle>

      <Text>
        {`Une attaque est automatiquement furtive si la cible ne s'y attend pas. Cela peut se produire si elle est endormie ou alors si le personnage se trouve dans son dos sans qu'elle ne le sache.`}
      </Text>

      <List data={['Toucher +50 %', 'Chances de succès critique +50 %', 'Impossible à esquiver ou à parer']}></List>

      <Title>Combat rapproché</Title>

      <Subtitle>Bousculade</Subtitle>

      <Text>
        Lorsque du contact, il est possible d'effectuer une bousculade. Cette action particulière se fera sous un test de <Skills.Mastery.Strength /> et des malus selon la taille et le poids de la cible pourront être appliqués. Sur une réussite, la personne bousculée se retrouvera au sol.
      </Text>

      <Text>
        Attention toutefois car cette dernière peut faire une action de contre sur sa compétence d'<Skills.Mastery.Agility />.
      </Text>

      <Subtitle>Charge</Subtitle>

      <Text>
        {`Cette attaque particulière permet de charger un ennemi et le frapper. Pour faire ainsi, il ne faut aucun obstacle sur le chemin de la charge et la cible doit impérativement se situer entre 3 mètres et la  vitesse de course du personnage, en mètres. Pour un humain, il doit donc être entre 3 et 8 mètres du personnage.`}
      </Text>

      <Text>
        {`Effectuer une telle action augmente les dégâts de l'attaque du degré de réussite de l'arme.`}
      </Text>

      <Subtitle>Croc-en-jambes</Subtitle>

      <Text>
        Lors d'une attaque d'opportunité, il est possible de faire un croc-en-jambes plutôt que d'attaquer. L'action nécessite uniquement un test de <Skills.Weapons.Melee />, et si elle réussit, permet de faire renverser la cible au sol.
      </Text>

      <Text>
        Cette dernière peut avoir une action de contre sur sa compétence d'<Skills.Mastery.Agility />.
      </Text>

      <Subtitle>Désarmement</Subtitle>

      <Text>
        Au lieu d'attaquer, il est possible de désarmer quelqu'un. L'action se fait avec un test de <Skills.Weapons.Melee /> et sur un succès, cela permet de faire tomber l'arme de la personne au sol ou de s'en saisir.
      </Text>

      <Text>
        La personne peut toutefois effectuer une action de contre sous sa compétence de <Skills.Mastery.Agility /> pour éviter le désarmement.
      </Text>

      <Subtitle>Feinte</Subtitle>

      <Text>
        À l'aide d'un test en <Skills.Social.Empathy />, il est possible de faire une feinte. Si cela fonctionne, l'attaque lors de la prochaine action bénéficiera d'un bonus de toucher de 50 %. Cela ne
      </Text>

      <Text>
        La cible peut toutefois tenter une action de contre avec sa compétence en <Skills.Defenses.Psychology /> pour détecter la feinte.
      </Text>

      <Subtitle>Lutte</Subtitle>

      <Text>
        Lors d'une prise en mêlée avec une personne, il est possible de faire une action dite de Lutte. Faire ainsi se passe avec un test de <Skills.Mastery.Strength /> et permet de prendre l'ascendant sur l'adversaire. Sur un succès, et tant que la position de lutte est maintenue, la personne est incapable d'agir.
      </Text>

      <Text>
        Celle-ci peut effectuer une action de contre sous sa compétence de <Skills.Mastery.Strength /> pour ne pas se laisser prendre l'ascendant lors de la lutte.
      </Text>

      <Text>
        Une personne immobilisée peut aussi faire un test de <Skills.Mastery.Strength /> tous les tours afin d'essayer de se libérer.
      </Text>

      <Subtitle>Prise</Subtitle>

      <Text>
        Au contact avec une personne, il est possible de la saisir et d'effectuer une prise sur elle, afin de bloquer un de ses membres. Faire ainsi nécessite un test de <Skills.Weapons.Melee /> et sur un succès, empêche la personne d'utiliser le membre du corps visé.
      </Text>

      <Text>
        Attention toutefois, cette dernière peut faire une action de contre sous sa compétence de <Skills.Mastery.Agility />.
      </Text>

      <Text>
        Lorsqu'une prise est déjà effective, il est possible de la forcer afin de briser le membre du corps pris. Il faut réussir un test de <Skills.Mastery.Strength /> pour cela.
      </Text>
    </>
  );
}
