import { Element, Type, Subtype } from 'data/metadata';

const data = [
  {
    description: `Des bottes souples qui s'accrochent n'importe où`,
    effects: `Permet d'escalader des surfaces planes`,
    name: `Bottes Stikwall`,
    value: 450,
    weight: 4,
  },
  {
    description: `Peut être activé ou désactivé`,
    effects: `Alimente en électricité dans un rayon de 2 mètres à raison de 40 PE par heure de manière illimitée`,
    name: `Collier en Asmarite`,
    value: 1250,
    weight: 0.2,
  },
  {
    effects: `Permet d'attaquer à Mains nues à distance`,
    name: `Gants à transfert d'énergie`,
    value: 750,
    weight: 0.5,
  },
  {
    effects: `Chances de réussite des sorts +30% s'ils sont incantés avec une gestuelle`,
    name: `Gants de dessinateur du chaos`,
    value: 600,
    weight: 0.5,
  },
  {
    description: `Permet d'escalader des surfaces planes`,
    effects: `Des gants souples qui s'accrochent n'importe où`,
    name: `Gants Stikwall`,
    value: 550,
    weight: 0.1,
  },
  {
    effects: `Permet de respirer sous l'eau
    Un petit bijou de technologique qui se porte en mordant la partie dédiée
    Objet d'avant-guerre et très rare`,
    name: `Masque de plongée Tek 3`,
    value: 432,
    weight: 0.5,
  },
  {
    effects: `Une fois alimenté, permet de voler à basse altitude avec des tests d'Agilité
    Consomme 500 PE par heure
    L'objet est très encombrant`,
    name: `Mini-fusée portable`,
    value: 1250,
    weight: 40,
  },
  {
    effects: `Protège de toute absorption de radiations en dessous de 50 Radiations`,
    name: `Tenue antiradiations`,
    value: 95,
    weight: 16,
  },
];

export default data.map((i) => ({
  ...i,
  element: Element.ITEM,
  subtype: Subtype.Armor.SPECIAL,
  type: Type.Item.ARMOR,
}));

