import { ButtonMenu } from 'components/ButtonMenu';

export function DataMenu() {
  return (
    <>
      <ButtonMenu
        button={{ icon: 'person', text: `Personnage` }}
        menuItems={[
          { icon: 'hands-holding-circle', text: `Aptitudes`, url: '/data/gifts' },
          { icon: 'wand-magic-sparkles', text: `Magie`, url: '/data/spells' },
          // { icon: 'book-open', text: `Savoirs`, url: '/data/knowledge' },
        ]}
      />

      <ButtonMenu
        button={{ icon: 'person-walking-luggage', text: `Inventaire` }}
        menuItems={[
          { icon: 'basket-shopping', text: `Objets`, url: '/data/items' },
          { icon: 'gun', text: `Armes`, url: '/data/weapons' },
          { icon: 'shield-halved', text: `Armures`, url: '/data/armors' },
        ]}
      />
    </>
  );
}
