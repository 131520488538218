import { Monsters, Spells } from 'data/metadata';

const data = [
  {
    abilities: [Monsters.Skills.Envol(50)],
    armor: 0,
    attacks: [
      `80 % : applique Radiations Ⅲ`,
      `60 % : provoque une infection`,
    ],
    damage: [`15 + 3x1d6 + DR`, ``, ``],
    health: 60,
    hit: [75, 0, 0],
    name: `Mut-cafard`,
    passives: [Monsters.Skills.Résistance('Dos', 5)],
    speed: 2,
  },
  {
    attacks: [Monsters.Skills.Poison('Ronge-Os', '2 minutes', 60)],
    damage: [`10 + 2x1d6 + DR`, ``, ``],
    health: 45,
    hit: [65, 0, 0],
    initiative: 2,
    name: `Mut-mante`,
    speed: 2,
  },
  {
    attacks: [
      Monsters.Skills.Poison('Sang-Sève', '3 minutes'),
      `60 % : applique Saignements I pour 3 tours`,
      `15 % : transmets une maladie aléatoire`,
    ],
    damage: [`6 + 2x1d4 + DR`, ``, ``],
    health: 25,
    hit: [80, 0, 0],
    initiative: 4,
    name: `Mut-moustique`,
    passives: [Monsters.Skills.Virevoltant(25)],
    speed: 3,
  },
  {
    abilities: [Monsters.Skills.Enfouissement],
    armor: 10,
    attacks: [
      `40 % : applique Radiations I`,
      Monsters.Skills.Poison('Essouffleur', '1 minutes', 25),
    ],
    damage: [`18 + 2x1d6 + DR`, ``, ``],
    health: 75,
    hit: [65, 0, 0],
    name: `Mut-crabe`,
    passives: [Monsters.Skills.Faiblesse('Tête', 0)],
    speed: 2,
  },
  {
    abilities: [Monsters.Skills.Charge(2, 30)],
    damage: [`10 + 2x1d6 + DR`, ``, ``],
    health: 180,
    hit: [35, 0, 0],
    name: `Mut-vache`,
    speed: 2,
  },
  {
    abilities: [Monsters.Skills.Charge(2, 50)],
    armor: 10,
    damage: [`22 + 3x1d8 + DR`, ``, ``],
    health: 145,
    hit: [79, 0, 0],
    initiative: 2,
    name: `Mut-sanglier`,
    passives: [
      Monsters.Skills.Résistance('Dos', 20),
      Monsters.Skills.Résistance('Tête', 15),
    ],
    speed: 3,
  },
  {
    abilities: [Monsters.Skills.Tunnel],
    armor: 1,
    damage: [`15 + 3x1d6 + DR`, ``, ``],
    health: 85,
    hit: [85, 0, 0],
    initiative: 5,
    name: `Mut-taupe`,
    speed: 4,
  },
  {
    attacks: [
      `40 % : applique Saignements Ⅱ pour 6 tours`,
      `20 % : transmets la Rage`,
    ],
    damage: [`18 + 2x1d8 + DR`, ``, ``],
    health: 65,
    hit: [85, 0, 0],
    initiative: 4,
    name: `Chien sauvage`,
    passives: [Monsters.Skills.Group(`2 à 4 [1 + 1d3]`)],
    speed: 5,
  },
  {
    armor: 4,
    attacks: [Monsters.Skills.Poison('Cendrita', '1 minute', 80)],
    damage: [`14 + 2x1d6 + DR`, ``, ``],
    health: 90,
    hit: [80, 0, 0],
    name: `Mut-fourmi géante`,
    speed: 2,
  },
  {
    armor: 15,
    attacks: [Monsters.Skills.Poison('Ronge-Os', '10 minutes', 80)],
    damage: [`22 + 2x1d6 + DR`, ``, ``],
    health: 70,
    hit: [80, 0, 0],
    initiative: 4,
    name: `Mut-scorpion`,
    passives: [Monsters.Skills.Faiblesse('Tête', 0)],
    speed: 3,
  },
  {
    attacks: [
      Monsters.Skills.Poison('Sang-Sève', '10 minutes'),
      Monsters.Skills.Poison('Essouffleur', '1 minute', 80),
      `60 % : applique Saignements Ⅲ pour 3 tours`,
      `40 % : transmets une maladie aléatoire`,
    ],
    damage: [`8 + 1d6 + DR`, ``, ``],
    health: 30,
    hit: [80, 0, 0],
    initiative: 8,
    name: `Mut-moustique virulent`,
    passives: [
      Monsters.Skills.Virevoltant(50),
      Monsters.Skills.Group(`3 à 6 [2 + 1d4]`),
    ],
    speed: 2,
  },
  {
    attacks: [
      Monsters.Skills.Poison('Sang-Sève', '15 minutes'),
      Monsters.Skills.Poison('Aveuglette', '5 minutes'),
      `80 % : applique Radiations Ⅲ et transmets le Petit Mal`,
      Monsters.Skills.Poison('Essouffleur', '1 minute', 60),
      `30 % : transmets la Putride`,
      `15 % : transmets la Rage`,
    ],
    damage: [`10 + 2x1d6 + DR`, ``, ``],
    health: 35,
    hit: [90, 0, 0],
    initiative: 7,
    name: `Porteur de maladies`,
    passives: [Monsters.Skills.Virevoltant(60)],
    speed: 4,
  },
  {
    attacks: [
      Monsters.Skills.Poison('Ronge-Os', '5 minutes', 80),
      Monsters.Skills.Poison('Étouffeur', '1 minute', 60),
      `40 % : transmets la Putride`,
      `20 % : transmets la Rage`
    ],
    damage: [`8 + 2x1d6 + DR`, ``, ``],
    health: 45,
    hit: [80, 0, 0],
    initiative: 5,
    name: `Infecte rongeur`,
    passives: [Monsters.Skills.Group(`2 à 5 [1 + 1d4]`)],
    speed: 3,
  },
  {
    abilities: [Monsters.Skills.Charge(`3`, `70`)],
    armor: 2,
    damage: [`12 + 2x1d6 + DR`, ``, ``],
    health: 115,
    hit: [70, 0, 0],
    initiative: 2,
    name: `Mut-cerf`,
    passives: [Monsters.Skills.Résistance('Tête', 10)],
    speed: 4,
  },
  {
    abilities: [
      `Jet de soie : 60 % de chances d'aveugler la cible`,
      `Sol gluant : réduit la vitesse de déplacement de la cible de moitié dans la zone affectée`,
    ],
    armor: 2,
    attacks: [Monsters.Skills.Poison('Cendrita', '3 minutes')],
    damage: [`16 + 3x1d6 + DR`, ``, ``],
    health: 70,
    hit: [95, 0, 0],
    initiative: 3,
    name: `Mut-araignée`,
    speed: 4,
  },
  {
    armor: 20,
    attacks: [Monsters.Skills.Poison('Ronge-Os', '20 minutes')],
    damage: [`30 + 3x1d6 + DR`, ``, ``],
    health: 105,
    hit: [95, 0, 0],
    initiative: 6,
    name: `Mut-scorpion géant`,
    passives: [Monsters.Skills.Faiblesse('Tête', 5)],
    speed: 4,
  },
  {
    abilities: [Monsters.Skills.Tunnel],
    armor: 5,
    damage: [`40 + 5x1d4 + 2xDR`, ``, ``],
    health: 160,
    hit: [110, 0, 0],
    initiative: 2,
    name: `Mut-taupe alpha`,
    passives: [
      `Groupe : se trouve généralement avec un groupe de 2 à 7 Mut-taupe [1 + 1d6]`,
      Monsters.Skills.Chef('Mut-taupes', 2),
    ],
    speed: 3,
  },
  {
    armor: 12,
    damage: [`30 + 4x1d8 + 2xDR`, ``, ``],
    health: 115,
    hit: [85, 0, 0],
    name: `Goun tao`,
    show: [
      `50 % de chances d'effrayer les individus proches`,
      `Chances d'effrayer augmentées de 30 % si furtif`
    ],
    speed: 5,
  },
  {
    armor: 1,
    attacks: [
      Monsters.Skills.Poison('Aveuglette', '5 minutes'),
      Monsters.Skills.Poison('Étouffeur', '1 minute'),
    ],
    damage: [`16 + 2x1d8 + DR`, ``, ``],
    health: 70,
    hit: [110, 80, 0],
    name: `Mut-mouche géante`,
    passives: [Monsters.Skills.Virevoltant(40)],
    speed: 3,
  },
  {
    abilities: [Monsters.Skills.Enfouissement],
    armor: 1,
    attacks: [
      `80 % : applique Radiations Ⅲ`,
      `80 % : applique Saignements Ⅲ pour 12 tours`,
    ],
    damage: [`15 + 3x1d6 + DR`, ``, ``],
    health: 150,
    hit: [85, 0, 0],
    initiative: 2,
    name: `Mut-tortue`,
    passives: [Monsters.Skills.Résistance('Dos', 20)],
    speed: 2,
  },
  {
    abilities: [Monsters.Skills.Saut(`5x1d6`)],
    armor: 2,
    attacks: [
      Monsters.Skills.Poison('Essouffleur', '1 minute', 60),
      `60 % : applique Saignements Ⅱ pour 6 tours`,
    ],
    damage: [`24 + 2x1d6 + DR`, ``, ``],
    health: 70,
    hit: [90, 0, 0],
    initiative: 5,
    name: `Traqueur nocturne`,
    passives: [`Nyctalope : voit aussi bien de nuit`],
    speed: 4,
  },
  {
    armor: 2,
    attacks: [
      Monsters.Skills.Poison('Tue-sorcier', '2 minutes'),
      Monsters.Skills.Poison('Étouffeur', '1 minute', 90),
      `70% : transmets la rage`,
    ],
    damage: [`24 + 2x1d6 + DR`, ``, ``],
    health: 135,
    hit: [105, 0, 0],
    initiative: 4,
    name: `Basilisk`,
    passives: [
      Monsters.Skills.CorpsDePoison(`Ⅳ`, 3, `Étouffeur et "Tue-sorcier"`),
      Monsters.Skills.Résistance('Dos', 12),
    ],
    speed: 4,
  },
  {
    armor: 4,
    damage: [`26 + 3x1d6 + DR`, ``, ``],
    health: 105,
    hit: [110, 0, 0],
    initiative: 8,
    name: `Mut-gecko`,
    passives: [Monsters.Skills.Group(`5 à 8 [4 + 1d4]`)],
    speed: 4,
  },
  {
    abilities: [`Traînée empoisonnée : libère du poison qui se répand dans les eaux proches et qui, à chaque fin de tour, a 80 % de chances d'appliquer Poison "Essouffleur" pour 12 tours`],
    attacks: [`Les attaques sont en PM et sont des choc mentaux : étourdit pendant 2 tours`],
    damage: [`16 + 4x1d8 + DR`, ``, ``],
    health: 55,
    hit: [0, 65, 0],
    initiative: 6,
    name: `Mut-poisson`,
    passives: [`Frémissant: réduit les chances d'être touché de 50 %`],
    speed: 5,
  },
  {
    abilities: [Monsters.Skills.Saut(6)],
    armor: 2,
    attacks: [`Applique Saignements Ⅳ permanent`],
    damage: [`18 + 4x1d4 + 2xDR`, ``, ``],
    health: 80,
    hit: [85, 0, 0],
    initiative: 8,
    name: `Molosse`,
    passives: [Monsters.Skills.Group(`3 à 6 [2 + 1d4]`)],
    speed: 4,
  },
  {
    abilities: [Monsters.Skills.Charge(4, 60)],
    armor: 4,
    attacks: [`75 % : renverse la cible au sol`],
    damage: [`24 + 3x1d8 + DR`, ``, ``],
    health: 190,
    hit: [95, 0, 0],
    initiative: 1,
    name: `Bergaul`,
    passives: [Monsters.Skills.Piétinement],
    speed: 5,
  },
  {
    abilities: [Monsters.Skills.HurlementStrident],
    armor: 5,
    attacks: [`Applique Saignements Ⅳ permanent`],
    damage: [`45 + 5x1d6 + DR`, ``, ``],
    health: 280,
    hit: [120, 0, 0],
    name: `Mut-ours`,
    show: [`90% : effraie les personnes proches`],
    speed: 4,
  },
  {
    abilities: [
      Monsters.Skills.HurlementStrident,
      `Cri à la lune : les personnes proches doivent faire un test de Sang froid, elles seront effrayées si elles échouent`
    ],
    armor: 2,
    attacks: [`Applique Saignements V permanent`],
    damage: [`25 + 10x1d4 + 3xDR`, ``, ``],
    health: 210,
    hit: [120, 0, 0],
    initiative: 12,
    name: `Mut-garou`,
    speed: 7,
  },
  {
    abilities: [`Rets : envoie des rets sur une cible et l'immobilise sur sa position`],
    armor: 2,
    attacks: [Monsters.Skills.Poison('Cendrita', '15 minutes'),],
    damage: [`18 + 2x1d6 + DR`, ``, ``],
    health: 140,
    hit: [110, 0, 0],
    initiative: 2,
    name: `Reine mut-araignée`,
    passives: [Monsters.Skills.CorpsDePoison(`V`, 12, `Cendrita et Étouffeur`)],
    show: [`Effraie les individus proches`],
    speed: 5,
  },
  {
    abilities: [`Constriction : immobilise une cible et augmente sa Fatigue de 1 par tour d'étreinte. La cible ne peut plus respirer quand elle est fatiguée`],
    armor: 8,
    attacks: [Monsters.Skills.Poison('Étouffeur', '1 minute')],
    damage: [`35 + 6x1d6 + 2xDR`, ``, ``],
    health: 190,
    hit: [130, 0, 0],
    initiative: 9,
    name: `Mut-serpent`,
    show: [`Effraie les individus proches`],
    speed: 6,
  },
  {
    armor: 8,
    damage: [`60 + 2x1d10 + DR`, ``, ``],
    health: 450,
    hit: [90, 0, 0],
    initiative: 2,
    name: `Mut-crocodile`,
    show: [`Effraie les individus proches`],
    speed: 4,
  },
  {
    abilities: [`Projections d'encre : applique un malus de 80 % sur les tests qui nécessite une visibilité dans la zone`],
    armor: 6,
    damage: [`75 + 5x1d8 + 2xDR`, ``, ``],
    health: 600,
    hit: [80, 0, 0],
    initiative: 6,
    name: `Mut-requin`,
    show: [`Effraie les individus proches`],
    speed: 12,
  },
  {
    abilities: [
      `Jet de bave : réduit l'Initiative de 4 et la vitesse de déplacement de la cible de 33 %, cumulable`,
      `Étreinte de poison : s'enroule autour d'une cible immobile et la serre ce qui lui applique trois occurrences de Poison V pour 6 tours (catégorie D+)`,
      Monsters.Skills.Tunnel,
    ],
    health: 290,
    initiative: 2,
    name: `Ver de vase`,
    passives: [Monsters.Skills.CorpsDePoison(`V`, 6, `Sang-Sève, Ronge-Os et Étouffeur`)],
    speed: `2 (6 en souterrain)`,
  },
  {
    abilities: [
      `Gober : avale une proie directement sans la mastiquer`,
      `Sucs gastriques : quiconque avalé toujours en vie subit Poison V à chaque tour`,
      `Les actions suivantes sont libres, mais une seule fois par tour :`,
      `• « Tremblement » : renverse au sol les personnes proches et les désarme`,
      `• « Hurlement d'outre-monde » : interrompt les incantations des personnes proches et empêche de sentir la magie pendant 2 tours`,
      `• « Regard vorace » : effraie les personnes en face pendant 2 tours`,
    ],
    attacks: [`Propulse la cible de 4 mètres, renverse au sol et désarme`],
    armor: 15,
    damage: [`150 + 15x1d10`, ``, ``],
    health: 2800,
    hit: [120, 0, 0],
    initiative: 5,
    name: `Mut-Grizzly`,
    passives: [
      Monsters.Skills.Résistance('Dos', 30),
      Monsters.Skills.Piétinement,
    ],
    show: [`Effraie les individus proches`],
    speed: 20,
  },
  {
    abilities: [
      `« Concertation » : une attaque collective qui utilise une action de chaque tête, les dégâts sont ceux de l'attaque normale multipliés par le nombre de têtes qui attaquent`,
      `« Crachat purulent » : jet de poison à distance qui applique le poison Étouffeur pendant 2 minutes`,
    ],
    actions: `+2`,
    attacks: [
      `Applique Radiations Ⅲ`,
      Monsters.Skills.Poison('Étouffeur', '2 minutes'),
    ],
    armor: 10,
    damage: [`70 + 8x1d8 + 2xDR`, `70 + 8x1d8 + 2xDR`, ``],
    health: 2100,
    hit: [105, 85, 0],
    initiative: 10,
    name: `Hydre`,
    passives: [
      `« Multiples têtes » : dispose de 3 têtes, chacune avec 700 points de vie et 1 action - lorsqu'une tête est vaincue, l'hydre perd une action`,
      `« Régénération radioactive » : chaque tête récupère toute sa santé à la fin de chaque tour - si plus de 200 points de vie ont été récupérés sur une tête de cette manière, elle se dédouble et l'hydre gagne 700 points de vie et 1 action`,
      `« Parade presciente » : chaque tête est capable de parer gratuitement et contre toutes les armes et ce jusqu'à une fois par tour`,
      `« Focalisation » : lorsqu'une cible est attaquée par l'hydre, toutes les têtes attaquent cette cible si possible`,
    ],
    show: [`Effraie les individus proches`],
    speed: 5,
  },
  {
    abilities: [
      `« Enchevêtrement » (coûte 3 actions) : enferme la cible sur le nombre ce qui l'empêche d'agir et double les dégâts qu'elle va subir`,
      `« Barrage » (coûte 5 actions): une formation en barrage qui inflige 150 + 1d100 dégâts`,
    ],
    actions: `+9`,
    attacks: [
      Monsters.Skills.Poison('Aveuglette', '2 minutes'),
      Monsters.Skills.Poison('Étouffeur', '1 minute'),
      `Applique Radiations Ⅲ`,
    ],
    damage: [`20 + 5x1d4`, ``, ``],
    health: 1000,
    hit: [90, 0, 0],
    initiative: 20,
    name: `Essaim de Camols`,
    passives: [
      Monsters.Skills.Virevoltant(50),
      `« Cohésion du groupe » : chaque tranche de 50 blessures réduit l'Initiative de 1`,
      `« Surnombre du groupe » : chaque tranche de 100 blessures réduit les Actions de 1`,
    ],
    speed: 20,
  },
  {
    abilities: [
      `« Bourrasque » : une rafale de vent projetée avec les ailes qui renverse les cibles au sol`,
      `« Coup de queue » : frappe allongée en mêlée qui propulse la cible au loin et la renverse au sol`,
      `« Morsure » : une attaque de mêlée qui ignore les armures`,
      `« Souffle de flammes » : crache un souffle de flammes dont la chaleur est comparable à de la lave en fusion, inflige Brûlure V pendant 1 minute`,
    ],
    actions: `+2`,
    armor: 15,
    attacks: [
      `Applique Saignements V permanent`,
    ],
    damage: [`45 + 10x1d6`, `45 + 10x1d6`, ``],
    health: 1200,
    hit: [90, 70, 0],
    initiative: 15,
    magic: [
      { school: Spells.SCHOOL.AWAKENING, level: `150 %` },
      { school: Spells.SCHOOL.FORGE, level: `150 %` },
    ],
    mana: 50,
    name: `Drake`,
    passives: [`« Magie antique » : incantations instantanées et coûte 1 de magie`],
    speed: 6,
  },
  {
    abilities: [
      `« Hors phase » : capable de disparaître et d'apparaître - lors de la disparition, la bête n'est plus dans la même réalité physique et ne peut plus être attaquée ou attaquer`,
      `« Griffe de déphasage » : une attaque normale mais qui fait disparaître la partie du corps touchée pendant 3 tours`,
      `« Déphasage » : une prise par morsure, qui si elle est maintenue un tour complet, fait passer la cible et la bête hors phase ensemble`,
      `« Coup de queue » : attaque de balayage à 80% de chances de toucher et qui renverse les cibles touchées au sol et leur applique Poison Étouffeur pour 1 minute`,
    ],
    actions: `+2`,
    armor: 5,
    attacks: [
      `Applique Radiations Ⅲ`,
    ],
    damage: [`45 + 10x1d6`, `45 + 10x1d6`, ``],
    health: 180,
    hit: [110, 0, 0],
    initiative: 12,
    name: `Bête de l'éclipse`,
    speed: 12,
  },
];

export default data.map((i) => ({
  ...i,
  types: [Monsters.Type.BEAST],
}));
