const aptitudes = [
  {
    effect: [
      `Une fois par tour, se relever est une action libre`,
      `Ignore les dégâts de chute si la hauteur est inférieure à 3 mètres`,
    ],
    name: `Acrobate`,
  },
  {
    effect: [
      `Les malus liés à la météo ou à la luminosité de 30 ou moins sont désormais ignorés`,
      `L'action de combat "Analyser la situation" est désormais une action gratuite (une seule action gratuite est possible par tour)`,
    ],
    name: `Adaptabilité`,
  },
  {
    effect: [
      `Le personnage n'est plus affecté par les malus liés à l'alcool`,
      `Tant que le personnage est alcoolisé, augmente les dégâts d'un DR supplémentaire`,
    ],
    name: `Alcoolique`,
  },
  {
    effect: [`Il n'y a plus de malus lors de l'utilisation de la main inhabituelle`],
    name: `Ambidextrie`,
  },
  {
    effect: [
      `Nombre de tours sans oxygène +3`,
      `Nombre de tours d'efforts physiques sans jet de dé +3`,
      `Augmente la vitesse de course et de nage de 50%`,
    ],
    name: `Athlète`,
  },
  {
    effect: [`Augmente le prix des objets vendus de 75% environ`],
    name: `Commercial`,
    requirements: [`Aptitude 'Marchand'`],
  },
  {
    effect: [
      `Ne déclenche plus les mines et pièges au sol`,
      `Double la vitesse de déplacement en discrétion`,
    ],
    name: `Course féline`,
  },
  {
    effect: [
      `Lors de l'incantation d'un sort, si le lanceur du sort est à moins de 10 mètres, vous savez immédiatement qui le lance`,
      `Lors d'une réussite de perception d'un sort, vous permet d'identifier quel est le sort qui est lancé`,
    ],
    name: `Culture de la magie`,
  },
  {
    effect: [`Les gains de radiations soignent les dégâts du double du montant de Radiations`],
    name: `Éponge à radiations`,
  },
  {
    effect: [
      `Lors des combats, lors de la phase de résolution, il est possible de changer une action rapide par une autre action rapide pour 1 point de Fatigue`,
    ],
    name: `Indécis`,
  },
  {
    effect: [
      `Donne un demi-point d'expérience aux compétences et aux sorts qui ont déjà un demi-point d'expérience qui n'est pas complété`,
      `Double l'expérience des succès critique pour les compétences et la magie`,
      `Les gains d'expérience de compétence peuvent désormais être dépensés dans une compétence au choix`,
      `Les gains d'expérience de sort peuvent désormais être dépensés dans un sort au choix`,
    ],
    name: `Introspection`,
  },
  {
    effect: [
      `Les sorts ne nécessitent plus de composante gestuelle ou verbale`,
      `Il n'est plus possible d'être distrait lors de l'incantation d'un sort`,
    ],
    name: `Magicien attentif`,
  },
  {
    effect: [
      `Le premier tour de maintien de sort ne coûte pas de Mental`,
      `Double le Mental récupéré par l'action de Céder à la folie`,
    ],
    name: `Magicien autodidacte`,
  },
  {
    effect: [
      `Réduit le coût en Mental pour canaliser un sort de 1 par tour de canalisation`,
      `Annule la pénalité d'incantation lorsqu'une canalisation est en cours`,
    ],
    name: `Magicien endurant`,
  },
  {
    effect: [
      `Les effets du manque ont augmente toutes les 48 heures au lieu de toutes les 24 heures`,
      `Augmente le seuil d'overdose et de décès de +3`,
    ],
    name: `Nature addict`,
  },
  {
    effect: [`Lors des succès ou échecs critiques des membres du groupe, le personnage gagne des points d'expérience dans la même compétence ou le même sort comme s'il avait fait le test lui même`],
    name: `Psychanalyste`,
  },
  {
    effect: [`Augmente les dégâts de l'attaque en puissance d'un DR supplémentaire`],
    name: `Puissance`,
  },
  {
    effect: [`Lors de la phase de préparation en combat, il est possible de regarder l'action d'un autre participant avant de noter la sienne`],
    name: `Mentaliste`,
  },
  {
    effect: [`Les succès critiques avec un dé à 01 rapportent un point de chance`],
    name: `Regain de chance`,
  },
  {
    effect: [`Réduit les chances de perdre un membre du corps de 20%`],
    name: `Squelette résistant`,
  },
]

export default aptitudes.map((a) => ({ ...a, level: 5 }));
