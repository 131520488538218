import { Element, Type, Subtype } from 'data/metadata';

const data = [
  {
    effect: `Nourri
    Radiations +5`,
    name: `Barre céréalée`,
    note: `Le compagnon idéal du voyageur`,
    value: 4,
    weight: 0.05,
  },
  {
    effect: `Nourri et déshydrate
    Radiations +20`,
    name: `Biscuit aux herbes`,
    note: `Une recette connue des terres dévastées et un bon remontant en cas de nécessité`,
    value: 2,
    weight: 0.02,
  },
  {
    effect: `Nourri
    Chances d'être malade
    Radiations +10`,
    name: `Chair d'insecte`,
    note: `Du cafard, miam!`,
    value: 1,
    weight: 0.01,
  },
  {
    effect: `Nourri fortement
    Chances d'être malade
    Chances de gagner un point de Psychose`,
    name: `Chair humanoïde`,
    note: `Il est extrèmement mal vu de manger ses pairs
    Ne peut plus rendre malade une fois la viande cuite`,
    value: 0,
    weight: 1,
  },
  {
    effect: `Chances de réussite magique +20% après 15 minutes et ce pendant plusieurs heures
    Chances d'avoir des hallucinations
    Chances de gagner des points de Psychose
    Radiations +40`,
    name: `Champignon`,
    note: `Ces champignons sont plutôt méconnus et rarement utilisés
    Une fois cuisinés avec succès, ce qui s'avère difficile, ils ne peuvent plus provoquer d'hallucinations ou donner des points de Psychose
    Mal cuisinés, ils peuvent provoquer des arrêts cardiaques`,
    value: 85,
    weight: 0.1,
  },
  {
    effect: `Nourri
    Radiations +10`,
    name: `Conserve cuisinée`,
    note: `Du lapin aux épices, du cassoulet, du porc aux haricots ou une ratatouille, ...`,
    value: 12,
    weight: 0.5,
  },
  {
    effect: `Nourri
    Radiations +15`,
    name: `Conserve de fruits ou de légumes`,
    note: `Des abricots, des pêches, des poires, des carottes, des pommes de terre, des lentilles, des tomates, ...`,
    value: 26,
    weight: 0.5,
  },
  {
    effect: `Nourri fortement
    Radiations +20
    Dépendance 80%`,
    name: `Fruit mutant`,
    note: `De la taille d'une pastèque, de couleur orangée, verdâtre ou très rarement bleutée
    Il est reconnu que ce fruit possède des propriétés addictives`,
    value: 8,
    weight: 4,
  },
  {
    effect: `Nourri légèrement
    Désaltère
    Radiations +5`,
    name: `Paquet de chips`,
    note: `Chips de pomme de terre, de légumes ou de patate douce ...`,
    value: 4,
    weight: 0.2,
  },
  {
    effect: `Nourri
    Radiations +5`,
    name: `Paquet de féculents`,
    note: `Paquet de pâtes, de nouilles, de purée, de blé ou de riz ...
    Doit être cuisiné`,
    value: 5,
    weight: 0.5,
  },
  {
    effect: `Nourri
    Radiations +25`,
    name: `Pot avec saumure`,
    note: `Peut contenir des cornichons ou autres légumes
    Utile pour la cuisine`,
    value: 32,
    weight: 0.5,
  },
  {
    effect: ``,
    name: `Pot d'épice`,
    note: `Du sel, du poivre, du thym, du curry ...
    Utile pour la cuisine`,
    value: 45,
    weight: 0.2,
  },
  {
    effect: `Récupère 1 point de Mental après 15 minutes
    Radiations +20`,
    name: `Racines de Bani`,
    note: ``,
    value: 7,
    weight: 0.1,
  },
  {
    effect: `Radiations +5`,
    name: `Sachet de bonbons`,
    note: `Des fraises tagada, des Haribo ou des Schtroumpfs ...`,
    value: 21,
    weight: 0.2,
  },
  {
    effect: `Nourri fortement
    Radiations +10
    Chances d'être malade ou empoisonné`,
    name: `Viande d'animal sauvage`,
    note: `Du Bergaul, de la mouche, de la fourmie géante ou du scorpion, ...
    Ne peut plus empoisonner ou rendre malade une fois la viande cuite`,
    value: 6,
    weight: 1,
  },
];

export default data.map((i) => ({
  ...i,
  element: Element.ITEM,
  subtype: Subtype.General.FOOD,
  type: Type.Item.GENERAL,
}));
