export const Spells = Object.freeze({
  Types: {
    CAST: 'Incantation',
    SUMMON: 'Invocation',
    CHANNEL: 'Canalisation',
    CAST_CHANNEL: 'Canalisable',
  },
  Requirement: {
    CONTACT: 'Contact',
    PROXIMITY: 'Proximité',
    COMPONENT: 'Composant',
    RELIC: 'Relique',
  },
  SCHOOL: {
    ALL: 'Toutes écoles',
    SAME: 'Même école',
    DAWN: 'Aube',
    DUSK: 'Crépuscule',
    AWAKENING: 'Éveil',
    FORGE: 'Forge',
    TEMPEST: 'Tempête',
    ASCEND: 'Ascension',
    TRANSCENDANCE: 'Transcendance',
    ECLIPSE: 'Éclipse',
  }
});
