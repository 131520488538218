const aptitudes = [
  {
    effect: [`Chances de succès critique lors d'attaques ou de sorts contre des humanoïdes +10%`],
    name: `Anthropologue`,
  },
  {
    effect: [`Chances de succès critique lors d'attaques ou de sorts contre des plantes +10%`],
    name: `Botaniste`,
  },
  {
    effect: [`Double le nombre de mots que le personnage peut prononcer par action de "Parler ou crier"`],
    name: `Bavard`,
  },
  {
    effect: [`Réduit la durée des Saignements reçus de 1 tour`],
    name: `Coagulation rapide`,
  },
  {
    effect: [`Les attaques reçues ne réduisent plus le score de Mental`],
    name: `Coriace`,
  },
  {
    effect: [
      `Délaye les effets de la soif de 12 heures`,
      `Délaye les effets de la faim de 3 jours`,
    ],
    name: `Économe`,
  },
  {
    effect: [`Chercher un objet dans son sac est une action rapide`],
    name: `Fouine`,
  },
  {
    effect: [`Chances de succès critique lors d'attaques ou de sorts contre des élémentaires +10%`],
    name: `Généticien`,
  },
  {
    effect: [`Supprime une folie < 51`,],
    name: `Internement`,
    usable: 2,
  },
  {
    effect: [`Réduit le prix des objets achetés d'environ 30%`],
    name: `Marchand`,
  },
  {
    effect: [`Lors du sommeil, le moindre bruit peut réveiller le personnage s'il le décide`],
    name: `Oreille attentive`,
  },
  {
    effect: [`Réduit la durée des Brûlure reçues de 1 tour`],
    name: `Peau ignifugée`,
  },
  {
    effect: [`Double la durée des substances chimiques injectées ou absorbées par le personnage`],
    name: `Physionomie réceptive`,
  },
  {
    effect: [`Reprendre son souffle en combat est désormais une action rapide`],
    name: `Récupération rapide`,
  },
  {
    effect: [`Chances de succès critique lors d'attaques ou de sorts contre des robots +10%`],
    name: `Roboticien`,
  },
  {
    effect: [`Chances de succès critique des actions contre des individus de sexe opposé de même race +10%`],
    name: `Sexiste`,
  },
  {
    effect: [`Résistance aux effets de température augmentée de 3 degrés`],
    name: `Thermo-tolérant`,
  },
  {
    effect: [`Points de chance +3`],
    name: `Veinard`,
    usable: 2,
  },
  {
    effect: [`Chances de succès critique lors d'attaques ou de sorts contre des mutants +10%`],
    name: `Virologiste`,
  },
  {
    effect: [`Chances de succès critique lors d'attaques ou de sorts contre des bêtes +10%`],
    name: `Zoologue`,
  },
];

export default aptitudes.map((a) => ({ ...a, level: 2 }));
