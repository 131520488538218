export default Object.freeze([
  [`Temps`, `Effet`],
  [`1 jour`, `Irritabilité, inconfort`],
  [`2 jours`, `Irritabilité accrue, Fatigue légère`],
  [`3 jours`, `Faiblesse musculaire, concentration difficile`],
  [`4 jours`, `Irritabilité intense, étourdissements, maux de tête`],
  [`5 à 10 jours`, `Faiblesse extrême, vertiges, confusion mentale, perte de masse musculaire`],
  [`10 à 15 Jours`, `Hallucinations, perte de conscience, incapacité à se mouvoir, vision floue`],
  [`15 jours ou plus`, `Coma, défaillance des organes vitaux`],
]);
