export default Object.freeze([
  [`Radiations`, `Effet`],
  [`25`, `Légère faiblesse du système immunitaire, le personnage tombe plus facilement malade.`],
  [`75`, `Le personnage est anormalement fatigué et sa peau devient plus sensible aux irritations.`],
  [`150`, `Le personnage se sent mal, des tâches rouges semblables à des coups de soleil apparaissent sur son corps.`],
  [`400`, `Le personnage est empoisonné et physiquement diminué, ses cheveux tombent et il devient stérile.`],
  [`550`, `Le personnage commence à vomir régulièrement, ses plaies cicatrisent très mal, et ses organes internes sont affectés.`],
  [`650`, `Le personnage souffre de diarrhée, de douleurs osseuses intenses et de plaies ouvertes suintantes. Son métabolisme devient instable et des mutations étranges peuvent apparaître.`],
  [`800`, `Le personnage vomit du sang, n'a plus de cheveux et subit des défaillances d'organes. Il est mourant et doit trouver un médecin spécialiste ou des traitements avancés immédiatement.`],
  [`1000`, `Le personnage est dans le coma et sur le point de décéder, désormais incurable.`],
]);
