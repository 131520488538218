export default Object.freeze({
  FULL: [
    'Parler ou crier (10-12 mots)',
    'Se déplacer de 8-9m',
    'Attaquer',
    'Incanter un sort',
    'Fouiller un sac',
    'Se reposer',
  ],
  FAST: [
    'Parler ou crier (5-6 mots)',
    'Se déplacer de 4-5m',
    'Engainer ou Dégainer',
    'Recharger',
    'Viser',
    'Parer ou Esquiver',
    'Prendre un objet obstrué à portée de main',
    'Utiliser un objet',
    'Analyser une situation',
    'Cesser une invocation',
    'Céder à la folie',
  ],
  FREE: [
    'Parler ou crier (1-2 mots)',
    'Se décaler (environ 1 mètre)',
    'Prendre un objet libre à portée de main',
    'Observer une situation',
    'Maintenir un sort'
  ],
});
