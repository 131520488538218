import Skills from 'components/afterworld/Skills';
import alternativeCastingData from 'data/rules/alternativeCasting';
import penaltiesData from 'data/rules/penaltiesCasting';
import schoolsPrerequisites from 'data/rules/schoolsPrerequisites';
import { PageHeading } from 'components/elements';
import { Quote, Subtitle, Table, Text, Title } from 'components/semantic';

export function ManualMagicPage() {
  return (
    <>
      <PageHeading>
        Variantes
      </PageHeading>

      <Title>Incanter un sort</Title>

      <Text>
        Incanter un sort est une action pleine et il n'est donc pas possible de faire d'autres actions en même temps lors d'un combat. Cela nécessite une gestuelle physique et une messe verbale et n'est généralement pas discret. Cette action demande une certaine concentration: si le personnage est dérangé pendant l'incantation, il doit faire un test d'<Skills.Mastery.Intellect /> pour maintenir l'incantation.
      </Text>

      <Text>
        Les sorts prennent environ 5 secondes à être lancés. Lors d'un combat, les sorts se lancent une fois que chaque participant a terminé son action, avant de passer au tour suivant. Une fois lancé, il faut déduire le coût du sort aux points de Mental.
      </Text>

      <Quote>
        {`Si un sort n'arrive pas à terme, ce dernier ne coûtera que la moitié du Mental prévu
        Diverses réductions de coûts d'un sort peuvent être accumulées, mais cela ne peut pas réduire le coût en dessous de 1.`}
      </Quote>

      <Text>
        {`Des écoles de magie avancées nécessitent que le personnage ait un niveau minimum avant de pouvoir s'y lancer et elles ne peuvent pas être choisies comme magies de spécialité.`}
      </Text>

      <Table data={schoolsPrerequisites}></Table>

      <Title>Alternatives</Title>

      <Subtitle>Canaliser un sort</Subtitle>

      <Text>
        {`La plupart des sorts sont éphémères et doivent être canalisés pour maintenir l'effet. Canaliser un sort ne demande aucun jet de dé : il suffit de le décider après le lancement du sort, mais chaque tour de canalisation va réduire le Mental de la moitié de son coût de lancement initial à chaque tour de canalisation.`}
      </Text>

      <Quote>
        {`Si un sort coûte 3 de Mental, le canaliser coûtera 2 de Mental par tour`}
      </Quote>

      <Text>
        {`Tout comme une incantation, canaliser un sort est une action : le personnage ne peut pas faire d'autres actions en même temps.`}
      </Text>

      <Text>
        {`Une exception existe toutefois : lancer un autre sort en parallèle, en tenant compte des pénalités applicables. Incanter un sort alors qu'une canalisation est en cours apporte un malus de 25% sur les chances de succès du nouveau sort.`}
      </Text>

      <Subtitle>Les invocations</Subtitle>

      <Text>
        {`Une invocation est un sort spécial: son coût en Mental n'est jamais récupéré tant que l'invocation est présente, il faut donc la désinvoquer pour récupérer le Mental associée.`}
      </Text>

      <Text>
        {`Certains sorts d'invocation sont canalisés, notamment s'il s'agit d'invocations qui peuvent s'animer. Les invocations ne peuvent pas se mouvoir d'elles-même.`}
      </Text>

      <Text>
        {`Un personnage proche de son invocation peut décider cesser la cesser ce qui représente une action rapide. Les points de Mental bloqués par l'invocation peuvent désormais être récupérés.`}
      </Text>

      <Text>
        {`Désinvoquer un sort en urgence, à distance, ou subir une désinvocation forcée a des conséquences néfastes : perdre des points de Mental supplémentaires ou les perdre définitivement, selon la situation.`}
      </Text>

      <Subtitle>Melanger les magies</Subtitle>

      <Text>
        {`Certains sorts appartiennent à plusieurs écoles de magie en même temps et il existe plusieurs solutions pour les lancer.`}
      </Text>

      <Text>
        {`Soit le personnage maîtrise toutes les écoles du sort et peut le lancer de lui même, dans ce cas il faut faire le test dans l'école de magie avec le plus faible score, avec un malus de 10% par école de magie demandée par le sort.`}
      </Text>

      <Text>
        {`Soit il est possible de lancer un sort à plusieurs, dans ce cas chaque joueur décide alors avec quelle école de magie il veut participer au sort. Si un joueur décide de participer avec plusieurs écoles de magie, il faut se référer aux règles juste au-dessus pour son jet de dé. Chaque joueur aura un malus de 5% par joueur qui incante sur le sort.`}
      </Text>

      <Title>Actions</Title>

      <Subtitle>Céder à la folie</Subtitle>

      <Text>
        {`Afin de récupérer des points de Mental en urgence, un personnage peut décider de céder à la folie. Il s'agit d'une action rapide soit environ 2 à 3 secondes en combat et permet de récupérer 5 points de Mental au prix d'un point de Psychose.`}
      </Text>

      <Subtitle>Maintien de sort</Subtitle>

      <Text>
        {`Un sort incanté est lancé immédiatement après l'incantation. Toutefois il est possible de décider de le garder en "réserve" afin de lancer le sort plus tard.`}
      </Text>

      <Text>
        {`Comme pour la canalisation, maintenir un sort consomme la moitié du coût du sort en point de Mental par tour de maintien.`}
      </Text>

      <Text>
        {`Si le sort n'est pas lancé à terme, il coûtera tout de même la moitié de son coût en points de Mental.`}
      </Text>

      <Quote>
        En cas de vacarme environnant, il faut réussir un test d'<Skills.Mastery.Intellect /> pour continuer à maintenir un sort
        <br />
        Il n'est pas possible de maintenir plus d'un sort à la fois ni de lancer d'autres sorts tant qu'il y a un maintien
      </Quote>

      <Title>Variantes d&apos;incantation</Title>

      <Text>
        {`Lors de l'incantation d'un sort, certaines variantes peuvent être appliquées. Il est possible de lancer le sort plus rapidement, ou encore d'agrandir sa zone d'effet. Chacune de ces modifications sur le sort a un impact sur son coût.`}
      </Text>

      <Table data={alternativeCastingData}></Table>

      <Title>Penalites</Title>

      <Text>
        {`Selon les circonstances d'incantation, certaines pénalités sont prises en compte. Ces dernières impactent directement les chances de réussir un sort.`}
      </Text>

      <Table data={penaltiesData}></Table>
    </>
  );
}
