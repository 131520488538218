import importedNames from './names.json';
import importedRaces from './races.json';
import importedSkills from './skills.js';
import importedTraits from './traits.json';

export const names = importedNames;
export const races = importedRaces;
export const skills = importedSkills;
export const traits = importedTraits;

export default {
  names,
  races,
  skills,
  traits,
}
