// export default Object.freeze([
//   [`1`, `Les muscles du joueur pourrissent`],
//   [`2`, `Les os du joueur fondent`],
//   [`3`, `Les organes internes du joueur fondent`],
//   [`4`, `Un membre du corps se détache et tombe`],
//   [`5`, `Le joueur vomit ses organes`],
//   [`6`, `Une nouvelle tête pousse, test de localisation`],
//   [`7`, `Un nouveau bras pousse, test de localisation`],
//   [`8`, `Un tentacule pousse, test de localisation`],
//   [`9`, `Mute en monstre, personnage injouable`],
//   [`10`, `Mute en horreur, personnage injouable`],
// ]);


export default Object.freeze([
  [`1`, `Le joueur n'a plus besoin de manger ni de dormir, mais il dégage une odeur putride.`],
  [`2`, `Le corps du joueur devient translucide, révélant ses organes en activité.`],
  [`3`, `Son sang devient bioluminescent et légèrement acide, rongeant les tissus internes.`],
  [`4`, `Des plaques chitineuses recouvrent la peau, offrant une armure naturelle mais limitant la mobilité.`],
  [`5`, `Les yeux du joueur fusionnent en un seul œil central, affectant sa perception.`],
  [`6`, `Le joueur développe un cri ultrasonique incontrôlé qui brise le verre et attire des créatures.`],
  [`7`, `Sa peau se couvre d'excroissances osseuses pointues qui blessent à chaque mouvement.`],
  [`8`, `Le cerveau du joueur subit une mutation, provoquant des hallucinations permanentes.`],
  [`9`, `Le joueur développe des glandes à venin, mais ses fluides corporels deviennent toxiques même pour lui.`],
  [`10`, `Le joueur entre régulièrement dans un état de stase involontaire et ne peut plus bouger pendant plusieurs heures.`],
  [`11`, `Une nouvelle tête pousse, mais elle a sa propre conscience, test de localisation pour déterminer l'endroit.`],
  [`12`, `Un nouveau bras pousse, incontrôlable et semi-autonome, test de localisation pour déterminer l'endroit.`],
  [`13`, `Un tentacule pousse, gluant et doté d'une sensibilité étrange, test de localisation pour déterminer l'endroit.`],
  [`14`, `Un membre du corps se détache et tombe.`],
  [`15`, `Les muscles du joueur pourrissent, réduisant sa force jusqu'à l'immobilité.`],
  [`16`, `Les os du joueur fondent, entraînant une douleur insoutenable et une incapacité à se déplacer.`],
  [`17`, `Les organes internes du joueur fondent, provoquant un empoisonnement systémique.`],
  [`18`, `Le joueur vomit ses organes, sa survie devient impossible sans intervention immédiate.`],
  [`19`, `Mute en monstre, personnage injouable, développe une faim insatiable.`],
  [`20`, `Mute en horreur, personnage injouable, devient un prédateur mutant.`],
]);

