// function removeAccents(str) {
//   return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
// }

export default function replaceAccentedCharacters(inputString) {
  const accentsMap = {
    'á': 'a', 'é': 'e', 'í': 'i', 'ó': 'o', 'ú': 'u',
    'à': 'a', 'è': 'e', 'ì': 'i', 'ò': 'o', 'ù': 'u',
    'â': 'a', 'ê': 'e', 'î': 'i', 'ô': 'o', 'û': 'u',
    'ä': 'a', 'ë': 'e', 'ï': 'i', 'ö': 'o', 'ü': 'u',
    'ã': 'a', 'õ': 'o',
    'ñ': 'n',
    'ç': 'c',
    'ý': 'y', 'ÿ': 'y',
    'æ': 'ae', 'œ': 'oe',
    'Á': 'A', 'É': 'E', 'Í': 'I', 'Ó': 'O', 'Ú': 'U',
    'À': 'A', 'È': 'E', 'Ì': 'I', 'Ò': 'O', 'Ù': 'U',
    'Â': 'A', 'Ê': 'E', 'Î': 'I', 'Ô': 'O', 'Û': 'U',
    'Ä': 'A', 'Ë': 'E', 'Ï': 'I', 'Ö': 'O', 'Ü': 'U',
    'Ã': 'A', 'Õ': 'O',
    'Ñ': 'N',
    'Ç': 'C',
    'Ý': 'Y', 'Ÿ': 'Y',
    'Æ': 'AE', 'Œ': 'OE'
    // add more if needed
  };

  return inputString.replace(/[^\x00-\x7F]/g, function (character) {
    return accentsMap[character] || character;
  });
}
