export default Object.freeze([
  [`Maladie`, `Incubation`, `Transmission`, `Effet`],
  [`Vermine de chair`, `12 heures`, `Contact sur plaie ouverte`, `J+1 : Douleurs atroces. J+2 : Gangrène des tissus. J+3 : décès`],
  [`Peste bubonique`, `1-7 jours`, `Contact`, `J : Fièvre, fatigue, maux de tête, bubons. J+4 : décès`],
  [`Rampade`, `2 jours`, `Contact fongique, inhalation de spores`, `J+2 : Toux sèche et fièvre. J+7 : Peau qui noircit, douleurs nerveuses. J+14 : Le parasite colonise le corps et prend le contrôle du système nerveux.`],
  [`Putride`, `2-3 jours`, `Contact`, `J : Gangrène des tissus. J+7 : décès`],
  [`Peste des sables`, `2-4 jours`, `Piqûre d'insecte`, `J+2 : Fièvre élevée, éruptions cutanées. J+5 : Gangrène des extrémités. J+10 : décès`],
  [`Rage`, `20-90 jours`, `Morsure, Griffure`, `J : Fièvre, insomnie, maux de tête, irritabilité. J+7 : faiblesses musculaires, déglutition et respiration  difficile. J+14 : décès`],
  [`Variole`, `4-17 jours`, `Inhalation, Contact`, `J : Fatigue, vomissements, pustules. J+10 : décès (infection, arrêt cardiaque)`],
  [`VIH`, ``, `Sexe, sang`, `Destruction du système immunitaire`],
]);
