
import { List, Text, Title } from 'components/semantic';
import { PageHeading } from 'components/elements';

const jobs = {
  Artisanat: [
    'Alchimiste',
    'Bottier - Cordonnier',
    'Couturier',
    'Dépeceur',
    'Forgeron',
    'Horloger',
    'Maroquinier',
    'Ornemaniste',
    'Papetier',
    'Tailleur - Sculpteur',
    'Tanneur',
    'Teinturier',
    `Armurier - Fabricant d'arc`,
  ],
  Nomade: [
    'Bûcheron',
    'Caravanier',
    'Charognard',
    'Contrebandier',
    'Courrier - Livreur',
    'Explorateur',
    'Guide',
    'Herboriste',
    'Pillard',
    'Récupérateur',
  ],
  Nourriture: [
    'Apiculteur',
    'Brasseur',
    'Chasseur',
    'Cueilleur',
    'Cuisinier',
    'Fermier - Vigneron',
    'Éleveur',
    'Pêcheur',
    `Testeur d'aliments`,
  ],
  Sciences: [
    'Apothicaire',
    'Archiviste - Bibliothécaire',
    'Guérisseur',
    'Historien',
    'Informaticien',
    'Mécanicien',
  ],
  Social: [
    'Chef',
    'Comédien',
    'Diplomate',
    'Esclavagiste',
    'Espion',
    'Gourou',
    'Héraut',
    'Musicien',
    'Prophète',
    'Prostitué',
    'Proxénète',
    'Receleur',
    'Scribe',
    'Tavernier - Tenancier',
    'Vendeur',
  ],
  Violence: [
    'Assassin',
    'Bourreau',
    'Chasseur de primes',
    'Garde de ville',
    'Garde du corps',
    'Garde-chasse',
    'Mercenaire',
    'Milicien',
  ]
};

export function UniverseJobsPage() {
  return (
    <>
      <PageHeading>
        {`Les métiers`}
      </PageHeading>

      <Text>
        {`La liste des métiers présentée ici n'est pas exhaustive.`}
      </Text>

      <Title>{`Artisanat`}</Title>
      <List data={jobs.Artisanat} />

      <Title>{`Nomade`}</Title>
      <List data={jobs.Nomade} />

      <Title>{`Nourriture`}</Title>
      <List data={jobs.Nourriture} />

      <Title>{`Sciences`}</Title>
      <List data={jobs.Sciences} />

      <Title>{`Social et commerce`}</Title>
      <List data={jobs.Social} />

      <Title>{`Violence`}</Title>
      <List data={jobs.Violence} />
    </>
  );
}
