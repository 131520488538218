import { Element, Type, Subtype } from 'data/metadata';

const data = [
  {
    armor: { armRight: 45 },
    description: `Un bracelet qui peut déclencher une protection énergétique circulaire de diamètre d'environ 50 centimètres`,
    name: `Bouclier de protection Plasma`,
    value: 163,
    weight: 2,
    effects: `Nécessite de l'énergie (consomme 100 PE par heure)`,
  },
  {
    armor: { armRight: 25 },
    description: `Un bouclier rond en acier simple d'environ 1 centimètre d'épaisseur`,
    name: `Bouclier en acier`,
    value: 49,
    weight: 12,
  },
  {
    armor: { armRight: 12 },
    description: `Un bouclier rond en bois simple d'environ 2 centimètres d'épaisseur`,
    name: `Bouclier en bois`,
    value: 14,
    weight: 4,
  },
  {
    armor: { armRight: 18 },
    description: `Une carapace de tortue mutante travaillée, une pièce plutôt rare`,
    name: `Carapace de tortue mutante taillée`,
    value: 76,
    weight: 10,
  },
  {
    armor: {
      head: 35,
      armRight: 35,
      armLeft: 35,
      chest: 35,
      legRight: 35,
      legLeft: 35,
    },
    description: `Un pavois extrêmement lourd derrière lequel se cacher
    N'est généralement pas porté mais transporté et déposé`,
    name: `Mur mobile de protection en titanium`,
    value: 850,
    weight: 50,
  },
  {
    armor: {
      head: 25,
      armRight: 25,
      armLeft: 25,
      chest: 25,
      legRight: 25,
      legLeft: 25,
    },
    description: `Une grande plaque en acier avec une vitre au niveau du visage, utilisé comme protection lors du contrôle des émeutes`,
    name: `Pavois antiémeute en acier`,
    value: 124,
    weight: 18,
    effects: `Nécessite de l'énergie (consomme 150 PE par heure)`,
  },
  {
    armor: {
      head: 45,
      armRight: 45,
      armLeft: 45,
      chest: 45,
      legRight: 45,
      legLeft: 45,
    },
    description: `Un bracelet large qui peut déclencher une protection rectangulaire d'environ 150 centimètres de hauteur pour 80 centimètres de largeur`,
    name: `Pavois antiémeute Plasma`,
    value: 326,
    weight: 3,
  },
  {
    armor: {
      head: 12,
      armRight: 12,
      armLeft: 12,
      chest: 12,
      legRight: 12,
      legLeft: 12,
    },
    description: `Une grande planche de bois taillée qui permet de se cacher entièrement derrière`,
    name: `Pavois en bois`,
    value: 25,
    weight: 8,
  },
];


export default data.map((i) => ({
  ...i,
  element: Element.ITEM,
  subtype: Subtype.Armor.SHIELD,
  type: Type.Item.ARMOR,
}));
