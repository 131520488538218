const aptitudes = [
  {
    effect: [
      `Par point de Psychose, augmente les dégâts de 5`,
      `Par folie, augmente les dégâts d'un montant équivalent au DR de l'attaque`,
    ],
    name: `Adepte de la démence`,
  },
  {
    effect: [
      `Permet de porter les armes lourdes à une main`,
      `Permet de recharger deux armes lourdes en une seule action de recharge`,
    ],
    name: `Adepte du gros calibre`,
  },
  {
    effect: [
      `Les variantes d'incantation ne génèrent plus de pénalités de Mental`,
      `Augmente les dégâts des sorts du DR du jet de lancement du sort`,
    ],
    name: `Archimage`,
  },
  {
    effect: [
      `Lors des tests de compétence, si un allié proche a également l'aptitude 'Cohésion du groupe': il est possible d'utiliser son score de compétence pour faire le test`,
    ],
    name: `Cohésion du groupe`,
  },
  {
    effect: [
      `Lors des tests de compétence en combat, il est possible d'utiliser le score de compétence d'une personne au choix à la place de celui du personnage`,
      `Attention: il ne faut pas connaître les scores de compétence des participants au combat, il faut lui donner le score et demander si l'action est réussie`,
    ],
    name: `Esprit miroir`,
  },
  {
    effect: [
      `Double les dégâts infligés avec des armes qui utilisent la compétence Corps-à-corps`,
      `Les attaques ne peuvent plus être parées avec des objets : si cela arrive, l'objet est brisé et le coup est quand même porté`,
    ],
    name: `Force démente`,
  },
  {
    effect: [`Augmente les dégâts infligés par le personnage d'un montant équivalent au plus haut score de dégâts sur son corps`],
    name: `Frénétique`,
  },
  {
    effect: [
      `Lors des combats, après que chaque participant ait agit lors de la phase d'Initiative, octroie une action rapide supplémentaire`,
      `Il est possible de faire 2 actions gratuites par tour`,
    ],
    name: `Homme d'action`,
  },
  {
    effect: [
      `Double les dégâts infligés avec des armes qui utilisent la compétence Lancer et pièges`,
      `Lancer un objet est une action rapide`,
    ],
    name: `Joker`,
  },
  {
    effect: [
      `Les attaques avec la compétence ${CHARACTER.SKILLS.WEAPONS.LIGHT} sont maintenant des actions rapides`,
      `Après avoir dégainé une arme légère : applique les mêmes bonus qu'une attaque furtive`,
    ],
    name: `Lucky-Luke`,
    requirements: [`Aptitude 'Tireur d'élite'`],
  },
  {
    effect: [
      `Double les dégâts avec la compétence ${CHARACTER.SKILLS.WEAPONS.LIGHT} lors de tirs précis`,
      `Les tirs précis sont possibles en course`,
    ],
    name: `Oeil de lynx`,
  },
  {
    effect: [
      `La parade est désormais une action gratuite (une seule action gratuite est possible par tour)`,
      `Capable de se déplacer autant qu'une action pleine en action gratuite s'il s'agit de parer pour un allié`,
    ],
    name: `Protecteur aguerri`,
  },
  {
    effect: [`Toute blessure se régénère immédiatement si elle n'est pas fatale`],
    name: `Radioactivité cellulaire`,
    requirements: [`Radiations 800`],
  },
  {
    effect: [
      `Augmente les dégâts du personnage des derniers dégâts qu'il a infligé (en ignorant ce bonus)`,
      `Dépendance aux dégâts infligés`,
    ],
    name: `Sadisme`,
  },
  {
    effect: [`Il est possible d'esquiver et parer les attaques furtives, même lors d'une incantation`],
    name: `Sixième sens`,
  },
  {
    effect: [
      `Augmente les chances de réussite des sorts avec plusieurs écoles de magie : montant de la magie la plus élevée du sort ÷ nombre total d'écoles de magie du sort`,
      `Réduit le coût en Mental des sorts avec plusieurs écoles de magie de 2`,
    ],
    name: `Souffleur de magie`,
  },
]

export default aptitudes.map((a) => ({ ...a, level: 15 }));
