
import { Quote, Text, Title } from 'components/semantic';
import { PageHeading } from 'components/elements';

export function ManualKnowledgePage() {
  return (
    <>
      <PageHeading>
        Carrière
      </PageHeading>

      <Text>
        {`Les vocations et les savoirs permettent d'ajouter une dimension role-play et davantage simulationniste sans une sur-couche de complexité trop importante. Ces mécanismes sont optionnels et il est possible de jouer sans.`}
      </Text>

      <Title>Les savoirs</Title>

      <Text>
        {`Les savoirs sont une sorte de spécialisation des compétences : par exemple certains savoirs relèvent de la compétence Ingénierie comme L'informatique, La mécanique ou La robotique.`}
      </Text>

      <Quote>
        {`Un test de compétence en rapport avec un savoir sans l'avoir voit ses chances de succès réduites de moitié`}
      </Quote>

      <Text>
        {`Obtenir des savoirs peut se faire de diverses façons : naturellement à la création du personnage, avec de l'entraînement ou par l'apprentissage d'une vocation.`}
      </Text>

      <Title>Les vocations</Title>
    </>
  );
}
