import { Spells } from 'data/metadata';

const data = Object.freeze([
  {
    additionalEffects: [`Surcharge : double la perte de Mental de la personne ciblée`],
    cost: 1,
    description: `Contrecarre le sort d'une personne, ce qui interrompt son incantation et lui fait perdre le coût du sort en points de Mental.`,
    name: `Contre-sort`,
    requirements: [`même école de magie que le sort visé`],
    schools: [{ name: Spells.SCHOOL.SAME }],
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [
      `Surcharge : l'invocateur tombe inconscient`,
      `Surcharge : augmente les dégâts à 25 + 4x1d8 par invocation`,
    ],
    cost: 2,
    damage: `15 + 3x1d6 (contondants)`,
    description: `Désinvoque une invocation et fait perdre à l'invocateur autant de points de Mental que le coût du sort.`,
    name: `Désinvocation`,
    requirements: [
      `contact avec l'invocation`,
      `même école de magie que l'invocation visée`,
    ],
    schools: [{ name: Spells.SCHOOL.SAME }],
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [`Attention : intervenir sur l'incantation d'autrui de manière impromptue peut avoir des effets catastrophiques`],
    cost: 2,
    description: `Intervient subtilement dans l'incantation d'une personne afin de tenter d'en changer le sujet, de sorte que ce sort atteigne une autre personne.`,
    name: `Redirection`,
    requirements: [`même école de magie que le sort visé`],
    schools: [{ name: Spells.SCHOOL.SAME }],
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [
      `Effet : impossible d'incanter des sorts de l'école visée dans la zone pendant 2 tours`,
      `Surcharge : augmente les blessures à 40 + 3x1d8`,
    ],
    cost: 3,
    damage: `25 + 2x1d6 (contondants)`,
    description: `Détruit les vents magiques d'une école précise autour d'une personne ce qui l'empêche de lancer des sorts de cette école de magie et lui inflige des dégâts globaux.`,
    name: `Brûlure de magie`,
    requirements: [`contact`],
    schools: [{ name: Spells.SCHOOL.SAME }],
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [`Attention : intervenir sur l'incantation d'autrui de manière impromptue peut avoir des effets catastrophiques`],
    cost: 3,
    description: `Intervient dans l'incantation d'une personne et tente d'en changer le sort pour un autre.`,
    name: `Changement d'incantation`,
    requirements: [`même école de magie que le sort visé`],
    schools: [{ name: Spells.SCHOOL.SAME }],
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [
      `Surcharge : double la perte de Mental`,
      `Surcharge : augmente les dégâts à 25 + 4x1d8 par invocation`,
    ],
    area: `10 m²`,
    cost: 3,
    damage: `15 + 3x1d6 (contondants) par invocation`,
    description: `Désinvoque toutes les invocations environnantes et fait perdre autant de points de Mental aux invocateurs que le coût de leur invocation.`,
    name: `Désinvocation de masse`,
    requirements: [
      `proximité`,
      `composant (échantillon de chaos magique stable)`,
    ],
    schools: [{ name: Spells.SCHOOL.ALL }],
    types: [Spells.Types.CAST],
  },
  {
    additionalEffects: [
      `Effet : impossible d'incanter dans la zone pendant 2 tours`,
      `Surcharge : porte les blessures à 40 + 3x1d8`,
    ],
    cost: 3,
    damage: `25 + 2x1d6`,
    description: `Détruit les vents magiques proches d'un individu, ce qui interrompt les incantations en cours et lui inflige des dégâts pour chaque école de magie qu'il était en train d'incanter.`,
    name: `Destruction du chaos`,
    requirements: [
      `proximité`,
      `composant (échantillon de chaos magique stable)`,
    ],
    schools: [{ name: Spells.SCHOOL.ALL }],
    types: [Spells.Types.CAST],
  },
]);

export default data;
