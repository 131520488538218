import { Element, Type, Subtype } from 'data/metadata';

const data = [
  {
    effects: ``,
    name: `Balle 5,56mm`,
    value: 2,
    weight: 0,
  },
  {
    effects: ``,
    name: `Balle 9mm ou 10mm`,
    value: 1,
    weight: 0,
  },
  {
    effects: `Dégâts +3 par munition qui touche / Les balles restent souvent fichées dans les cibles`,
    name: `Balle 9mm ou 10mm à pointe creuse`,
    value: 2,
    weight: 0,
  },
  {
    effects: `Dégâts -3 par munition qui touche / Les balles traversent et ricochent facilement`,
    name: `Balle 9mm ou 10mm blindée`,
    value: 1,
    weight: 0,
  },
  {
    effects: `Ignore jusqu'à 20 points d'armure de la cible`,
    name: `Balle 9mm ou 10mm perforante`,
    value: 3,
    weight: 0,
  },
  {
    effects: ``,
    name: `Calibre .32 ou .44`,
    value: 1,
    weight: 0,
  },
  {
    effects: `Dégâts +3 par munition qui touche / Les balles restent souvent fichées dans les cibles`,
    name: `Calibre .32 ou .44 à pointe creuse`,
    value: 2,
    weight: 0,
  },
  {
    effects: `Dégâts -3 par munition qui touche / Les balles traversent et ricochent facilement`,
    name: `Calibre .32 ou .44 blindée`,
    value: 1,
    weight: 0,
  },
  {
    effects: `Ignore jusqu'à 30 points d'armure de la cible`,
    name: `Calibre .32 ou .44 perforant`,
    value: 3,
    weight: 0,
  },
  {
    effects: ``,
    name: `Carburant`,
    value: 5,
    weight: 0,
  },
  {
    effects: ``,
    name: `Cellule énergétique`,
    value: 2,
    weight: 0,
  },
  {
    effects: ``,
    name: `Fléchette`,
    value: 1,
    weight: 0,
  },
  {
    effects: `Ajoute un type de poison aux attaques`,
    name: `Fléchette empoisonnée`,
    value: 3,
    weight: 0,
  },
  {
    effects: `Ignore jusqu'à 10 points d'armure de la cible`,
    name: `Fléchette à pointe d'acier`,
    value: 4,
    weight: 0,
  },
  {
    effects: `La zone touchée applique Radiations Ⅳ par tour pendant 1 jour`,
    name: `Mini-Nuke`,
    value: 850,
    weight: 8,
  },
  {
    effects: ``,
    name: `Missile`,
    value: 20,
    weight: 3,
  },
  {
    effects: `Module individuel équipé de micro-processeur et de capteurs qui se connecte derrière une balle de n'importe quel calibre et lui donne de l'intelligence
    L'armure utilisée doit disposer d'un pointeur laser pour diriger la munition
    La balle suit le pointeur autant que possible puis devient autonome au repérage de la cible
    Les chances de touchées sont augmentées de 30% et les chances de succès critique de 10%`,
    name: `Module de lumino-guidage`,
    value: 25,
    weight: 0.01,
  },
  {
    effects: `Module individuel qui se connecte derrière une balle de n'importe quel calibre
    Nécessite de régler la distance d'activation avec un test d'Ingénierie
    En cas de succès, les chances de touchées sont augmentées de 50%`,
    name: `Module de thermo-guidage`,
    value: 10,
    weight: 0.01,
  },
];

export default data.map((i) => ({
  ...i,
  element: Element.ITEM,
  subtype: Subtype.Weapon.AMMUNITION,
  type: Type.Item.WEAPON,
}));
