export default Object.freeze([
  [`Affamé`, `Compétences -10`, `Nourriture + Temps`],
  [`Alcool`, `Force +5, Intelligence -5`, `Temps`],
  [`Asphyxié`, `Compétences -20`, `Air + Temps`],
  [`Assourdi`, `N'entend plus`, `Temps`],
  [`Au sol`, `Parade et esquive impossible`, `Se relever`],
  [`Aveuglé`, `Incapable de voir`, `Repos des yeux`],
  [`Contrôlé`, `Actions contrôlées par autrui`, `Test de Volonté`],
  [`Désarmé`, `Arme déséquipée`, `Récupérer arme`],
  [`Désorienté`, `Plus de magie`, `Test d'Intelligence`],
  [`Ébloui`, `Attaque -10, ${CHARACTER.SKILLS.MASTERY.ACUITY} (vue) -10`, `Repos des yeux`],
  [`Essoufflement`, `Compétences -10`, `Air`],
  [`Étourdi`, `Incapable d'agir`, `Repos + temps`],
  [`Fasciné`, `Incapable d'agir`, `Test de Volonté`],
  [`Fou`, `Actions changeantes`, `Test de Volonté`],
  [`Immobilisé`, `Immobile`, `Test de Vigueur`],
  [`Inconscient`, `Incapable d'agir`, `-`],
  [`Manque I`, `Compétences -5`, `Alcool ou drogue`],
  [`Manque Ⅱ`, `Compétences -10`, `Alcool ou drogue`],
  [`Manque Ⅲ`, `Compétences -30`, `Alcool ou drogue`],
  [`Paralysé`, `Incapable d'agir`, `-`],
  [`Peur`, `Incapable d'agir`, `Test de Sang froid`],
  [`Phobie`, `Incapable d'agir`, `Test de Sang froid à -40`],
  [`Surcharge légère (< 20)`, `Compétences -10, course impossible`, `Moins d'objets`],
  [`Surcharge lourde (> 20)`, `Compétences -20, immobilisé`, `Moins d'objets`],
  [`Vomissements`, `Vigueur -10`, `-`],
]);
