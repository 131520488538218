import Box from '@mui/material/Box';
import { Link } from "react-router-dom";

import { PageHeading, Version } from 'components/elements';
import { PageRootContainer } from 'layout/PageRootContainer';

import packageJson from '../../package.json';


export function AboutPage() {
  return (
    <PageRootContainer>
      <PageHeading>
        À propos
      </PageHeading>

      <Box sx={{ p: 6, textAlign: 'justify' }}>
        <Box sx={{ mb: 1 }}>
          Application, jeu et règles réalisé par <Link to={`https://olivierleclercq.xyz`} target="_blank" rel="noopener noreferrer" >Olivier Leclercq</Link>.
        </Box>

        <Box sx={{ mb: 1 }}>
          Les images et certains textes sont générés par intelligence artificielle.
          <br />
          Si du contenu sur cette application ressemble ou s'apparente à une oeuvre dont vous êtes l'auteur, veuillez me contacter pour régulariser la situation.
        </Box>

        {/* <Box sx={{ mb: 1 }}>
          Le nom "Afterworld" est une marque déposée.
        </Box>

        <Box sx={{ mb: 2 }}>
          La propriété intellectuelle du contenu qui n'est pas généré par IA est protégé.
        </Box>

        <Box sx={{ mb: 2 }}>
          Toute utilisation non consentie à des fins commerciales est frauduleuse et interdite.
        </Box>*/}

        <Box sx={{ mb: 2 }}>
          Cette application et ce jeu ne sont pas publiés à des fins commerciales.
        </Box>

        {/* <Box sx={{ mb: 2 }}>
          Des services sont proposées à des fin commerciales, principalement car ceux-ci représentent des coûts à l'utilisation.
        </Box> */}
      </Box>
      <Version version={packageJson.version} />
    </PageRootContainer>
  );
}
