export const Monsters = Object.freeze({
  Type: {
    BEAST: 'Bête',
    ELEMENTAL: 'Élémentaire',
    HUMAN: 'Humanoïde',
    MUTANT: 'Mutant',
    PLANT: 'Plante',
    ROBOT: 'Robot',
  },
  Skills: {
    Alarme: `Alarme : produit un son strident audible à des kilomètres à la ronde`,
    AuraRadioactive: (amount) => (`Aura radioactive : applique Radiations ${amount} aux personnes proches à la fin de chaque tour`),
    Charge: (distance, damage) => (`Charge : nécessite au moins ${distance} mètres avec la cible, dégâts +${damage} et renverse au sol`),
    Chef: (monster, amount) => (`Chef : augmente l'Initiative des ${monster} de ${amount}`),
    CorpsDePoison: (poison, duration, type) => (`Corps de poison : à chaque coup reçu en mêlée, inflige Poison ${poison} à l'attaquant pour ${duration} tours, type ${type}`),
    DoubleEsquive: `Double esquive : permet d'effectuer une deuxième esquive par tour`,
    Enfouissement: `Enfouissement : s'enterre dans le sol afin de se cacher`,
    Envol: (chances) => (`Envol : chances d'être touché réduites de ${chances} %`),
    Faiblesse: (zone, amount) => (`Faiblesse : armure de ${amount} dans la zone "${zone}"`),
    Feinte: `Feinte : permet de feindre le bruit d'un chargeur vide`,
    Flasque: `Flasque : dégâts physiques réduits de moitié`,
    Group: (number) => (`Groupe : se trouve généralement par groupe de ${number}`),
    HurlementStrident: `Hurlement strident : fait appel à tout monstre similaire proche qui aurait entendu ce cri`,
    Intangible: `Intangible : insensible aux dégâts physiques`,
    MouvementsErratiques: (chances) => (`Mouvements erratiques : lors d'une course, chances d'être touché réduites de ${chances} %`),
    Piétinement: `Piétinement: lors d'une charge ou d'une attaque sur quelqu'un au sol, les dégâts sont doublés`,
    Poison: (type, time, chances = '') => (`${chances ? `${chances}% : ` : ''}Applique Poison "${type}" pour ${time}`),
    RégénérationRadioactive: `Régénération radioactive : récupère toute la santé à la fin de chaque tour`,
    Résistance: (zone, amount) => (`Résistance : armure de ${amount} dans la zone "${zone}"`),
    Saut: (damage) => (`Saut : nécessite entre 1 et 2 mètres avec la cible, dégâts + ${damage} et renverse la cible au sol`),
    Surcharge: `Surcharge: augmente l'initiative de 10 et les actions de 1 pendant 6 tours, puis ne peut plus agir pendant 6 tours`,
    Tremblement: `Tremblement: renverse les personnes proches au sol et les désarme`,
    Tunnel: `Tunnel : capable de se loger sous terre et de creuser des tunnels de sa propre taille`,
    Virevoltant: (chances) => (`Virevoltant : chances d'être touché réduites de ${chances} %`),
  },
});
