import { Image, Text, Title } from 'components/semantic';
import { Gallery, PageHeading } from 'components/elements';

export function UniverseKnowledgeRacesPage() {
  return (
    <>
      <PageHeading>
        {`Les races`}
      </PageHeading>

      <Title>Humain</Title>

      <Text>
        {`Vestiges d’un monde disparu, les humains errent dans les ruines d’un passé qu’ils ne comprennent plus. Leurs cités ne sont que des ombres, leur technologie une relique qu’ils peinent à maîtriser. Certains tentent de rebâtir une civilisation, d’autres embrassent le chaos du nouveau monde. Survivants avant tout, ils sont à la fois l’héritage d’hier et l’incertitude de demain, tiraillés entre nostalgie et adaptation.`}
      </Text>

      <Gallery>
        <Image size="medium" src="/images/universe/races/human1.webp" />
        <Image size="medium" src="/images/universe/races/human2.webp" />
        <Image size="medium" src="/images/universe/races/human3.webp" />
      </Gallery>

      <Text>
        {`Un humain adulte fait généralement entre 160 et 220 centimètres, pèse entre 60 et 120 kilogrammes et vit jusqu'à environ 60 ans.`}
      </Text>

      <Title>Salbek</Title>

      <Text>
        {`Ils étaient humains autrefois, mais le feu du Grand Boom les a purifiés. La chair calcinée, les os renforcés par les radiations, ils ont survécu là où d’autres n’étaient que cendres. Leur existence est un dogme, leur foi une certitude : le monde doit embrasser la mutation. Stériles et immortels dans l’âme, ils voient leur corps comme un temple corrompu en attente d’une ultime ascension. Le salut passe par la souffrance et l’acceptation de leur nature nouvelle.`}
      </Text>

      <Gallery>
        <Image size="medium" src="/images/universe/races/salbek1.webp" />
        <Image size="medium" src="/images/universe/races/salbek2.webp" />
        <Image size="medium" src="/images/universe/races/salbek3.webp" />
      </Gallery>

      <Text>
        {`Leur peau a changé comme si elle avait brûlé et varie du brun au vert. Ils sont devenus stériles, mais leur espérance de vie a drastiquement augmenté.`}
      </Text>

      <Text>
        {`Les Salbek mesurent entre 160 et 220 centimètres, pèsent entre 40 et 80 kilogrammes et peuvent vivre entre 200 et 300 ans.`}
      </Text>

      <Title>Thog</Title>

      <Text>
        {`Nés d’une épidémie qui aurait dû les faucher, les Thog sont la colère et la résilience incarnées. Leurs muscles sont des montagnes de chair tendue, leurs corps des armes vivantes façonnées par la maladie. Certains les craignent, les considérant comme des aberrations, d’autres les respectent pour leur force brute et leur endurance sans égale. Chaque Thog porte en lui la marque du virus, une loterie génétique qui ne touche qu’une minorité mais en fait des titans du nouveau monde.`}
      </Text>

      <Text>
        {`Quand l'infection est clémente, les Thog gardent leur humanité, mais dans d'autres cas, ils cèdent à la folie et n'écoutent plus que leur instincts primitifs.`}
      </Text>

      <Gallery>
        <Image size="medium" src="/images/universe/races/thog1.webp" />
        <Image size="medium" src="/images/universe/races/thog2.webp" />
        <Image size="medium" src="/images/universe/races/thog3.webp" />
      </Gallery>

      <Text>
        {`La peau des Thog varie du brun au jaunâtre. Ils ont des muscles saillants qui donnent l'impression d'être contractés en permanence.`}
      </Text>

      <Text>
        {`Ils mesurent entre 180 et 260 centimètres, pèsent entre 100 et 180 kilogrammes et peuvent vivre jusqu'à environ 90 ans.`}
      </Text>

      <Title>Cyborgs</Title>

      <Text>
        {`La chair est faible. La pensée est inefficace. Pour les Cyborgs, l’humanité n’est qu’un vestige du passé à effacer pièce par pièce. Certains remplacent un bras, d’autres leur système nerveux, et les plus extrêmes ne sont plus que machines aux souvenirs flous. Ils n’ont pas de nation, seulement une quête : transcender leur condition organique et devenir plus que des êtres vivants. Pour eux, l’avenir appartient aux circuits, et la conscience biologique est une erreur à corriger.`}
      </Text>

      <Gallery>
        <Image size="medium" src="/images/universe/races/cyborg1.webp" />
        <Image size="medium" src="/images/universe/races/cyborg2.webp" />
        <Image size="medium" src="/images/universe/races/cyborg3.webp" />
      </Gallery>

      <Text>
        {`Ils sont de la même taille que les humains et pèsent autant. Leur espérance de vie peut toutefois s'élever jusqu'à dans les 80 ans, en fonction des membres qui ont été remplacés.`}
      </Text>

      <Title>Elementaires</Title>

      <Text>
        {`Ils sont nés avec un souffle de magie dans les veines, des êtres dont le corps est partiellement façonné par les énergies arcaniques. Une main sculptée dans la pierre, des cheveux faits de flammes, une peau aux reflets aqueux… Chaque Élémentaire est un écho du chaos magique qui imprègne ce monde brisé. Trop connectés aux flux ésotériques, ils vivent à la frontière entre le réel et l’éthéré, percevant des murmures que d’autres ignorent. Leur existence est un équilibre fragile entre puissance et dissolution.`}
      </Text>

      <Gallery>
        <Image size="medium" src="/images/universe/races/elementary1.webp" />
        <Image size="medium" src="/images/universe/races/elementary2.webp" />
        <Image size="medium" src="/images/universe/races/elementary3.webp" />
      </Gallery>

      <Text>
        {`Les élémentaires mesurent entre 140 et 180 centimètres, pèsent entre 40 et 80 kilogrammes et vivent jusqu'à environ 80 ans.`}
      </Text>

      <Title>Adrulens</Title>

      <Text>
        {`Exilés de leur monde natal, les Adrulens ont vu leur empire tomber sous le poids de la trahison et de la guerre. Désormais dispersés parmi les mortels, ils conservent leur majesté, leur savoir et leur dévotion inébranlable à leurs divinités perdues. Leur apparence élancée et leurs traits raffinés cachent un passé de conquérants et de mystiques. Certains cherchent à reconstruire leur civilisation, d’autres embrassent l’errance comme une punition divine. Mais tous savent que leur exil n’est pas une fin, seulement une épreuve.`}
      </Text>

      <Gallery>
        <Image size="medium" src="/images/universe/races/adrulen1.webp" />
        <Image size="medium" src="/images/universe/races/adrulen2.webp" />
        <Image size="medium" src="/images/universe/races/adrulen3.webp" />
      </Gallery>

      <Text>
        {`Lépor, leur monde d’origine, n’est plus qu’une planète stérile, entièrement façonnée par la civilisation. Lorsque la guerre des bombes ravagea la Terre, les répercussions atteignirent leur propre système, les poussant à envoyer un vaisseau d’observation. Mais la distance était immense, et trois générations naquirent et moururent à bord avant d’atteindre leur destination.`}
      </Text>

      <Text>
        {`Face au chaos terrestre, les Adrulens hésitèrent. Pendant des décennies, ils observèrent, jugeant cette planète trop instable pour être approchée. Mais cette mission cachait un dessein plus sombre : l’exil des Emelen, une caste jugée indésirable par le Haut Conseil. Vénérant Mayila, déesse de la vie et des secrets, ces mystiques étaient perçus comme une menace pour l’ordre établi.`}
      </Text>

      <Text>
        {`Finalement, ce furent eux qui descendirent en premiers. Ils cherchèrent un sens à leur bannissement, s’intégrant aux survivants tout en conservant leur foi ardente. Certains les voient comme des érudits aux desseins obscurs, d’autres comme les prophètes d’un avenir incertain. Mais une chose est sûre : leur arrivée sur Terre n’était pas un hasard, et leur présence pourrait bien façonner le destin des vivants.`}
      </Text>
    </>
  );
}
