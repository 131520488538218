import { Element, Type, Subtype } from 'data/metadata';

const data = [
  {
    damage: `20 + 2x1d10 + 3xDR`,
    description: `Équivalent à un gourdin ou une massue`,
    name: `Batte de base-ball`,
    value: 12,
    weight: 1,
  },
  {
    damage: `28 + 4x1d6 + 3xDR`,
    description: `Équivalent à une machette un ou couteau de boucher`,
    name: `Couteau de combat`,
    value: 22,
    weight: 0.5,
  },
  {
    damage: `15 + 2x1d4 + 2xDR`,
    description: `Équivalent à un scalpel ou un couteau suisse`,
    name: `Dague`,
    value: 22,
    weight: 0.5,
  },
  {
    damage: `26 + 4x1d6 + 3xDR`,
    description: `Équivalent à un sabre`,
    name: `Épée`,
    value: 40,
    weight: 2,
  },
  {
    damage: `20 + 3x1d6 + 3xDR`,
    description: `Cette épée garde sa forme et sa matérialité malgré le voyage entre les plans`,
    name: `Épée de poussière d'étoile`,
    value: 1850,
    weight: 2,
  },
  {
    damage: `80 + 15x1d8 + 2xDR`,
    effects: `Entre 5 et 8 mètres / Nécessite Force 150+`,
    name: `Épée géante`,
    value: 12,
    weight: 80,
  },
  {
    damage: `30 + 10x1d4 + 4xDR`,
    effects: `Armes de corps-à-corps -20`,
    name: `Faux`,
    value: 85,
    weight: 3,
  },
  {
    damage: `30 + 10x1d10 + 2xDR`,
    effects: `Armes de corps-à-corps -40
    Sur un échec pair, se blesse soit-même`,
    name: `Fléau`,
    value: 68,
    weight: 4,
  },
  {
    damage: `25 + 3x1d8 + 2xDR`,
    description: `La version assistée permet que le poids soit "porté" de manière autonome`,
    name: `Gants adamantins`,
    value: 320,
    weight: 16,
  },
  {
    damage: `20 + 3x1d20`,
    effects: `Provoque des explosions : blesse le porteur également`,
    name: `Gants ATM`,
    value: 72,
    weight: 1,
    zone: `1`,
  },
  {
    damage: `18 + 5x1d4 + 3xDR`,
    description: `Équivalent à des griffes`,
    name: `Gants d'épines`,
    value: 30,
    weight: 0.5,
  },
  {
    damage: `20 + 10x1d4 + 4xDR`,
    name: `Griffes adamantines`,
    value: 65,
    weight: 4,
  },
  {
    damage: `28 + 6x1d6 + 2xDR`,
    description: `Équivalent à une bardiche`,
    name: `Hache`,
    value: 45,
    weight: 3,
  },
  {
    damage: `30 + 10x1d4 + 4xDR`,
    description: `Équivalent à une rapière, un fleuret ou un stylet`,
    name: `Katana`,
    value: 75,
    weight: 1,
  },
  {
    ammo: `Crystal d'énergie noire`,
    damage: `180`,
    effects: `Absorbe la matière au contact`,
    name: `Lame anti-matière`,
    value: 850,
    weight: 0.5,
  },
  {
    damage: `25 + 4x1d8 + 3xDR`,
    name: `Lance`,
    value: 24,
    weight: 4,
  },
  {
    damage: `25 + 3x1d10 + 3xDR`,
    description: `La version assistée permet que le poids soit automatiquement géré, ce qui permet de manier l'arme à une main`,
    name: `Masse`,
    value: 145,
    weight: 15,
  },
  {
    damage: `14 + 1d10 + 2xDR`,
    name: `Pied de biche`,
    value: 4,
    weight: 1.5,
  },
  {
    damage: `6 + 1x1d6 + 2xDR`,
    name: `Poing américain`,
    value: 3,
    weight: 0.5,
  },
  {
    ammo: `Energie (consomme 40 PE par heure)`,
    damage: `50 + 12x1d6 + DR`,
    name: `Sabre à énergie`,
    value: 190,
    weight: 0.5,
  },
  {
    ammo: `Energie (consomme 40 PE par heure)`,
    damage: `58 + 10x1d8 + 2xDR`,
    name: `Tronçonneuse`,
    value: 180,
    weight: 8,
  },
];

export default data.map((i) => ({
  ...i,
  element: Element.ITEM,
  subtype: Subtype.Weapon.MELEE,
  type: Type.Item.WEAPON,
}));
