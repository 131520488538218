import PropTypes from 'prop-types';

import { DataCard } from 'components/afterworld/data/card/DataCard';
import { CardPin } from 'components/afterworld/data/card/CardPin';

// import './Gift.scss';

export function Knowledge({
  description,
  name,
  related,
  requirements = [],
}) {
  return (
    <DataCard
      className="gift"
      content={description}
      bottom={requirements.map((requirement) => (
        <CardPin key={requirement} >
          {requirement}
        </CardPin >
      ))}
      title={name}
      pinLeft={{
        tooltip: related,
        value: related.slice(0, 3),
      }}
    />
  );
}

Knowledge.propTypes = {
  description: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  related: PropTypes.string.isRequired,
  requirements: PropTypes.arrayOf(PropTypes.string),
};
