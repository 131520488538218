import Skills from 'components/afterworld/Skills';
import degreesData from 'data/rules/temperature';
import hunger from 'data/rules/hunger';
import thirst from 'data/rules/thirst';
import { PageHeading } from 'components/elements';
import { Subtitle, Table, Text, Title } from 'components/semantic';

export function ManualPhysicsPage() {
  return (
    <>
      <PageHeading>
        Physiologie
      </PageHeading>

      <Title>Besoins vitaux</Title>

      <Text>
        {`Ci après les effets sur le corps d'un personnage qui ne mange pas ou ne boit pas pendant une certaine période.`}
      </Text>

      <Subtitle>La soif</Subtitle>

      <Table data={thirst} sizes={[1, 3]}></Table>

      <Subtitle>La faim</Subtitle>

      <Table data={hunger} sizes={[1, 3]}></Table>

      <Title>Chute et chute d'objet</Title>

      <Text>
        {`Une chute peut arriver à n'importe qui ! Un personnage qui tombe et atterrit sur une surface solide va subir des dégâts contondants sur le membre du corps qui amorti le choc, selon la hauteur : 1d10 par mètre.`}
      </Text>

      <Text>
        {`Si le personnage atterrit sur une surface qui amortit le choc, que ce soit un trampoline, une toile tendue ou bien de l'eau, les dégâts sont réduits de moitié.`}
      </Text>

      <Text>
        {`Dans l'autre cas de figure, si un objet tombe d'une certaine hauteur sur un personnage, il subira dégâts selon le poids de l'objet et la hauteur de chute : 1 dégât par kilogramme x 1d10 par mètre.`}
      </Text>

      <Title>Effort physique</Title>

      <Text>
        {`En cas d'effort physique inhabituel, course, escalade ou natation, le personnage s'épuise naturellement. Chaque tour d'effort augmente la Fatigue de 1 point.`}
      </Text>

      <Text>
        Lorsque le personnage a autant de points de Fatigue que son score en Endurance, il est à bout : continuer l'effort va lui demander des tests de <Skills.Defenses.Will /> à chaque tour.
        S'il réussit et que l'effort à lieu, il doit ensuite réaliser un test de <Skills.Defenses.Vigor />. En cas d'échec, il s'évanouit et gagne un point de Psychose.
      </Text>

      <Title>Oxygène</Title>

      <Text>
        {`Plusieurs cas de figure peuvent survenir où un personnage ne peut plus respirer : se faire étouffer, se noyer ou la fumée d'un incendie.`}
      </Text>

      <Text>
        {`Un personnage peut retenir son souffle autant de tours que son score en Endurance. Passé ce délai, il sera en manque d'oxygène.`}
      </Text>

      <Text>
        Pour chaque tour où un personnage manque d'oxygène, il perd un point de Mental et doit faire un test de <Skills.Defenses.Will />. En cas d'échec, il gagne un point de Psychose, tombe inconscient et risque de décéder.
      </Text>

      <Title>Température</Title>

      <Text>
        La température maximale qu'un personnage peut supporter est de 30° + son score en Endurance. Cette valeur peut varier selon l'équipement du personnage. Au-delà de cette température, le personnage doit effectuer un test de <Skills.Defenses.Will /> à chaque intervalle, et tombera inconscient en cas d'échec. Sa Psychose augmente de 1.
      </Text>

      <Table data={degreesData}></Table>

      <Text>
        {`Tout comme pour la chaleur, la température minimale qu'un personnage peut endurer est de 10° - son score en Endurance. De la même manière, cette valeur peut être modifiée avec l'équipement du personnage.`}
      </Text>

    </>
  );
}
