import { Monsters, Spells } from 'data/metadata';

const data = [
  {
    armor: 2,
    damage: [`12 + 3x1d6 + DR`, ``, ``],
    health: 70,
    hit: [60, 0, 0],
    initiative: 4,
    name: `Homme-rat`,
    skills: [
      { skill: 'Maîtrise', value: `65 %` },
    ],
    speed: 4,
    types: [Monsters.Type.BEAST, Monsters.Type.HUMAN],
  },
  {
    armor: 3,
    attacks: [
      `Applique Radiations Ⅱ`,
      Monsters.Skills.Poison('Spectrol', '15 minutes'),
      Monsters.Skills.Poison('Essouffleur', '5 minutes'),
    ],
    damage: [`24 + 4x1d6 + DR`, ``, ``],
    health: 310,
    hit: [75, 0, 0],
    name: `Wido`,
    speed: 2,
    types: [Monsters.Type.PLANT, Monsters.Type.MUTANT],
  },
  {
    abilities: [`Absorption : la vase s'enroule autour d'une personne et ne peut plus être ciblée, les blessures infligées à la personne sont aussi infligées à la vase, augmente la Fatigue de la personne jusqu'à atteindre le score en Endurance puis elle tombe inconsciente`],
    health: 40,
    name: `Vase collante`,
    passives: [Monsters.Skills.Group(`3 à 6 [2 + 1d4]`)],
    speed: 1,
    types: [Monsters.Type.PLANT, Monsters.Type.MUTANT],
  },
  {
    armor: 2,
    attacks: [
      `Dégâts doublés contre les cyborgs`,
      `Provoque un dysfonctionnement de ce qui est électrique ou électronique`
    ],
    damage: [`12 + 2x1d8 + DR`, ``, ``],
    health: 100,
    hit: [85, 0, 0],
    name: `Cyborg rouillé`,
    speed: 1,
    types: [Monsters.Type.HUMAN, Monsters.Type.ROBOT],
  },
  {
    abilities: [Monsters.Skills.Charge(`2`, `4`)],
    armor: 3,
    attacks: [
      `Applique Radiations Ⅱ`,
      Monsters.Skills.Poison('Spectrol', '5 minutes', `(PM) 40`),
    ],
    damage: [`14 + 3x1d6 + DR`, ``, ``],
    death: [`Nuage de poison: libère un nuage empoisonné qui a 60 % de chances d'appliquer Poison "Essouffleur" pour 12 tours`],
    health: 0,
    hit: [85, 60, 0],
    initiative: 4,
    name: `Mut-cochon`,
    speed: 3,
    types: [Monsters.Type.BEAST, Monsters.Type.PLANT],
  },
  {
    armor: 3,
    damage: [`selon arme invoquée`, ``, ``],
    health: 100,
    hit: [65, 85, 0],
    initiative: 4,
    magic: [{ school: Spells.SCHOOL.FORGE, level: `75 %` }],
    mana: 12,
    name: `Magicien renégat`,
    skills: [
      { skill: `Sang froid`, value: `0 %` },
    ],
    types: [Monsters.Type.ELEMENTAL, Monsters.Type.HUMAN],
  },
  {
    abilities: [Monsters.Skills.Saut(`3x1d6`)],
    armor: 3,
    attacks: [`60 % : applique Saignements Ⅲ pour 12 tours`],
    damage: [`16 + 2x1d6 + DR`, ``, ``],
    death: [`Explosion : provoque une détonation à la fin de l'activité cardiaque qui inflige 40 + 3x1d20 aux alentours`],
    health: 60,
    hit: [85, 0, 0],
    initiative: 6,
    name: `Cyber-chien`,
    speed: 4,
    types: [Monsters.Type.BEAST, Monsters.Type.ROBOT],
  },
  {
    abilities: [`"Ultrasons": chaque personne aux alentours a 50 % de chances d'être étourdi pendant 1 tour`],
    armor: 4,
    attacks: [Monsters.Skills.Poison('Cendrita', '1 minute', 60)],
    damage: [`12 + 3x1d6 + DR`, ``, ``],
    health: 0,
    hit: [80, 0, 0],
    initiative: 3,
    name: `Mut-fourmi`,
    speed: 2,
    types: [Monsters.Type.BEAST, Monsters.Type.MUTANT],
  },
  {
    attacks: [
      `Fait fondre les armures en peau, tissu ou cuir`,
      `Applique Brûlure V pendant 2 tour au membre du corps touché, puis Saignements Ⅲ permanent`
    ],
    health: 20,
    hit: [80, 80, 0],
    name: `Vase autonome`,
    passives: [
      Monsters.Skills.Intangible,
      Monsters.Skills.AuraRadioactive('Ⅱ'),
    ],
    speed: 2,
    types: [Monsters.Type.PLANT, Monsters.Type.MUTANT],
  },
  {
    abilities: [`Spores énervantes : libère un nuage de spores qui force les personnes proches à attaquer le Porteur de spores`],
    armor: 1,
    attacks: [`Applique Radiations Ⅲ`],
    damage: [`12 + 2x1d6 + DR`, ``, ``],
    health: 55,
    hit: [85, 0, 0],
    initiative: 3,
    name: `Porteur de spores`,
    passives: [Monsters.Skills.RégénérationRadioactive],
    speed: 3,
    types: [Monsters.Type.PLANT, Monsters.Type.MUTANT],
  },
  {
    abilities: [`Psychose généralisée: les personnes proches deviennent folles et gagnent un point de folie`],
    attacks: [`Applique Radiations Ⅲ`],
    health: 110,
    magic: [
      { school: Spells.SCHOOL.AWAKENING, level: `100 %` },
      { school: Spells.SCHOOL.DUSK, level: `80 %` },
      { school: Spells.SCHOOL.ASCEND, level: `60 %` },
    ],
    mana: 30,
    name: `Horreur des profondeurs cultiste`,
    show: [
      `80% : gagne un point de psychose`,
      `60% : rend fou pendant 10 minutes`,
    ],
    speed: 2,
    types: [Monsters.Type.MUTANT, Monsters.Type.ELEMENTAL],
  },
  {
    abilities: [Monsters.Skills.Envol(30)],
    armor: 5,
    attacks: [
      `Rend fou pendant 1 heure`,
      `Applique Radiations Ⅳ`,
      Monsters.Skills.Poison('Ronge-Os', '2 minutes'),
      Monsters.Skills.Poison('Tue-sorcier', '1 minute'),
      `60% : transmets le Petit Mal`,
      `30% : transmets la Peste Bubonique`,
    ],
    damage: [`22 + 4x1d6 + DR`, ``, ``],
    health: 170,
    hit: [90, 0, 0],
    initiative: 6,
    name: `Pestenfer`,
    speed: 4,
    types: [Monsters.Type.BEAST, Monsters.Type.MUTANT],
  },
  {
    abilities: [`Regard envoûtant : force la cible à regarder dans les yeux pendant 3 tours, contré avec un test de Volonté`],
    armor: 16,
    attacks: [
      `Applique Radiations Ⅳ`,
      Monsters.Skills.Poison('Ronge-Os', '1 heure'),
    ],
    damage: [`25 + 5x1d6 + 2xDR`, ``, ``],
    health: 160,
    hit: [95, 0, 0],
    initiative: 3,
    items: [`Arme de corps à corps exotique`],
    name: `Mut-scorpion alpha`,
    passives: [
      `Groupe : se trouve généralement avec un groupe de 2 à 7 Mut-scorpions [1 + 1d6]`,
      Monsters.Skills.Chef('Mut-scorpions', 4),
      `Regard pétrifiant : après un tour de contact oculaire, transforme la cible en pierre`,
      Monsters.Skills.Faiblesse("Tête", 0),
      Monsters.Skills.Faiblesse("Tronc", 0),
    ],
    speed: 5,
    types: [Monsters.Type.BEAST, Monsters.Type.MUTANT],
  },
  {
    abilities: [Monsters.Skills.Tremblement],
    armor: 6,
    attacks: [Monsters.Skills.Poison('Spectrol', '1 minute')],
    damage: [`12 + 2x1d6 + DR`, ``, ``],
    health: 80,
    hit: [90, 0, 0],
    magic: [{ school: Spells.SCHOOL.AWAKENING, level: `100 %` }],
    mana: 30,
    name: `Tréant`,
    speed: 2,
    types: [Monsters.Type.PLANT, Monsters.Type.ELEMENTAL],
  },
  {
    abilities: [`Odeur putride : les personnes proches tombent inconscientes si elles échouent un test de Volonté`],
    armor: 3,
    attacks: [
      Monsters.Skills.Poison('Neucrosite', '3 minutes'),
      `60% : transmets la Putride`,
    ],
    damage: [`16 + 3x1d8 + DR`, ``, ``],
    health: 110,
    hit: [75, 0, 0],
    magic: [{ school: Spells.SCHOOL.DUSK, level: `100 %` }],
    mana: 40,
    name: `Moréant`,
    speed: 2,
    types: [Monsters.Type.PLANT, Monsters.Type.ELEMENTAL],
  },
  {
    abilities: [`Nova de poison : les personnes proches ont 35 % de chances de se voir appliquer Poison "Essouffleur" pour 6 tours`],
    attacks: [
      `Applique Radiations Ⅳ`,
      Monsters.Skills.Poison('Étouffeur', '1 minute'),
    ],
    damage: [`6 + 1d6`, ``, ``],
    health: 40,
    hit: [85, 0, 0],
    name: `Vase mutante`,
    passives: [
      Monsters.Skills.Flasque,
      Monsters.Skills.AuraRadioactive('Ⅲ'),
      Monsters.Skills.RégénérationRadioactive,
    ],
    speed: 2,
    types: [Monsters.Type.PLANT, Monsters.Type.MUTANT],
  },
  {
    armor: 6,
    attacks: [`60 % : renverse la cible au sol`],
    damage: [`28 + 3x1d8 + DR`, ``, ``],
    health: 130,
    hit: [90, 0, 0],
    name: `Techno-Thog sauvage`,
    show: [`80 % : effraie les individus proches`],
    speed: 2,
    types: [Monsters.Type.MUTANT, Monsters.Type.ROBOT],
  },
  {
    armor: 4,
    damage: [`35 + 8x1d4 + 4xDR`, ``, ``],
    health: 160,
    hit: [110, 0, 0],
    initiative: 8,
    name: `Écorcheur`,
    passives: [Monsters.Skills.MouvementsErratiques(60)],
    show: [`Effraie les individus proches`],
    speed: 6,
    types: [Monsters.Type.BEAST, Monsters.Type.HUMAN],
  },
  {
    attacks: [
      `Applique Radiations Ⅳ`,
      Monsters.Skills.Poison('Spectrol', '15 minutes'),
      Monsters.Skills.Poison('Essouffleur', '3 minutes', 80),
      `60 % : rend fou pendant 3 heures, à la suite de quoi le personnage gagne un point de psychose`,
      `40 % : transmets une maladie, généralement une infection`
    ],
    damage: [`19 + 2x1d8 + DR`, ``, ``],
    death: [
      `Zone radioactive : laisse une flaque au sol qui applique Radiations Ⅳ par tour à l'intérieur`,
      `Spores empoisonnées: évacue des spores transparentes qui ont 35 % de chances par tour à les respirer d'appliquer du poison "Rêvoilé"`
    ],
    health: 220,
    hit: [110, 0, 0],
    name: `Marécage ambulant`,
    speed: 2,
    types: [Monsters.Type.PLANT, Monsters.Type.MUTANT],
  },
  {
    abilities: [`Spores toxiques : libère un nuage qui applique Poison "Spectrol" et "Rêvoilé" aux personnes proches pendant 3 minutes`],
    actions: `+1`,
    attacks: [`Applique Saignements Ⅳ permanent`],
    damage: [`12 + 3x1d4 + DR`, ``, ``],
    health: 0,
    hit: [120, 0, 0],
    initiative: 8,
    name: `Épouvantail des ténèbres`,
    speed: 4,
    types: [Monsters.Type.PLANT, Monsters.Type.MUTANT],
  },
  {
    actions: `+1`,
    armor: 5,
    health: 110,
    hit: [100, 100, 35],
    initiative: 6,
    items: [
      `Arme quelconque exotique`,
      `Armure de cuir renforcée ou armure assistée, partielle ou complète`
    ],
    magic: [
      { school: Spells.SCHOOL.FORGE, level: `60 %` },
      { school: Spells.SCHOOL.TEMPEST, level: `80 %` },
      { school: Spells.SCHOOL.DAWN, level: `80 %` },
      { school: Spells.SCHOOL.DUSK, level: `60 %` },
    ],
    mana: 40,
    name: `Exécuteur`,
    speed: 3,
    skills: [
      { skill: `Survie`, value: `80 %` },
      { skill: `Social`, value: `65 %` },
      { skill: `Maîtrise`, value: `70 %` },
    ],
    types: [Monsters.Type.HUMAN, Monsters.Type.ELEMENTAL],
  },
  {
    actions: `+1`,
    armor: 6,
    attacks: [`Applique Saignements Ⅲ pour 12 tours`],
    damage: [`24 + 4x1d6 + 2xDR`, ``, ``],
    health: 125,
    hit: [90, 0, 0],
    initiative: 8,
    magic: [
      { school: Spells.SCHOOL.FORGE, level: `90 %` },
      { school: Spells.SCHOOL.AWAKENING, level: `60 %` },
      { school: Spells.SCHOOL.TEMPEST, level: `60 %` },
    ],
    mana: 40,
    name: `Mut-cornu`,
    speed: 2,
    types: [Monsters.Type.ELEMENTAL, Monsters.Type.MUTANT],
  },
];

export default data;
