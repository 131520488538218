import Skills from 'components/afterworld/Skills';
import bleedingData from 'data/rules/bleedingData';
import diseasesBeninData from 'data/rules/diseasesSoft';
import diseasesLethalData from 'data/rules/diseasesLethal';
import dotData from 'data/rules/dotData';
import poisonData from 'data/rules/poisons';
import radiationsData from 'data/rules/radiations';
import radiationsMutationData from 'data/rules/radiationsMutations';
import { PageHeading } from 'components/elements';
import { Table, Text, Title } from 'components/semantic';

export function ManualEnvironmentPage() {
  return (
    <>
      <PageHeading>
        Environnement
      </PageHeading>

      <Title>Dégâts sur la durée</Title>

      <Text>
        {`Ce tableau de dégâts concerne autant les saignements que les brûlures. Selon le niveau de dégâts, le personnage perdra un certain nombre de points de vie à chaque tour. La durée de l'effet est indiqué avec la source qui l'a causé.`}
      </Text>

      <Table data={dotData}></Table>

      <Text>
        {`Dans le cas précis des saignements, voici la description de l'effet selon l'intensité.`}
      </Text>

      <Table data={bleedingData}></Table>

      <Title>Maladie</Title>

      <Text>
        {`Ce monde, bien que différent du nôtre, regorge de maladies que les personnages peuvent attraper. Différentes méthodes de transmission existent, que ce soit par le contact avec un agent infecté, par inhalation ou après avoir ingurgité des aliments avariés.`}
      </Text>

      <Text>
        Dès qu'un personnage entre en contact avec un agent pathogène d'une maladie, il doit faire un test de <Skills.Defenses.Will />. S'il échoue, il contracte la maladie juste après le temps d'incubation.
      </Text>

      <Text>
        Une fois malade, le personnage doit réitérer son test de <Skills.Defenses.Will /> tous les jours. Son système immunitaire ne viendra à bout de la maladie qu'après avoir réussi deux de ces tests consécutivement.
      </Text>

      <Text>
        {`Il peut aussi bénéficier de médicaments afin de traiter la maladie, encore faut-il savoir lesquels prendre.`}
      </Text>

      <Table data={diseasesBeninData} responsive sizes={[1, 1, 1, 3]}></Table>

      <Text>
        {`Attention toutefois, même les maladies les plus courantes, si elles persistent trop longtemps car le système ne parvient pas à les vaincre ou qu'elles ne sont pas soignées, peuvent être fatales pour les personnages. Il ne faut pas les négliger !`}
      </Text>

      <Text>
        {`Il existe également des maladies que le système immunitaire du personnage ne peut pas vaincre seul. Une fois ces maladies contractées, le personnage ne pourra être soigné qu'avec un traitement approprié.`}
      </Text>

      <Table data={diseasesLethalData} responsive sizes={[1, 1, 1, 3]}></Table>

      <Title>Poison</Title>

      <Text>
        {`Les effets du poison dépendent du type de poison qui circule dans les veines du personnage.`}
      </Text>

      <Table data={poisonData} responsive sizes={[1, 1, 1, 3]}></Table>

      <Title>Radiations</Title>

      <Text>
        {`Le taux de radiations peut augmenter de plusieurs façons, par l'ingestion de nourriture irradiée, par l'attaque d'un monstre irradié ou simplement en se baladant dans une zone irradiée.`}
      </Text>

      <Text>
        {`Le montant de radiations appliqué se réfère généralement à la table des intensités et il est directement ajouté au score de radiations du personnage, que ce soit ponctuel ou régulier.`}
      </Text>

      <Table data={radiationsData} responsive sizes={[1, 3]}></Table>

      <Text>
        {`Si un personnage reste à un taux de radiations élevé pendant une période prolongée, et ce sans mourir, des effets supplémentaires peuvent survenir.`}
      </Text>

      <Table data={radiationsMutationData} responsive sizes={[1, 3]}></Table>

    </>
  );
}
