import styled from "@emotion/styled";

const VersionContainer = styled.div`
  bottom: 75px;
  font-family: CfNightOfTheDamnedRegular;
  font-size: 1.5em;
  left: 0px;
  letter-spacing: 0.3em;
  position: absolute;
  right: 0px;
  text-align: center;
`;

export function Version({ version }) {
  return (
    <VersionContainer>
      {version}
    </VersionContainer>
  );
}

