export default Object.freeze([
  [`Nom`, `Rareté`, `Origine`, `Effet`],
  [`Cendrita`, `Commun`, `Faune`, `Engourdit les muscles, Force et Agilité -25% et Initiative -5`],
  [`Sang-Sève`, `Commun`, `Faune`, `Empêche la coagulation, durée des Saignements x3`],
  [`Essouffleur`, `Commun`, `Faune`, `Constamment essoufflé, double le gain de point de Fatigue`],
  [`Étouffeur`, `Commun`, `Faune`, `Fatigué physiquement, les gains de points de Fatigue provoquent un test de Vigueur, arrêt cardiaque en cas d'échec`],
  [`Ronge-Os`, `Commun`, `Faune`, `Réduit la résistance osseuse, Vigueur -25% et Armure naturelle à 0`],
  [`Aveuglette`, `Commun`, `Faune`, `Gaz irritable, aveugle pendant 5 minutes en cas de contact`],
  [`Rêvoilé`, `Commun`, `Flore`, `Endort, puis réduit l'Acuité de 50 au réveil`],
  [`Spectrol`, `Commun`, `Flore`, `Provoque des hallucinations visuelles et auditives`],
  [`Neucrosite`, `Commun`, `Flore`, `Provoque la nécrose des tissus touchés sur la durée du poison`],
  [`Assoiffeur`, `Commun`, `Flore`, `Bloque la production de salive, augmente l'effet de soif de 12 heures`],
  [`Tue-sorcier`, `Commun`, `Eau radioactive`, `Incapable de lancer des sorts ou percevoir la magie`],
  [`Rouillâtre`, `Commun`, `Résidus métalliques`, `Capacité de souffle -3, atteindre un état de fatigue dans cet état demande un test de Vigueur qui, s'il est raté, provoque une perforation des poumons`],
  [`Neigêons`, `Rare`, `Neige radioactive`, `Provoque Brûlure Ⅲ à chaque contact`],
  [`Gêolette`, `Rare`, `Lieux renfermés`, `Gaz neurotoxique incolore et inodore, provoque des tremblements, Compétences -25% pendant exposition et définitif si le personnage y reste au moins 1 heure`],
  [`Vapôrâtre`, `Rare`, `Marais radioactifs`, `État fiévreux et affame, augmente l'effet de faim de 3 jours, tendances cannibales`],
  [`Gammaturge`, `Rare`, `Synthèse`, `Affaiblit le corps, gains de Radiations x3`],
  [`Nerf-mort`, `Rare`, `Synthèse`, `Toxine qui paralyse`],
  [`Gris-Esprit`, `Rare`, `Synthèse`, `Toxine qui attaque les neurones, Intelligence -20 définitif`],
  [`Poussière`, `Rare`, `Synthèse`, `Toxine qui attaque les poumons, Vigueur -20 définitif`],
  [`Sépulcre`, `Rare`, `Synthèse`, `Coma pendant 3 jours, test de létal Vigueur toutes les 12 heures`],
]);
