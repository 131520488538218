import { Element, Type, Subtype } from 'data/metadata';

const data = [
  {
    ammo: `Carreau`,
    bpa: `1`,
    damage: `55 + 10x1d6 + 3xDR`,
    load: `5`,
    name: `Arbalète`,
    range: `Moyenne`,
    value: 93,
    weight: 4,
  },
  {
    ammo: `Fléchettes + grenade`,
    bpa: `1`,
    damage: `40 + 5x1d20 + 2xDR`,
    load: `1`,
    name: `ATM`,
    range: `Moyenne`,
    value: 138,
    weight: 4,
  },
  {
    ammo: `Calibre .32`,
    bpa: `2`,
    damage: `50 + 8x1d10 + DR`,
    load: `2`,
    name: `Fusil à pompes`,
    range: `Courte`,
    value: 92,
    weight: 4,
    zone: `0,5`,
  },
  {
    ammo: `Balles 5,56mm`,
    bpa: `12`,
    damage: `40 + 6x1d8 + 2xDR`,
    load: `24`,
    name: `Fusil d'assaut`,
    range: `Moyenne`,
    value: 102,
    weight: 4,
  },
  {
    ammo: `Calibre .32`,
    bpa: `4`,
    damage: `30 + 5x1d6 + 3xDR`,
    load: `8`,
    name: `Fusil de chasse .32`,
    range: `Longue`,
    value: 72,
    weight: 4,
  },
  {
    ammo: `Cellules E`,
    bpa: `1`,
    damage: `45 + 8x1d8 + 2xDR`,
    effects: `Provoque une légère explosion au toucher`,
    load: `1`,
    name: `Fusil Gauss`,
    range: `Longue`,
    value: 395,
    weight: 6,
    zone: `0,5`,
  },
  {
    ammo: `Cellules E`,
    bpa: `5`,
    damage: `50 + 6x1d8 + 2xDR`,
    load: `40`,
    name: `Fusil laser`,
    range: `Moyenne`,
    value: 150,
    weight: 8,
  },
  {
    ammo: `Calibre .44`,
    bpa: `1`,
    damage: `40 + 10x1d6 + 4xDR`,
    load: `1`,
    name: `Fusil sniper`,
    description: `Équivalent Fusil de précision AC-4K`,
    range: `Longue`,
    value: 159,
    weight: 8,
  },
  {
    ammo: `Calibre .44`,
    bpa: `3`,
    damage: `32 + 8x1d6 + 3xDR`,
    description: `Équivalent Smith & Wesson M29 ou Desert Eagle`,
    load: `18`,
    name: `Magnum .44`,
    range: `Longue`,
    value: 84,
    weight: 2,
  },
  {
    ammo: `Balles 9mm`,
    bpa: `20`,
    damage: `36 + 6x1d8 + 2xDR`,
    load: `40`,
    description: `Équivalent MP-5 ou Uzi 27 SMG`,
    name: `Pistolet-mitrailleur 9mm`,
    range: `Courte`,
    value: 81,
    weight: 3,
  },
  {
    ammo: `Balles 10mm`,
    bpa: `20`,
    damage: `40 + 5x1d8 + 2xDR`,
    description: `Équivalent SMG 10mm`,
    load: `60`,
    name: `Pistolet-mitrailleur 10mm`,
    range: `Courte`,
    value: 76,
    weight: 3,
  },
  {
    ammo: `Calibre .32`,
    bpa: `4`,
    damage: `22 + 5x1d6 + 2xDR`,
    load: `8`,
    name: `Pistolet .32`,
    range: `Courte`,
    value: 21,
    weight: 1,
  },
  {
    ammo: `Balles 9mm`,
    bpa: `6`,
    damage: `35 + 5x1d6 + 2xDR`,
    description: `Équivalent Sig-Sauer P220, Beretta M9FS, Mauser HSI ou Browning HP`,
    load: `12`,
    name: `Pistolet 9mm`,
    range: `Courte`,
    value: 45,
    weight: 1,
  },
  {
    ammo: `Balles 10mm`,
    bpa: `2`,
    damage: `18 + 6x1d4 + 2xDR`,
    description: `Équivalent Sig-Sauer 338 ou Colt 6520`,
    load: `8`,
    name: `Pistolet 10mm`,
    range: `Courte`,
    value: 34,
    weight: 1,
  },
  {
    ammo: `Cellules E`,
    bpa: `4`,
    damage: `10 + 1d10 + 2xDR`,
    effects: `Chances de paralyser la cible : 25%`,
    load: `16`,
    name: `Pistolet à décharges élec.`,
    range: `Courte`,
    value: 42,
    weight: 2,
  },
  {
    ammo: `Cellules E`,
    bpa: `4`,
    damage: `10 + 1d10 + 2xDR`,
    effects: `Dégâts x 5 + 25% Choc EM sur les robots`,
    load: `12`,
    name: `Pistolet à impulsions EM`,
    range: `Courte`,
    value: 25,
    weight: 2,
  },
  {
    ammo: `Fléchettes`,
    bpa: `1`,
    damage: `5 + 1d4 + 2xDR`,
    load: `5`,
    name: `Pistolet à seringues`,
    range: `Moyenne`,
    value: 55,
    weight: 1,
  },
  {
    ammo: `Cellules E`,
    bpa: `6`,
    damage: `34 + 5x1d8 + 2xDR`,
    load: `30`,
    name: `Pistolet laser`,
    range: `Courte`,
    value: 58,
    weight: 3,
  },
  {
    bpa: `1`,
    damage: `34 + 3x1d8 + 2xDR`,
    effects: `Recharge 2 de capacité par tour au soleil`,
    load: `6`,
    name: `Solarium`,
    range: `Moyenne`,
    value: 265,
    weight: 8,
  },
  {
    ammo: `Débris`,
    bpa: `1`,
    damage: `25 + 4x1d10`,
    effects: `Effets dépendant des munitions utilisées`,
    load: `1`,
    name: `Tromblon`,
    range: `Courte`,
    value: 29,
    weight: 4,
  },
];

export default data.map((i) => ({
  ...i,
  element: Element.ITEM,
  subtype: Subtype.Weapon.LIGHT,
  type: Type.Item.WEAPON,
}));
