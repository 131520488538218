import { Monsters, Spells } from 'data/metadata';

const data = [
  {
    name: `Élémentaire d'Albâtre`,
    passives: [
      Monsters.Skills.DoubleEsquive,
      `Être magique : façonne un sort à l'aide d'une danse`
    ],
    speed: 2,
    skills: [
      {
        skill: `Agilité`,
        value: `120 %`
      }
    ],
  },
  {
    health: 65,
    initiative: 6,
    magic: [{ school: Spells.SCHOOL.TEMPEST, level: `90 %` }],
    mana: 20,
    name: `Manifestation tempétueuse`,
    passives: [Monsters.Skills.Intangible],
    speed: 3,
  },
  {
    health: 25,
    magic: [{ school: Spells.SCHOOL.AWAKENING, level: `80 %` }],
    mana: 15,
    name: `Marée mouvante`,
    passives: [Monsters.Skills.Flasque],
    speed: 2,
  },
  {
    attacks: [`Applique Brûlure Ⅲ pour 6 tours`],
    damage: [`12 + 2x1d6 + DR`, ``, ``],
    health: 70,
    hit: [80, 0, 0],
    initiative: 2,
    magic: [{ school: Spells.SCHOOL.FORGE, level: `100 %` }],
    mana: 25,
    name: `Manifestation ardente`,
    passives: [Monsters.Skills.Intangible],
  },
  {
    armor: 8,
    attacks: [`Renverse au sol`],
    damage: [`12 + 2x1d6 + DR`, ``, ``],
    health: 0,
    hit: [85, 0, 0],
    magic: [{ school: Spells.SCHOOL.AWAKENING, level: `110 %` }],
    mana: 20,
    name: `Golem de glace`,
  },
  {
    health: 100,
    initiative: 3,
    magic: [{ school: Spells.SCHOOL.DUSK, level: `110 %` }],
    mana: 12,
    name: `Érudit vagabond`,
    speed: 3,
  },
  {
    armor: 8,
    damage: [`12 + 2x1d8 + DR`, ``, ``],
    health: 90,
    hit: [80, 60, 0],
    magic: [
      { school: Spells.SCHOOL.FORGE, level: `90 %` },
      { school: Spells.SCHOOL.TEMPEST, level: `70 %` },
    ],
    mana: 30,
    name: `Goliath`,
    speed: 3,
  },
  {
    armor: 4,
    attacks: [
      `70% : applique Saignements Ⅲ pour 12 tours`,
      `50% : applique Radiations Ⅲ`,
    ],
    damage: [`14 + 4x1d4 + 2xDR`, ``, ``],
    health: 65,
    hit: [90, 0, 0],
    magic: [{ school: Spells.SCHOOL.AWAKENING, level: `90 %` }],
    mana: 25,
    name: `Manifestation glaciale`,
    speed: 3,
  },
  {
    health: 40,
    initiative: 10,
    magic: [
      { school: Spells.SCHOOL.TEMPEST, level: `110 %` },
      { school: Spells.SCHOOL.AWAKENING, level: `90 %` },
      { school: Spells.SCHOOL.ASCEND, level: `50 %` },
    ],
    mana: 40,
    name: `Avatar de la tempête`,
    speed: 3,
    skills: [{ skill: `Maîtrise`, value: `110 %` }],
  },
  {
    attacks: [`Dématérialise la cible vers le plan des Ombres petit à petit, réduit son Endurance de 1 pendant 12 tours, cumulable. Arrivé à 0, la cible n'existe plus dans ce plan`],
    health: 90,
    hit: [110, 0, 0],
    initiative: 6,
    magic: [{ school: Spells.SCHOOL.DUSK, level: `120 %` }],
    mana: 20,
    name: `Ombre vivante`,
    passives: [Monsters.Skills.Intangible],
    show: [`80% : effraie les personnes proches`],
    speed: 6,
  },
  {
    damage: [`12 + 5x1d4 + 2xDR`, ``, ``],
    health: 70,
    hit: [110, 0, 0],
    initiative: 4,
    magic: [
      { school: Spells.SCHOOL.DUSK, level: `120 %` },
      { school: Spells.SCHOOL.ASCEND, level: `80 %` },
    ],
    mana: 30,
    name: `Cauchemar`,
    passives: [`Horreur : à la fin de chaque tour, les personnes proches gagnent un point de psychose`],
    show: [`Effraie les personnes proches`],
    speed: 4,
  },
  {
    actions: `+1`,
    armor: 8,
    attacks: [`Applique Brûlure Ⅳ pour 6 tours`],
    damage: [`80 + 10x1d8 + 3xDR`, ``, ``],
    health: 850,
    hit: [115, 0, 0],
    initiative: 2,
    magic: [
      { school: Spells.SCHOOL.FORGE, level: `110 %` },
      { school: Spells.SCHOOL.TEMPEST, level: `40 %` },
    ],
    mana: 50,
    name: `Kobold`,
    speed: 5,
  },
];

export default data.map((i) => ({
  ...i,
  types: [Monsters.Type.ELEMENTAL],
}));
